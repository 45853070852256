import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'roles'
})
export class RolesPipe implements PipeTransform {

    transform(role: string, roles: string[]): boolean {
        return roles.indexOf(role) !== -1;
    }

}
