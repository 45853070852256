import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { APP_CONFIG } from '../app.config';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

import {
    publishLast,
    refCount,
    map,
    catchError,
} from 'rxjs/operators';

@Injectable()
export abstract class CrudService {
    protected apiUrl: string = '';

    public constructor(protected http: HttpClient) {}

    get(params?: { [key: string]: any }): Observable<any> {

        let urlParams = new HttpParams();

        if (params) {
            for (let key in params) {
                const value = params[key];
                if (value !== null) {
                    urlParams = urlParams.set(key, params[key]);
                }
            }
        }

        let options = { params: urlParams };

        return this.http.get(APP_CONFIG.backendHost + this.apiUrl, options)
            .pipe(
                publishLast(),
                refCount(),
                catchError((error: HttpErrorResponse) => {
                    return observableThrowError(
                        error.error,
                    );
                }),
            );
    }

    all(): Observable<any> {
        return this.get().pipe(map((res) => res.data));
    }

    find(id): Observable<any> {
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + id)
            .pipe(
                publishLast(),
                refCount(),
                catchError((error: HttpErrorResponse) => {
                    return observableThrowError(
                        error.error,
                    );
                }),
            );
    }

    create(data): Observable<any> {
        return this.http.post(APP_CONFIG.backendHost + this.apiUrl,
            data)
            .pipe(
                publishLast(),
                refCount(),
                catchError((error: HttpErrorResponse) => {
                    return observableThrowError(
                        error.error,
                    );
                }),
            );
    }

    update(data): Observable<any> {
        return this.http.put(APP_CONFIG.backendHost + this.apiUrl + data.id,
            data)
            .pipe(
                publishLast(),
                refCount(),
                catchError((error: HttpErrorResponse) => {
                    return observableThrowError(
                        error.error,
                    );
                }),
            );
    }

    del(id): Observable<any> {
        return this.http.delete(APP_CONFIG.backendHost + this.apiUrl + id)
            .pipe(
                publishLast(),
                refCount(),
                catchError((error: HttpErrorResponse) => {
                    return observableThrowError(
                        error.error,
                    );
                }),
            );
    }

    /**
     * Формирует параметры для запросы
     *
     * @returns {HttpParams}
     * @param params
     */
    protected createHttpParams(params: { [key: string]: any }): HttpParams {
        let urlParams = new HttpParams();

        for (let key in params) {
            urlParams = urlParams.set(key, params[key]);
        }

        return urlParams;
    }
}
