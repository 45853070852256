import {Component, OnInit, OnDestroy, ViewChild, HostListener} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {Menu, MenuItems} from '../../../shared/menu-items/menu-items';
import {Subscription} from 'rxjs';

import {Project} from '../../../models/project';
import {AuthService} from '../../../services/auth.service';
import {AppService} from '../../../services/app.service';
import {User} from '../../../models/user';
import {APP_CONFIG} from '../../../app.config';
import {environment} from '../../../../environments/environment';
import {VARS} from '../../../../environments/vars';

import {filter} from 'rxjs/operators';

export interface Options {
    heading?: string;
    removeFooter?: boolean;
    mapHeader?: boolean;
}

@Component({
    selector: 'app-layout',
    templateUrl: 'admin-layout.component.html',
    styleUrls: ['admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
    private _router: Subscription;

    options: Options;
    theme = 'light';
    showSettings = false;
    isDocked = false;
    isBoxed = false;
    isOpened = true;
    mode = 'push';
    _mode = this.mode;
    _autoCollapseWidth = 991;
    width = window.innerWidth;

    logo = `${APP_CONFIG.backendHost}/uploads/assets/fields-logo.png?v=${(new Date()).getTime()}`;
    logoTitle = VARS.logoTitle || '1-2-1';

    project: Project;
    projects: Project[];
    userRole: string;

    menuItems: Menu[] = [];

    @ViewChild('sidebar') sidebar;

    newDate = new Date().getFullYear();

    constructor(public menuItemsService: MenuItems,
                private router: Router,
                private route: ActivatedRoute,
                private modalService: NgbModal,
                private titleService: Title,
                private appService: AppService,
                private auth: AuthService) {
        this.project = null;
        this.projects = [];
        this.userRole = this.auth.getUserRole();
        this.menuItems = this.menuItemsService.getAll();
    }

    ngOnInit(): void {
        if (this.isOver()) {
            this._mode = 'over';
            this.isOpened = false;
        }

        this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            // Scroll to top on view load
            document.querySelector('.main-content').scrollTop = 0;

            if (this.isOver() || event.url === '/maps/fullscreen') {
                this.isOpened = false;
            }

            this.route.children.forEach((route: ActivatedRoute) => {
                let activeRoute: ActivatedRoute = route;
                while (activeRoute.firstChild) {
                    activeRoute = activeRoute.firstChild;
                }
                this.options = activeRoute.snapshot.data;
            });

            if (this.options.hasOwnProperty('heading')) {
                this.setTitle(this.options.heading);
            }
        });

        this.projects = this.appService.getProjects();
        this.appService.getProjectsObservable().subscribe((projects) => {
            this.projects = projects;
        });
        // if (this.projects.length) {
        //     this.appService.setCurProject(this.projects[0].id);
        // }

        this.project = this.appService.getCurProject();
        this.appService.getCurProjectObservable().subscribe((project) => {
            if (typeof project !== 'undefined') {
                this.project = project;
            }
        });
    }

    onChangeProject(value) {
        this.appService.setCurProject(value);
    }

    ngOnDestroy() {
        this._router.unsubscribe();
    }

    setTitle(newTitle: string) {
        this.titleService.setTitle('1-2-1 DataBase | ' + newTitle);
    }

    toggleSidebar(): void {
        if (this._mode !== 'dock') {
            this.isOpened = !this.isOpened;
            // при открытии/зыкрытии сайдбара триггерим событие window:resize
            // нужно для перерисовки некоторых компонентов
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 0);
        }
    }

    isOver(): boolean {
        return window.matchMedia(`(max-width: 991px)`).matches;
    }

    openSearch(search) {
        this.modalService.open(search, {windowClass: 'search', backdrop: false});
    }

    signout() {
        this.auth.signout().subscribe(
            (response) => {
                this.router.navigate(['/auth/signin']);
            },
            (err) => {
            }
        );
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.width === event.target.innerWidth) {
            return false;
        }

        if (this.isOver()) {
            this._mode = 'over';
            this.isOpened = false;
        } else {
            this._mode = this.mode;
            this.isOpened = true;
        }
        

        this.width = event.target.innerWidth;
    }
}
