import {Injectable} from '@angular/core';
import {CrudService} from '../crud.service';
import {AppService} from '../app.service';
import {APP_CONFIG} from '../../app.config';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ViolationService extends CrudService {
    private exportUrl: string;

    constructor(http: HttpClient, private appService: AppService) {
        super(http);

        this.apiUrl = '/api/projects/' + appService.getCurProject().id + '/violations/';
        this.exportUrl = '/api/projects/' + appService.getCurProject().id + '/violations-export/';

        this.appService.getCurProjectObservable().subscribe((project) => {
            if (typeof project !== 'undefined') {
                this.apiUrl = '/api/projects/' + project.id + '/violations/';
                this.exportUrl = '/api/projects/' + project.id + '/violations-export/';
            }
        });
    }

    violationsExport(params: any): void {
        window.open(`${APP_CONFIG.backendHost}${this.exportUrl}?city=${params.city}&outlet=${params.outlet}&promouter=${params.promouter}&status=${params.status}&from_date=${params.from_date}&to_date=${params.to_date}`, "_blank");
    }
}