import {throwError as observableThrowError, Observable} from 'rxjs';
import {CrudService} from '../crud.service';
import {Injectable} from '@angular/core';
import {APP_CONFIG} from '../../app.config';
import {HttpErrorResponse, HttpParams} from '@angular/common/http';

import {
    map,
    refCount,
    publishLast,
    catchError,
} from 'rxjs/operators';

@Injectable()
export class ProjectUserService extends CrudService {

    getInProject(projectId: any, params?: { [key: string]: any }): Observable<any> {

        let urlParams = new HttpParams();

        if (params) {
            for (let key in params) {
                const value = params[key];
                if (value !== null) {
                    urlParams = urlParams.set(key, params[key]);
                }
            }
        }

        let options = {params: urlParams};

        return this.http.get(APP_CONFIG.backendHost + '/api/projects/' + projectId + '/users', options)
            .pipe(
                publishLast(),
                refCount(),
                catchError((error: HttpErrorResponse) => {
                    return observableThrowError(
                        error.error
                    );
                }),
            );
    }

    allInProject(projectId: any): Observable<any> {
        return this.get(projectId).pipe(map(res => res.data));
    }

    findInProject(projectId: any, id): Observable<any> {
        return this.http.get(APP_CONFIG.backendHost + '/api/projects/' + projectId + '/users/' + id)
            .pipe(
                publishLast(),
                refCount(),
                catchError((error: HttpErrorResponse) => {
                    return observableThrowError(
                        error.error
                    );
                })
            );
    }

    createInProject(projectId: any, data): Observable<any> {
        return this.http.post(APP_CONFIG.backendHost + '/api/projects/' + projectId + '/users/',
            data)
            .pipe(
                publishLast(),
                refCount(),
                catchError((error: HttpErrorResponse) => {
                    return observableThrowError(
                        error.error
                    );
                }),
            );
    }

    updateInProject(projectId: any, data): Observable<any> {
        return this.http.put(APP_CONFIG.backendHost + '/api/projects/' + projectId + '/users/' + data.id,
            data)
            .pipe(
                publishLast(),
                refCount(),
                catchError((error: HttpErrorResponse) => {
                    return observableThrowError(
                        error.error
                    );
                }),
            );
    }

    delInProject(projectId: any, id): Observable<any> {
        return this.http.delete(APP_CONFIG.backendHost + '/api/projects/' + projectId + '/users/' + +id)
            .pipe(
                publishLast(),
                refCount(),
                catchError((error: HttpErrorResponse) => {
                    return observableThrowError(
                        error.error
                    );
                }),
            );
    }
}
