import {
    Component,
    EventEmitter,
    Input, OnChanges,
    OnInit, Output, SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {DialogService} from '../../dialog/dialog.service';

@Component({
    selector: 'mg-crud-table',
    templateUrl: './mg-crud-table.component.html',
    styleUrls: ['./mg-crud-table.component.scss']
})
export class MgCrudTableComponent implements OnInit, OnChanges {

    @ViewChild('actions') actions: TemplateRef<any>;
    @ViewChild('table') table: any;

    @Input() rows: any[];
    @Input() columns: any[];
    @Input() count: number;
    @Input() limit: number;
    offset: number = 0;

    @Input() createButton: boolean = true;
    @Input() editButton: boolean = true;
    @Input() deleteButton: boolean = true;

    @Input() actionCreateTemplate?: TemplateRef<any>;
    @Input() actionEditTemplate?: TemplateRef<any>;
    @Input() actionDeleteTemplate?: TemplateRef<any>;

    @Output() onAdd: EventEmitter<void> = new EventEmitter<void>();
    @Output() onEdit: EventEmitter<number> = new EventEmitter<number>();
    @Output() onDelete: EventEmitter<number> = new EventEmitter<number>();
    @Output() onSort: EventEmitter<any> = new EventEmitter<any>();
    @Output() onPage: EventEmitter<any> = new EventEmitter<any>();

    messages: {[key: string]: string};

    constructor(private dialogService: DialogService) {
        this.messages = {
            emptyMessage: 'Нет данных',
            totalMessage: 'всего'
        }
    }

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('columns') && (this.editButton || this.deleteButton)) {
            this.columns.push({
                name: 'Действия',
                cellTemplate: this.actions,
                prop: 'id'
            });
        }
    }

    add(): void {
        this.onAdd.emit();
    }

    edit(id: number): void {
        this.onEdit.emit(id);
    }

    del(id: number): void {
        this.dialogService.confirm('Подтвердить удаление?').then((result) => {
            if (result) {
                this.onDelete.emit(id);
            }
        });
    }

    sort($event) {
        this.onSort.emit($event)
    }

    page($event): void {
        this.onPage.emit($event);

        this.offset = $event.offset;
    }
}
