import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormGroupDirective} from '@angular/forms';
import {ThemeTemplateProperties} from '../../../../../models/design/theme-template-property';

@Component({
    selector: 'theme-template-text',
    templateUrl: './text.component.html',
    styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit, OnDestroy {

    @Input() propertiesFormGroup: FormGroup;
    @Input() properties: ThemeTemplateProperties;

    constructor(private parentForm: FormGroupDirective, private fb: FormBuilder) {
    }


    ngOnInit(): void {
        if (!this.properties) {
            this.properties = new ThemeTemplateProperties();
        }

        this.propertiesFormGroup = this.fb.group({
            text_color_primary: [this.properties.text_color_primary || '#000000'],
            text_color_secondary: [this.properties.text_color_secondary || '#000000'],
        })

        this.parentForm.form.addControl('properties', this.propertiesFormGroup);
    }

    ngOnDestroy(): void {
        this.parentForm.form.removeControl('properties');
    }
}
