import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {
    debounceTime,
    switchMap,
    distinctUntilChanged,
    merge,
    map,
    filter,
} from 'rxjs/operators';

import {CrudService} from '../../../services/crud.service';
import {NgbTypeahead, NgbTypeaheadConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-typeahead',
    templateUrl: './typeahead.component.html',
    styleUrls: ['./typeahead.component.scss'],
    providers: [
        NgbTypeaheadConfig,
    ]
})
export class TypeaheadComponent implements OnInit, OnDestroy {
    @Input('crudService') crudService: CrudService;
    @Input('targetField') targetField: string;

    @Output('selectItem') change: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('instance') instance: NgbTypeahead;
    focus$ = new Subject<any>();
    click$ = new Subject<any>();

    model: any;

    formatter = (result) => result[this.targetField];

    search = (text$: Observable<string>) => {
        const inputFocus$ = this.focus$,
            clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));

        return text$
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                merge(inputFocus$),
                merge(clicksWithClosedPopup$),
                switchMap(term => {
                    const params: any = {
                        page: 1,
                        limit: 10
                    };

                    if (term) {
                        params.search = term;
                    }

                    console.log('search', term, params);

                    return this.crudService
                        .get(params)
                        .pipe(map(data => data.data));
                }),
            );
    };

    constructor(private typeheadConfig: NgbTypeaheadConfig) {
        typeheadConfig.editable = false;
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    // onSelectItem($event: NgbTypeaheadSelectItemEvent): void {
    //     console.log('selectItem', $event);
    //     this.change.emit($event.item.id);
    // }

    onNgModelChanged(value): void {
        console.log(value);
        if (value !== undefined) {
            this.change.emit(value.id);
        } else {
            this.change.emit(null);
        }
    }

    onFocusout($event): void {
        console.log('focusout', $event);
    }
}
