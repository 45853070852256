import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { Subject } from 'rxjs';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
    @Input() change: any;

    @Input() placeholder: string;
    @Input() ngClass: string;
    @Input() bindLabel: string;
    @Input() typeToSearchText: string;

    @Input() searchable: boolean;
    @Input() loading: boolean;
    @Input() typeahead: Subject<any>;

    @Input() error: string;
    @Input() options?: {
        key: string | number,
        value: string | number,
    }[];

    @Input() model: string;

    @Output() modelChange = new EventEmitter<string>();

    constructor() {
        this.change = () => {};

        this.placeholder = '';
        this.bindLabel = 'label';
        this.ngClass = '';
        this.typeToSearchText = 'Начните вводить';

        this.searchable = false;
        this.loading = false;
        this.typeahead = new Subject<any>();

        this.error = '';
        this.options = [];
    }

    public onChange(e) {
        this.change(e);
        this.modelChange.emit(e);
    };
}
