import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {StepService} from '../../../../services/entity-services/step.service';
import {APP_CONFIG} from '../../../../app.config';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'field-description',
    templateUrl: './description.component.html',
    styleUrls: ['./description.component.scss']
})
export class FieldDescriptionComponent {

    @Input() field: FormGroup;

    backendHost: string = APP_CONFIG.backendHost;

    constructor(private stepService: StepService) {
    }

    handleUpload(event, field) {
        const control = field.get('description');
        control.setErrors({'file': false});
        event.preventDefault();

        this.stepService.uploadImage(event.target.files[0]).subscribe((data) => {
            if (data.success === false) {
                control.setValue(null);
                control.setErrors({'file': true});
            } else {
                control.setValue(data.path);
            }
        });
    }
}
