import {SegmentBlockRule} from './segment-block';

export class SubField {
    type: string;
    name: string;
    fieldValue: string;
    order?: number;
}

export class Field {
    uuid?: string;
    name: string;
    description: string;
    type: string;
    sub: SubField[];
    order?: number;
    required: boolean;
    stepFieldDisabled: boolean;
    mask: string;
    validation: string;
    validateEndpoint: string;
    brandId?: number;
    fieldMin?: number;
    fieldMax?: number;
    discoverPloomDistrict?: boolean;
    showComponentName?: boolean;
    payload: any[] = [];
    send_to_mindbox_as?: string;
    next_step_customer_found?: number;
    next_step_customer_not_found?: number;
    next_step_customer_found_blocked_test_drive?: number;
    next_step_customer_found_blocked_questionnaire?: number;
    next_step_customer_found_blocked_test_drive_and_questionnaire?: number;
}


export class InitStep {
    id?: number;
    name: string;
    uuid?: string;
    order?: number;
    fields: Field[];
    project_id?: number;
}
