import {Component, Input} from '@angular/core';
import {Brand} from '../../../../models/brand';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'field-brands-select',
    templateUrl: './brands-select.component.html',
    styleUrls: ['./brands-select.component.scss']
})
export class BrandsSelectComponent {

    @Input() field: FormGroup;
    @Input() brands: Brand[];

    constructor() {
    }
}
