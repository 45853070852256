import {ViolationSetting} from './violation-setting';
import {ProductMark} from './product-mark';
import {StockProduct} from './stock-product';
import {Brand} from './brand';
import {ProjectAdditionalOption} from "./project-additional-option";
import { Mechanic } from './mechanic';

export class Project {
    id: number;
    client_id: number = null;
    theme_id: number = null;
    brand_id: number = null;
    company_id: number = null;
    name: string;
    type: number = null;
    description: string;
    phone_verify_type: string;
    shift_verify_type: string;
    password: string;
    brand_color: string;
    secondary_brand_color: string;
    text_color: string;
    header_and_footer_color: string;
    brand_logo: string;
    app_portrait_cover: string;
    app_landscape_cover: string;
    contact_structure?: string = '';
    target_audience?: string = '';
    help?: string = '';
    schedule?: any[] = [];
    archived?: number = 0;
    test_drive_refund_available: boolean;
    mini_questionnaire_is_enabled: boolean;
    defective_device_refund_available: boolean;
    test_drive_refund_signature_required: boolean;
    test_drive_signature_text: string = null;
    contact_registration_timeout?: number = 0;
    violations?: ViolationSetting[] = [];
    use_stock?: boolean = false;
    products: ProductMark[] = [];
    gifts: StockProduct[] = [];
    test_drive_devices: StockProduct[] = [];
    brands: Brand[];
    mechanics: Mechanic[];
    additional_options: ProjectAdditionalOption[] = [];
    supervised_projects: Project[] = [];
    shift_breaks_limit: number;
    show_no_devices_no_sticks?: boolean;
    shift_start_screen_type?: string;
    address_program_is_required?: boolean;
    shift_verification_is_required?: boolean;
    shift_verification_interval_in_minutes?: number;
}
