export class ThemeTemplateProperties {
    text_color_default?: string;
    text_color_active?: string;
    font_weight?: string;
    text_color_primary?: string;
    text_color_secondary?: string;

    background_color_default?: string;
    background_color_active?: string;

    background_gradient_colors_default?: string;
    background_gradient_colors_active?: string;
    background_gradient_locations_default?: string;
    background_gradient_locations_active?: string;

    border_color_default?: string;
    border_color_active?: string;

    border_gradient_colors_default?: string;
    border_gradient_colors_active?: string;
    border_gradient_locations_default?: string;
    border_gradient_locations_active?: string;

    top_left_border_radius_default?: string;
    top_right_border_radius_default?: string;
    bottom_left_border_radius_default?: string;
    bottom_right_border_radius_default?: string;

    top_left_border_radius_active?: string;
    top_right_border_radius_active?: string;
    bottom_left_border_radius_active?: string;
    bottom_right_border_radius_active?: string;

    background_image?: string;

    divider_color_default?: string;

    divider_gradient_colors_default?: string;
    divider_gradient_locations_default?: string;
}
