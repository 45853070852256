import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {FileUploader} from 'ng2-file-upload';
import {APP_CONFIG} from '../../../../../../app.config';
import {AuthService} from '../../../../../../services/auth.service';

@Component({
    selector: 'theme-template-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit {
    @Input() formName: string;
    @Input() control: FormControl;

    @Input() label: string;
    @Input() placeholder: string;
    @Input() name: string;

    imageContent: any;

    uploader: FileUploader;
    imageMaxFileSize = 5 * 1024 * 1024;
    imageError = '';
    backendHost: string;

    constructor(private injector: Injector, private authService: AuthService) {
        this.backendHost = APP_CONFIG.backendHost;
    }

    ngOnInit() {
        if (this.control.value) {
            this.imageContent = {
                'path': this.control.value,
                'type': 'photo',
            }
        }

        this.uploader = new FileUploader({
            url: APP_CONFIG.backendHost + '/api/design/theme-template/image/upload',
            maxFileSize: this.imageMaxFileSize,
        });

        this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
        };

        this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
        }
        this.uploader.onBeforeUploadItem = () => {
            this.imageError = null;
        };
        this.uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };
        this.uploader.onWhenAddingFileFailed = (item, filter) => {
            if (filter.name === 'fileSize') {
                this.imageError = 'Максимальный размер файла - ' + this.formatBytes(this.imageMaxFileSize) + '.';
            } else {
                this.imageError = 'Ошибка при загрузке файла';
            }
        };

        this.uploader.onCompleteItem = (item, response, status, headers) => {
            this.imageError = null;
            this.removeContent();
            if (status === 200) {
                this.imageContent = JSON.parse(response);
                this.control.setValue(this.imageContent.path);
            } else if (status === 400) {
                this.imageError = JSON.parse(response).error;
            } else {
                this.imageError = 'Внутренняя ошибка сервера';
            }
        }
    }

    removeContent = () => {
        this.imageContent = {};
        this.imageError = '';
        this.control.setValue(null);
    };

    private formatBytes(bytes, decimals?) {
        if (bytes === 0) {
            return '0 Байт'
        }
        const k = 1024,
            dm = decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}
