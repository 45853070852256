import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {FileUploader} from 'ng2-file-upload';
import {APP_CONFIG} from '../../app.config';
import {Cookie} from 'ng2-cookies/ng2-cookies';

@Component({
    selector: 'mg-schedule-import',
    templateUrl: './mg-schedule-import.component.html',
    styleUrls: ['./mg-schedule-import.component.scss']
})
export class MgScheduleImportComponent implements OnInit {

    @Input() tableData: any[];
    @Output() tableDataChange: EventEmitter<any[]> = new EventEmitter<any[]>();
    scheduleUploader: FileUploader;
    fileName: string;

    constructor() {
        this.scheduleUploader = new FileUploader({
            url: APP_CONFIG.backendHost + '/api/work-schedules/upload',
            allowedFileType: ['xls', 'xlsx']
        });
        this.fileName = '';
    }

    ngOnInit(): void {
        this.scheduleUploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
            this.fileName = file.file.name;
        };
        this.scheduleUploader.onCompleteItem = (item, response, status, headers) => {
            let res = JSON.parse(response);
            if (status === 200) {
                this.tableData = res.data;
                this.tableDataChange.emit(this.tableData);
            }
        };
    }

    fileInputChange(event): void {
        this.scheduleUploader.uploadAll();
        event.target.value = '';
    }

    removeFile(): void {
        this.scheduleUploader.clearQueue();
        this.fileName = '';
        this.tableData = [];
        this.tableDataChange.emit(this.tableData);
    }
}
