import {Component, OnInit, Input, ChangeDetectorRef} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.component.html',
  styleUrls: ['dialog.component.scss']
})
export class DialogComponent implements OnInit {

  @Input() title;
  @Input() message;

  constructor(public activeModal: NgbActiveModal, public changeRef: ChangeDetectorRef) { }

  ngOnInit() {
  }

}
