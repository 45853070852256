import {Project} from './project';
import {City} from './city';
import {Team} from './team';

export class User {
    id: number;
    email: string;
    phone: string;
    first_name: string;
    last_name: string;
    password?: string;
    role: string;
    role_name: string;
    region_id: number;
    projects: Project[] = [];
    cities: City[] = [];
    teams: Team[] = [];
    extra_permissions: any = [];
    selected_team: number;
}
