import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'field-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss']
})
export class FieldCheckboxComponent {

    @Input() field: FormGroup;
    @Input() controlName: string;
    @Input() description: string;

    constructor() {
    }
}
