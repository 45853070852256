import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CrudService } from '../crud.service';
import { APP_CONFIG } from '../../app.config';
import { Formula } from '../../models/formula';
import { HttpErrorResponse } from '@angular/common/http';
import { publishLast, refCount, catchError } from 'rxjs/operators';

@Injectable()
export class ProjectService extends CrudService {
    protected apiUrl = '/api/projects/';

    cities(projectId: number): Observable<any> {
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + projectId + '/cities')
            .pipe(
                publishLast(),
                refCount(),
            )
    }

    attachCity(projectId: number, cityId: number): Observable<any> {
        return this.http.post(APP_CONFIG.backendHost + this.apiUrl + projectId + '/cities', {
            city: cityId
        })
            .pipe(
                publishLast(),
                refCount(),
            )
    }

    detachCity(projectId: number, cityId: number): Observable<any> {
        return this.http.delete(APP_CONFIG.backendHost + this.apiUrl + projectId + '/cities/' + cityId)
            .pipe(
                publishLast(),
                refCount(),
            )
    }

    formulas(projectId: number, report: string): Observable<any> {
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + projectId + '/formulas?report=' + report)
            .pipe(
                publishLast(),
                refCount(),
            )
    }

    saveFormulas(projectId: number, report: string, formulas: Formula[]): Observable<any> {
        return this.http.post(APP_CONFIG.backendHost + this.apiUrl + projectId + '/formulas', {
            report: report,
            formulas: formulas
        })
            .pipe(
                publishLast(),
                refCount(),
                catchError((error: HttpErrorResponse) => {
                    return observableThrowError(
                        error.error
                    );
                }),
            );
    }

    getCigarettes(): Observable<any> {
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + 'cigarettes')
            .pipe(
                publishLast(),
                refCount(),
            )
    }

    getGifts(): Observable<any> {
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + 'gifts')
            .pipe(
                publishLast(),
                refCount(),
            )
    }

    getTestDriveDevicesOptions(): Observable<any> {
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + 'devices')
            .pipe(
                publishLast(),
                refCount(),
            )
    }

    getBrands(projectId: number): Observable<any> {
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + projectId + '/brands')
            .pipe(
                publishLast(),
                refCount(),
            )
    }

    getMechanics(projectId: number): Observable<any> {
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + projectId + '/mechanics')
            .pipe(
                publishLast(),
                refCount(),
            )
    }

    getBrandProductsAndGifts(projectId: number): Observable<any> {
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + projectId + '/gifts-and-products')
            .pipe(
                publishLast(),
                refCount(),
            )
    }

    getProductsByBrand(projectId: number, brandId: number): Observable<any> {
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + projectId + '/brand/' + brandId + '/products')
            .pipe(
                publishLast(),
                refCount(),
            )
    }

    getAvailableTypes(): Observable<any> {
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + 'types')
            .pipe(
                publishLast(),
                refCount(),
            )
    }

    saveProductsByBrand(projectId: number, brandId: number, products): Observable<any> {
        return this.http.post(APP_CONFIG.backendHost + this.apiUrl + projectId + '/brand/' + brandId + '/products', {
            'products': products,
        })
            .pipe(
                publishLast(),
                refCount(),
            )
    }

    getGiftsByBrand(projectId: number, brandId: number): Observable<any> {
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + projectId + '/brand/' + brandId + '/gifts')
            .pipe(
                publishLast(),
                refCount(),
            )
    }

    getSwapGiftsByBrand(projectId: number, brandId: number): Observable<any> {
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + projectId + '/brand/' + brandId + '/swap-gifts')
            .pipe(
                publishLast(),
                refCount(),
            )
    }

    saveGiftsByBrand(projectId: number, brandId: number, gifts): Observable<any> {
        return this.http.post(APP_CONFIG.backendHost + this.apiUrl + projectId + '/brand/' + brandId + '/gifts', {
            'gifts': gifts,
        })
            .pipe(
                publishLast(),
                refCount(),
            )
    }

    saveSwapGiftsByBrand(projectId: number, brandId: number, gifts): Observable<any> {
        return this.http.post(APP_CONFIG.backendHost + this.apiUrl + projectId + '/brand/' + brandId + '/swap-gifts', {
            'gifts': gifts,
        })
            .pipe(
                publishLast(),
                refCount(),
            )
    }
}
