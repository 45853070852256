import {Component, Input, OnInit} from '@angular/core';
import {ViolationSetting} from '../../models/violation-setting';
import {Project} from '../../models/project';

@Component({
    selector: 'ng-violation-settings',
    templateUrl: './violation-settings.component.html',
    styleUrls: ['./violation-settings.component.scss']
})
export class ViolationSettingsComponent implements OnInit {

    @Input() private project: Project;
    settings: ViolationSetting[] = [];

    constructor() {
    }

    ngOnInit(): void {
        if (this.project.violations.length === 9) {
            this.settings = this.project.violations;
        } else {
            this.initDefaultSettings();
        }
    }

    initDefaultSettings(): void {
        this.settings = this.project.violations || [];
        for (let i = 0; i < 9; i++) {
            if (this.settings[i]) {
                continue;
            }

            let setting = new ViolationSetting();
            setting.id = i + 1;
            switch (i) {
                case 5:
                    setting.value = {
                        minutes: 10
                    };
                    break;
                case 6:
                    setting.value = {
                        limit: 20
                    };
                    break;
                case 7:
                    setting.value = {
                        minutes: 1,
                        materials: 10
                    };
                    break;
                case 8:
                    setting.value = {
                        minutes: 5
                    };
                    break;
            }
            this.settings.push(setting);
        }
        this.project.violations = this.settings;
    }

}
