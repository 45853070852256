import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {StepService} from '../../../../services/entity-services/step.service';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'field-mindbox-select',
    templateUrl: './mindbox-select.component.html',
    styleUrls: ['./mindbox-select.component.scss']
})
export class FieldMindboxSelectComponent {

    @Input() field: FormGroup;

    constructor(private stepService: StepService) {
    }
}
