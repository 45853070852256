import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {ValidatorFn, AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
    selector: '[positiveNumber]',
    providers: [{provide: NG_VALIDATORS, useExisting: PositiveNumberDirective, multi: true}]
})

export class PositiveNumberDirective implements Validator {

    private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
    private specialKeys: Array<string> = ['Backspace', 'Tab'];

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }

    validate(c: AbstractControl): { [key: string]: any; } {
        return positiveNumberValidator()(c);
    }
}

export function positiveNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const isNotOk = Number(control.value) < 0;
        return isNotOk ? {nonPositive: {value: control.value}} : null;
    };
}
