import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {APP_CONFIG} from '../../app.config';
import {AppService} from '../app.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {publishLast, refCount} from 'rxjs/operators';


@Injectable()
export class MultiReportService {
    url: string;

    constructor(private http: HttpClient, private appService: AppService) {
        this.url = APP_CONFIG.backendHost + '/api/multi-report';
    }

    get(params?: { [key: string]: any }): Observable<any> {

        let urlParams = new HttpParams();

        if (params) {
            for (let key in params) {
                urlParams = urlParams.set(key, params[key]);
            }
        }

        let options = {params: urlParams};
        return this.http.get(this.url, options)
            .pipe(
              publishLast(),
              refCount(),
            )
    }

    /**
     * Формирует параметры для запросы
     *
     * @param {{[p: string]: any}} params
     * @returns {HttpParams}
     */
    protected createHttpParams(params: { [key: string]: any }): HttpParams {
        let urlParams = new HttpParams();

        for (let key in params) {
            urlParams = urlParams.set(key, params[key]);
        }

        return urlParams;
    }

    /**
     * @param {string} reportName
     * @param {{[p: string]: any}} params
     * @returns {Observable<any>}
     */
    protected report(reportName: string, params?: { [key: string]: any }): Observable<any> {
        let options = params ? {params: this.createHttpParams(params)} : {};
        return this.http.get(`${this.url}/${reportName}`, options);
    }

    /**
     * Экспорт отчета по сменам
     *
     * @param params
     * @param body
     */
    shiftsReport(params?: { [key: string]: any }): Observable<any> {
        return this.report('shifts', params);
    }

    /**
     * Экспорт отчета по сменам
     *
     * @param params
     * @param body
     */
    shiftsExport(params: any, body: any): Observable<any> {
        let options = params ? {params: this.createHttpParams(params)} : {};
        return this.http.post(`${this.url}/shifts/export`, body, options);
    }
    
    /**
     * Экспорт отчета по арендам
     *
     * @param params
     * @param body
     */
     testDrivesReport(params?: { [key: string]: any }): Observable<any> {
        return this.report('test-drives', params);
    }

    /**
     * Экспорт отчета по арендам
     *
     * @param params
     * @param body
     */
    testDrivesExport(params: any, body: any): Observable<any> {
        let options = params ? {params: this.createHttpParams(params)} : {};
        return this.http.post(`${this.url}/test-drives/export`, body, options);
    }

     /**
     * Экспорт отчета по анкетам
     *
     * @param params
     * @param body
     */
	anketsReport(params?: { [key: string]: any }): Observable<any> {
		return this.report('ankets', params);
	}

	 /**
	 * Экспорт отчета по анкетам
	 *
	 * @param params
	 * @param body
	 */
	anketsExport(params: any, body: any): Observable<any> {
		let options = params ? {params: this.createHttpParams(params)} : {};
		return this.http.post(`${this.url}/ankets/export`, body, options);
	}

     /**
     * Экспорт отчета по регистрация обмена неисправных устройств
     *
     * @param params
     * @param body
     */
    defectiveDeviceRefundsReport(params?: { [key: string]: any }): Observable<any> {
		return this.report('defective-devices', params);
	}

	 /**
	 * Экспорт отчета по регистрация обмена неисправных устройств
	 *
	 * @param params
	 * @param body
	 */
      defectiveDeviceRefundsExport(params: any, body: any): Observable<any> {
		let options = params ? {params: this.createHttpParams(params)} : {};
		return this.http.post(`${this.url}/defective-devices/export`, body, options);
	}
}
