import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppService} from '../../services/app.service';
import {ProjectService} from '../../services/entity-services/project.service';
import {Subscription, BehaviorSubject} from 'rxjs';
import {TestDriveService} from '../../services/entity-services/test-drive.service';
import {TestDrive} from '../../models/test-drive';

import {
    switchMap,
} from 'rxjs/operators';
import {TableColumn} from '@swimlane/ngx-datatable';

@Component({
    selector: 'app-test-drives',
    templateUrl: 'test-drives.component.html',
    styleUrls: ['test-drives.component.scss'],
    providers: [
        TestDriveService
    ],
})
export class TestDrivesComponent implements OnInit, OnDestroy {

    filterParams: any = {};
    params: any = {};
    params$: BehaviorSubject<any>;
    columns: TableColumn[] = [];

    testDrives: TestDrive[] = [];
    count = 0;

    showCreateButton = false;
    showEditButton = false;
    showDeleteButton = false;


    messages = {
        emptyMessage: 'Нет данных',
        totalMessage: 'всего'
    };

    testDrivesSubscription: Subscription;
    projectSubscription: Subscription;

    searchModel: any;

    constructor(public appService: AppService,
                public projectService: ProjectService,
                public testDriveService: TestDriveService) {

    }

    changeParameter(name, value): void {
        this.filterParams[name] = value;
    }


    search(): void {
        this.params.page = 1;
        this.applyParams();
    }

    ngOnInit(): void {
        this.initData();
        this.projectSubscription = this.appService.getCurProjectObservable().subscribe((project) => {
            if (typeof project !== 'undefined') {
                this.initData();
            }
        });
    }

    initData(): void {
        this.params = {
            page: 1,
            limit: 10
        };

        this.params$ = new BehaviorSubject<any>(this.params);
        this.testDrivesSubscription = this.params$
            .pipe(switchMap(params => this.testDriveService.get(params)))
            .subscribe((response) => {
                this.testDrives = response.data;
                this.count = response.count;
            });
        this.initColumns();
    }

    initColumns() {
        this.columns = [
            {
                name: 'ID Анкеты',
                prop: 'contact_id',
                width: 100,
                minWidth: 100,
                cellClass: 'center-in',
            },
            {
                name: 'Консультант (Выдал Аренду)',
                prop: 'consultant_phone',
                width: 250,
                minWidth: 250,
                cellClass: 'center-in',
            },
            {
                name: 'Дата Выдачи Аренды',
                prop: 'taken_date',
                width: 250,
                minWidth: 250,
                cellClass: 'center-in',
            },
            {
                name: 'Время выдачи Аренды',
                prop: 'taken_time',
                width: 250,
                minWidth: 250,
                cellClass: 'center-in',
            },
            {
                name: 'Устройство',
                prop: 'device',
                width: 150,
                minWidth: 150,
                cellClass: 'center-in',
            },
            {
                name: 'SN устройства',
                prop: 'serial',
                width: 150,
                minWidth: 150,
                cellClass: 'center-in',
            },
            {
                name: 'Дата возврата с Аренды',
                prop: 'returned_date',
                width: 250,
                minWidth: 250,
                cellClass: 'center-in',
            },
            {
                name: 'Время возврата с Аренды',
                prop: 'returned_time',
                width: 250,
                minWidth: 250,
                cellClass: 'center-in',
            },
            {
                name: 'Длительность Аренды',
                prop: 'duration',
                width: 250,
                minWidth: 250,
                cellClass: 'center-in',
            },
            {
                name: 'Программа возврата с Аренды',
                prop: 'returned_project_name',
                width: 250,
                minWidth: 250,
                cellClass: 'center-in',
            },
            {
                name: 'Консультант (Принял возврат)',
                prop: 'returned_consultant_phone',
                width: 250,
                minWidth: 250,
                cellClass: 'center-in',
            },
            {
                name: 'Причины отказа от покупки',
                prop: 'all_reasons_for_refusal',
                width: 400,
                minWidth: 400,
                cellClass: 'center-in',
            },
        ];
    }

    page($event): void {
        this.params.page = $event.offset + 1;
        this.applyParams();
    }

    protected applyParams(): void {
        Object.keys(this.filterParams).forEach(key => this.filterParams[key] === undefined && delete this.filterParams[key]);
        this.params$.next({...this.params, ...this.filterParams});
    }


    ngOnDestroy(): void {
        this.testDrivesSubscription && this.testDrivesSubscription.unsubscribe();
    }


    filterChanged(filterName: string, filterValue: number | string): void {
        this.params.page = 1;

        if (!filterValue || filterValue === 'null') {
            delete this.params[filterName];
        } else {
            this.params[filterName] = filterValue;
        }

        this.applyParams();
    }
}
