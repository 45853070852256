import {Injectable} from '@angular/core';

import {CrudService} from '../crud.service';
import {APP_CONFIG} from '../../app.config';
import {District} from '../../models/district';
import {catchError, publishLast, refCount} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {throwError as observableThrowError} from 'rxjs';

@Injectable()
export class FiasCityService extends CrudService {
    protected apiUrl = '/api/fias-cities/';

    search(query: string = null, area: number = null) {
        let queryString = '';
        if (query) {
            queryString = '?query=' + query;
            if (area) {
                queryString += '&area=' + area;
            }
        }
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + 'search/query' + queryString);
    };

    saveDistricts(cityId: number, districts: District[]) {
        return this.http.post(APP_CONFIG.backendHost + this.apiUrl + cityId + '/districts',
            districts)
            .pipe(
                publishLast(),
                refCount(),
                catchError((error: HttpErrorResponse) => {
                    return observableThrowError(
                        error.error,
                    );
                }),
            );
    }

    getRegions() {
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + 'regions');
    }
}
