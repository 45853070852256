import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'toLink'
})
export class ToLink implements PipeTransform {
    transform(value: string, ...args): any {
        if (/^(http|https):\/\/(www\.)?.*/i.test(value)) {
            return `<a href="${value}" target="_blank">Ссылка</a>`;
        }

        return value;
    }
}
