import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {SubField, Field} from '../../../../models/init-step';
import {
    Validators,
    FormArray,
    FormGroup,
    FormBuilder,
    AbstractControl,
    ValidatorFn,
    ValidationErrors, FormControl
} from '@angular/forms';
import {Step, StepChoice} from '../../../../models/step';
import {MgForceDirectedGraphComponent} from '../../../../shared/mg-force-directed-graph/mg-force-directed-graph.component';
import {GridsterConfig} from 'angular-gridster2';
import {FileUploader} from 'ng2-file-upload';
import {Router} from '@angular/router';
import {APP_CONFIG} from '../../../../app.config';
import {StepService} from '../../../../services/entity-services/step.service';
import {FormService} from '../../../../services/form.service';
import {Project} from '../../../../models/project';
import {AppService} from '../../../../services/app.service';
import {Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Brand} from '../../../../models/brand';
import {ProjectService} from '../../../../services/entity-services/project.service';
import * as QuillNamespace from 'quill';
import {SegmentBlockRule} from '../../../../models/segment-block';
import { Mechanic } from 'app/models/mechanic';
import { MechanicService } from 'app/services/entity-services/mechanic.service';

@Component({
    selector: 'app-contact-structure',
    templateUrl: './contact-structure.component.html',
    styleUrls: ['./contact-structure.component.scss'],
    providers: [StepService, MechanicService]
})
export class ContactStructureComponent implements OnInit, OnDestroy {

    project: Project;
    brands: Brand[];
    mechanics: Mechanic[];
    protected projectSubscription: Subscription;
    protected brandsSubscription: Subscription;
    protected mechanicsSubscription: Subscription;
    inProgress: boolean;

    stepForm: FormGroup;
    choiceForm: FormGroup;
    fieldForm: FormGroup;

    groupFieldTypes: string[];

    stack: Array<Step> = [];

    steps: Array<Step> = [];
    step: Step;

    delSteps: Array<Step> = [];
    delChoices: Array<StepChoice> = [];

    @ViewChild(MgForceDirectedGraphComponent)
    private graphComp: MgForceDirectedGraphComponent;

    graph: { links: any[], nodes: any[] };

    gridOptions: GridsterConfig;
    dashboard: Array<StepChoice>;

    graphOptions: any;

    selected: StepChoice;

    attachmentUploader: FileUploader;
    attachmentError: string;
    backendHost: string = APP_CONFIG.backendHost;

    editorConfig: {};

    fieldTypesWithValidationEndpoint = [
        'qr',
        'qr-check',
        'gift-selects',
        'swap-gift-selects',
        'date',
        'bdate',
        'select',
    ];

    fieldTypesWithMinMaxValues = [
        'number', 'group-checkbox'
    ];

    fieldTypesWithPayload = [
        'phone_verify',
        'personal-info-verify',
        'location-selects'
    ];

    segmentBlocks: Array<string>;
    segmentBlocksReadable: Object;

    personalInfoVerificationResponses: Array<string>;
    personalInfoVerificationResponsesReadable: Object;

    static eventStop(item, scope): void {
        // console.info('eventStop', item, scope);
    }

    static itemChange(item, scope): void {
        // console.info('itemChanged', item, scope);
    }

    static itemResize(item, scope): void {
        // console.info('itemResized', item, scope);
    }

    static itemInit(item): void {
        // console.info('itemInitialized', item);
    }

    static arrayControlUp(arrayIndex: number, array: FormArray) {
        if (arrayIndex) {
            const buf = array.at(arrayIndex - 1);
            array.setControl(arrayIndex - 1, array.at(arrayIndex));
            array.setControl(arrayIndex, buf);
        }
    }

    static arrayControlDown(arrayIndex: number, array: FormArray) {
        if (arrayIndex < array.length - 1) {
            const buf = array.at(arrayIndex + 1);
            array.setControl(arrayIndex + 1, array.at(arrayIndex));
            array.setControl(arrayIndex, buf);
        }
    }

    constructor(private fb: FormBuilder,
                private router: Router,
                private http: HttpClient,
                private stepService: StepService,
                private formService: FormService,
                private appService: AppService,
                private projectService: ProjectService) {
        this.project = this.appService.getCurProject();
        this.segmentBlocks = SegmentBlockRule.blocks;
        this.segmentBlocksReadable = SegmentBlockRule.blocksReadable;

        this.personalInfoVerificationResponses = [
            'success', 'failure'
        ];
        this.personalInfoVerificationResponsesReadable = {
            'success': 'Положительный ответ',
            'failure': 'Отрицательный ответ'
        };

        this.attachmentUploader = new FileUploader({
            url: APP_CONFIG.backendHost + '/api/steps/upload-attachment/',
        });

        this.groupFieldTypes = ['group-checkbox', 'group-radio', 'select'];

        this.gridOptions = {
            gridType: 'fit',
            compactUp: false,
            compactLeft: false,
            itemChangeCallback: ContactStructureComponent.itemChange,
            itemResizeCallback: ContactStructureComponent.itemResize,
            margin: 10,
            outerMargin: true,
            minCols: 12,
            maxCols: 12,
            maxItemCols: 50,
            minItemCols: 1,
            maxItemRows: 50,
            minItemRows: 1,
            defaultItemCols: 12,
            defaultItemRows: 1,
            fixedColWidth: 250,
            fixedRowHeight: 250,
            draggable: {
                enabled: true,
                stop: ContactStructureComponent.eventStop
            },
            resizable: {
                enabled: true,
                stop: ContactStructureComponent.eventStop
            },
            swap: false
        };

        const Quill: any = QuillNamespace;
        const FontAttributor = Quill.import('attributors/style/font');
        const SizeStyle = Quill.import('attributors/style/size');
        const AlignStyle = Quill.import('attributors/style/align');

        SizeStyle.whitelist = ['16px', false, '28px', '42px'];
        FontAttributor.whitelist = [
            'sans serif', 'serif', 'monospace', 'roboto'
        ];

        Quill.register(FontAttributor, true);
        Quill.register(SizeStyle, true);
        Quill.register(AlignStyle, true);

        this.editorConfig = {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],

                [{'header': 1}, {'header': 2}],               // custom button values
                [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
                [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
                [{'direction': 'rtl'}],                         // text direction

                [{'size': SizeStyle.whitelist}],    // custom dropdown
                [{'header': [1, 2, 3, 4, 5, 6, false]}],

                [{'color': []}, {'background': []}],          // dropdown with defaults from theme
                [{'align': []}],

                ['clean'],                                        // remove formatting button
            ]
        };

        this.graphOptions = {
            width: 800,
            height: 800,
            nodeRadius: 8,
            strokeWidth: 5,
            clickHandler: (d) => {
            }
        };

        this.stepForm = this.initStepForm();
        this.choiceForm = this.initChoiceForm();
        this.fieldForm = this.initFieldForm();

        this.inProgress = false;
    }

    ngOnInit(): void {
        this.initData();
        this.projectSubscription = this.appService.getCurProjectObservable().subscribe((project) => {
            if (typeof project !== 'undefined') {
                this.project = project;
                this.initData();
            }
        });
    }

    ngOnDestroy(): void {
        this.projectSubscription && this.projectSubscription.unsubscribe();
        this.brandsSubscription && this.brandsSubscription.unsubscribe();
        this.mechanicsSubscription && this.mechanicsSubscription.unsubscribe();
    }

    protected initData() {
        this.brandsSubscription = this.projectService.getBrands(this.project.id)
            .subscribe((response) => {
                this.brands = response.brands;
            });
        this.mechanicsSubscription = this.projectService.getMechanics(this.project.id)
            .subscribe((response) => {
                this.mechanics = response.mechanics;
            });
            

        this.step = new Step();
        this.steps = [];
        this.stack = [];

        this.genGraph();

        this.stepService.get().subscribe((data) => {
            for (const item of data) {
                item.stepId = this.generateStepId();
                this.steps.push(item);
            }

            // Replace real IDs to generated stepIds
            for (const item of this.steps) {
                if (item.fields) {
                    for (const field of item.fields) {
                        if ((this.hasPayload(field.type)) && field.payload) {
                            for (const rule of field.payload) {
                                this.changeIdToStepId(rule, 'next_step_id');
                            }
                        }
                    }
                }
            }

            this.prepareSteps();
            this.init();
        });
    }

    changeIdToStepId(field, propName) {
        if (field[propName]) {
            const step = this.steps.find((step) => step.id == field[propName]);
            if (step) {
                field[propName] = step.stepId;
            }
        }
    }

    initStepForm(): FormGroup {
        return this.fb.group({
            name: [null],
            description: [null, Validators.compose([Validators.required])],
            big_btn: [null],
            content_position: ['center'],
            report_order: [0],
            mechanic_id: [null]
        })
    }

    initChoiceForm(): FormGroup {
        return this.fb.group({
            title: [null],
            is_black: [true],
            type: [0],
            show_modal: [false],
            modal_text: [null],
            button_position: ['center', Validators.compose([Validators.required])],
            brand_id: [null]
        });
    }

    initFieldForm(): FormGroup {
        return this.fb.group({
            fields: this.fb.array([])
        });
    }

    prepareSteps(): void {
        for (const step of this.steps) {
            for (const choice of step.choices) {
                const result = this.steps.find((step) => step.id == choice.next_step_id);
                if (typeof result !== 'undefined') {
                    choice.next_step = result.stepId;
                }
            }
        }
    }

    init(): void {
        if (this.steps.length) {
            this.step = this.steps[0];
        } else {
            this.step = new Step();
            this.step.choices = [new StepChoice()];
            this.steps.push(this.step);
        }

        this.selected = null;
        this.dashboard = this.step.choices || [];

        this.stepForm = this.initStepForm();
        this.fillStepFormControls();

        this.choiceForm = this.initChoiceForm();

        this.fieldForm = this.initFieldForm();
        this.restoreFieldForm();

        this.genGraph();
        this.redrawGraph();

        this.attachmentUploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };
        this.attachmentUploader.onCompleteItem = (item, response, status, headers) => {
            this.attachmentError = null;
            this.step.attachment = null;

            if (status === 200) {
                this.step.attachment = JSON.parse(response);
            } else if (status === 400) {
                this.attachmentError = JSON.parse(response).error;
            } else {
                this.attachmentError = 'Внутренняя ошибка сервера';
            }
        };
    }

    /*
     * field functions
     */
    initField(): FormGroup {
        return this.fb.group({
            name: [null],
            type: ['text', Validators.compose([Validators.required])],
            subFields: this.fb.array([]),
            description: [null],
            required: [true],
            discoverPloomDistrict: [false],
            showComponentName: [false],
            stepFieldDisabled: [false],
            send_to_mindbox_as: [null],
            mask: [null],
            validation: [null],
            validateEndpoint: [null],
            fieldMin: [null],
            fieldMax: [null],
            brandId: [null],
            payload: this.fb.array([]),
        }, {validator: this.fieldMinIsLesserThanFieldMax});
    }

    fieldMinIsLesserThanFieldMax: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        const min = control.get('fieldMin');
        const max = control.get('fieldMax');
        if (!min || !max || !min.value || !max.value) {
            return null;
        }
        return Number(min.value) <= Number(max.value) ? null : {minMaxValidationError: true};
    };

    initSubField(): FormGroup {
        return this.fb.group({
            name: [null, Validators.compose([Validators.required])],
            fieldValue: [null]
        });
    }

    addField(): void {
        const control = <FormArray>this.fieldForm.controls['fields'];
        control.push(this.initField());
    }

    removeField(i): void {
        const control = <FormArray>this.fieldForm.controls['fields'];
        control.removeAt(i);
    }

    fieldUp(i): void {
        if (i) {
            const control = <FormArray>this.fieldForm.controls['fields'];
            const buf = control.at(i - 1);
            control.setControl(i - 1, control.at(i));
            control.setControl(i, buf);
        }
    }

    fieldDown(i): void {
        const control = <FormArray>this.fieldForm.controls['fields'];
        if (i < control.length - 1) {
            const buf = control.at(i + 1);
            control.setControl(i + 1, control.at(i));
            control.setControl(i, buf);
        }
    }

    selectFieldChanged(i): void {
        const controls = (<FormArray>this.fieldForm.controls['fields']).at(i),
            type = controls.get('type').value,
            subFields = <FormArray>controls.get('subFields'),
            payload = <FormArray>controls.get('payload');
        this.clearFormArray(payload);

        // добавление подполей
        for (let j = 0, size = subFields.controls.length; j < size; ++j) {
            subFields.removeAt(j);
        }
        if (this.groupFieldTypes.indexOf(type) !== -1) {
            subFields.push(this.initSubField());
        }

        this.restoreEmptyPayload(payload, type);
    }

    pushDefaultRules = (payload: FormArray, type: string) => {
        if (type === 'phone_verify') {
            this.pushBlockRule(
                new SegmentBlockRule({name: SegmentBlockRule.DEFAULT, next_step_id: null}),
                payload);
        }
        if (type === 'personal-info-verify') {
            this.pushBlockRule(
                new SegmentBlockRule({name: 'success', next_step_id: null}),
                payload);
            this.pushBlockRule(
                new SegmentBlockRule({name: 'failure', next_step_id: null}),
                payload);
        }
    };

    clearFormArray = (formArray: FormArray) => {
        if (formArray) {
            while (formArray.length !== 0) {
                formArray.removeAt(0)
            }
        }
    };

    addSubField(i): void {
        const control = <FormArray>this.fieldForm.controls['fields'];
        (<FormArray>control.at(i).get('subFields')).push(this.initSubField());
    }

    removeSubField(i, j): void {
        const control = <FormArray>(<FormArray>this.fieldForm.controls['fields']).at(i).get('subFields');
        control.removeAt(j);
    }

    subFieldUp(i, j): void {
        if (j) {
            const control = <FormArray>(<FormArray>this.fieldForm.controls['fields']).at(i).get('subFields');
            ContactStructureComponent.arrayControlUp(j, control);
        }
    }

    subFieldDown(i, j): void {
        const control = <FormArray>(<FormArray>this.fieldForm.controls['fields']).at(i).get('subFields');
        ContactStructureComponent.arrayControlDown(j, control);
    }

    /*
     * /field functions
     */


    changedOptions(): void {
        this.gridOptions.api.optionsChanged();
    }

    chooseItem(item): void | boolean {
        if (this.selected) {
            if (this.choiceForm.invalid) {
                this.formService.markControlsAsTouched(this.choiceForm);
                return false;
            }

            this.selected.title = this.choiceForm.controls['title'].value;
            this.selected.type = this.choiceForm.controls['type'].value;
            this.selected.show_modal = this.choiceForm.controls['show_modal'].value;
            this.selected.modal_text = this.choiceForm.controls['modal_text'].value;
            this.selected.is_black = this.choiceForm.controls['is_black'].value;
            this.selected.button_position = this.choiceForm.controls['button_position'].value;
            this.selected.brand_id = this.choiceForm.controls['brand_id'].value;
        }

        this.selected = item;
        this.choiceForm = this.initChoiceForm();
        this.choiceForm.controls['title'].setValue(this.selected.title);
        this.choiceForm.controls['type'].setValue(this.selected.type);
        this.choiceForm.controls['is_black'].setValue(this.selected.is_black);
        this.choiceForm.controls['show_modal'].setValue(this.selected.show_modal);
        this.choiceForm.controls['modal_text'].setValue(this.selected.modal_text);
        this.choiceForm.controls['button_position'].setValue(this.selected.button_position);
        this.choiceForm.controls['brand_id'].setValue(this.selected.brand_id);
    }

    isItemSelected(item): boolean {
        return item == this.selected;
    }

    removeItem(item): void {
        if (typeof item.id !== 'undefined') {
            this.delChoices.push(item);
        }

        this.dashboard.splice(this.dashboard.indexOf(item), 1);
        this.selected = null;
        this.redrawGraph();
    }

    addItem(): void {
        this.dashboard.push(new StepChoice());
    }

    addStep(): void | boolean {
        if (this.formsInvalid()) {
            return false;
        }

        this.saveToStack();

        this.step = new Step();
        this.step.stepId = this.generateStepId();

        this.stepForm = this.initStepForm();
        this.choiceForm = this.initChoiceForm();
        this.fieldForm = this.initFieldForm();

        this.selected.next_step = this.step.stepId;

        this.steps.push(this.step);

        this.selected = null;
        this.dashboard = [new StepChoice()];

        this.redrawGraph();
    }

    nextStep(): void | boolean {
        if (this.formsInvalid()) {
            return false;
        }

        this.saveToStack();

        this.stepForm = this.initStepForm();
        this.step = this.steps.find((step) => step.stepId === this.selected.next_step);

        this.fillStepFormControls();
        this.restoreFieldForm();

        this.dashboard = this.step.choices;
        this.selected = null;

        this.redrawGraph();
    }

    removeAttachment(): void {
        this.step.attachment = null;
    }

    removeStep(): void {
        if (typeof this.step.id !== 'undefined') {
            this.delSteps.push(this.step);
        }

        const pos = this.steps.indexOf(this.step),
            oldStep = this.step;

        this.steps.splice(pos, 1);
        if (pos > this.steps.length - 1) {
            this.step = this.steps[this.steps.length - 1];
        } else {
            this.step = this.steps[pos];
        }

        for (const step of this.steps) {
            if (step.choices) {
                for (const choice of step.choices) {
                    if (choice.next_step == oldStep.stepId) {
                        choice.next_step = null;
                    }
                }
            }
        }

        this.stepForm = this.initStepForm();
        this.fillStepFormControls();
        this.restoreFieldForm();
        this.dashboard = this.step.choices || [];
        this.selected = null;

        this.redrawGraph();
    }

    protected generateStepId() {
        let index = 1;

        if (this.steps.length) {
            for (const step of this.steps) {
                const stepId = step.stepId;
                if (stepId > index) {
                    index = stepId;
                }
            }
            ++index;
        }

        return index;
    }

    protected saveToStack(): void {
        this.fillStep();
        this.stack.push(this.step);
    }

    protected redrawGraph(): void {
        this.graphComp.reset();
        this.genGraph();
        this.graphComp.graph = this.graph;
        this.graphComp.ngOnInit();
    }

    protected formsInvalid(): boolean {
        if (this.stepForm.invalid || this.fieldForm.invalid) {
            this.formService.markControlsAsTouched(this.stepForm);
            this.formService.markControlsAsTouched(this.fieldForm);
            return true;
        }

        return false;
    }

    restoreFieldForm(): void {
        this.fieldForm = this.initFieldForm();

        const control = <FormArray>this.fieldForm.controls['fields'];
        if (this.step.fields) {
            for (const item of this.step.fields) {
                const data = {
                    uuid: [item.uuid],
                    name: [item.name],
                    type: [item.type, Validators.compose([Validators.required])],
                    subFields: this.restoreSubFields(item),
                    description: [item.description],
                    required: [item.required],
                    discoverPloomDistrict: [item.discoverPloomDistrict],
                    showComponentName: [item.showComponentName],
                    stepFieldDisabled: [item.stepFieldDisabled],
                    send_to_mindbox_as: [item.send_to_mindbox_as || null],
                    fieldMin: [item.fieldMin],
                    fieldMax: [item.fieldMax],
                };
                if (this.hasPayload(item.type)) {
                    data['payload'] = this.restorePayload(item);
                    data['brandId'] = item.brandId || null;
                }
                if (item.type === 'masked-text') {
                    data['mask'] = item.mask || null;
                }
                if (item.type === 'text-with-validation') {
                    data['validation'] = item.validation || null;
                }
                if (this.hasValidationEndpoint(item.type)) {
                    data['validateEndpoint'] = item.validateEndpoint || null;
                }
                if (item.type === 'gift-selects' || item.type === 'swap-gift-selects' || item.type === 'purchase-selects') {
                    data['brandId'] = item.brandId || null;
                }
                control.push(this.fb.group(data, {validator: this.fieldMinIsLesserThanFieldMax}));
            }
        }
    }

    restorePayload = (item: Field) => {
        if (item.type === 'phone_verify' || item.type === 'personal-info-verify') {
            return this.restoreBlockRules(item, item.type);
        }
        if (item.type === 'location-selects') {
            return this.restoreLocationSelectTexts(item, item.type)
        }

        return this.fb.array([]);
    };

    restoreEmptyPayload = (payload: FormArray, type) => {
        if (type === 'phone_verify' || type === 'personal-info-verify') {
            this.pushDefaultRules(payload, type);
        }
        if (type === 'location-selects') {
            this.pushDefaultLocationsSelectTexts(payload);
        }
    };

    restoreBlockRules = (item: Field, type: string) => {
        const blockRules = this.fb.array([]);

        if (this.hasPayload(item.type)) {
            if (item.payload && item.payload.length) {
                for (const rule of item.payload) {
                    this.pushBlockRule(
                        new SegmentBlockRule({name: rule.name, next_step_id: rule.next_step_id}),
                        blockRules);
                }
            }
        }
        if (!blockRules.length) {
            this.pushDefaultRules(blockRules, type);
        }
        return blockRules;
    };

    restoreLocationSelectTexts = (item: Field, type: string) => {
        const texts = this.fb.array([]);

        if (this.hasPayload(item.type)) {
            if (item.payload && item.payload.length) {
                for (const text of item.payload) {
                    texts.push(this.fb.group({
                        name: [text.name],
                        content: [text.content],
                    }));
                }
            }
        }

        if (!texts.value.find(availableCities => availableCities.name === 'availableCities')) {
            texts.push(this.fb.group({
                name: ['availableCities'],
                content: [[]],
            }));
        }

        if (!texts.length) {
            this.pushDefaultLocationsSelectTexts(texts);
        }
        return texts;
    };

    pushDefaultLocationsSelectTexts = (payload: FormArray) => {
        payload.push(this.fb.group({
            name: ['citySelectText'],
            content: [null],
        }));
        payload.push(this.fb.group({
            name: ['districtSelectText'],
            content: [null],
        }));
        payload.push(this.fb.group({
            name: ['availableCities'],
            content: [[]],
        }));
    };

    restoreSubFields = (item: Field) => {
        const subFields = this.fb.array([]);
        if (this.groupFieldTypes.indexOf(item.type) !== -1) {
            for (const sub of item.sub) {
                subFields.push(this.fb.group({
                    name: [sub.name, Validators.compose([Validators.required])],
                    fieldValue: [sub.fieldValue]
                }));
            }
        }

        return subFields;
    };


    pushBlockRule = (rule: SegmentBlockRule, array: FormArray) => {
        array.push(this.fb.group({
            name: [rule.name, Validators.compose([Validators.required])],
            next_step_id: [rule.next_step_id, Validators.compose([Validators.required])],
        }));
    };

    saveFields(): void {
        const fields = this.fieldForm.controls['fields'].value;
        this.step.fields = [];

        for (let i = 0, fieldsCount = fields.length; i < fieldsCount; ++i) {
            const field = new Field();
            field.uuid = fields[i].uuid;
            field.name = fields[i].name;
            field.description = fields[i].description;
            field.required = fields[i].required;
            field.stepFieldDisabled = fields[i].stepFieldDisabled;
            field.send_to_mindbox_as = fields[i].send_to_mindbox_as;
            field.type = fields[i].type;
            field.order = i;
            if (this.hasPayload(fields[i].type)) {
                this.savePayload(field, fields[i].payload);
                field.brandId = fields[i].brandId || null;
            }
            if (fields[i].type === 'masked-text') {
                field.mask = fields[i].mask || null;
            }
            if (fields[i].type === 'text-with-validation') {
                field.validation = fields[i].validation || null;
            }
            if (this.hasValidationEndpoint(fields[i].type)) {
                field.validateEndpoint = fields[i].validateEndpoint || null;
            }
            if (fields[i].type === 'gift-selects' || fields[i].type === 'swap-gift-selects' || fields[i].type === 'purchase-selects') {
                field.brandId = fields[i].brandId || null;
            }
            if (fields[i].type === 'location-selects') {
                field.discoverPloomDistrict = fields[i].discoverPloomDistrict || null;
            }
            if (fields[i].type === 'product-selects') {
                field.showComponentName = fields[i].showComponentName || null;
            }
            if (this.hasMinMaxValues(fields[i].type)) {
                field.fieldMin = parseInt(fields[i].fieldMin, 10);
            }
            if (this.hasMinMaxValues(fields[i].type) || fields[i].type === 'personal-info-verify') {
                field.fieldMax = parseInt(fields[i].fieldMax, 10);
            }
            if (this.groupFieldTypes.indexOf(field.type) !== -1) {
                this.saveSubFields(field, fields[i].subFields)
            }
            this.step.fields.push(field);
        }
    }

    saveSubFields = (field: Field, subFields) => {
        field.sub = [];
        for (let j = 0, subCount = subFields.length; j < subCount; ++j) {
            const sub = new SubField();
            sub.name = subFields[j].name;
            sub.fieldValue = subFields[j].fieldValue;
            sub.order = j;
            field.sub.push(sub);
        }
    };

    savePayload = (field: Field, payload) => {
        if (field.type === 'phone_verify' || field.type === 'personal-info-verify') {
            return this.saveBlockRules(field, payload);
        }
        if (field.type === 'location-selects') {
            return this.saveLocationSelectTexts(field, payload)
        }
    };

    saveBlockRules = (field: Field, blockRules) => {
        field.sub = [];
        for (let j = 0, rulesCount = blockRules.length; j < rulesCount; ++j) {
            const rule = new SegmentBlockRule();
            rule.name = blockRules[j].name;
            rule.next_step_id = blockRules[j].next_step_id;
            field.payload.push(rule);
        }
    };

    saveLocationSelectTexts = (field: Field, texts) => {
        field.sub = [];
        texts.map((item) => {
            const text = {'name': item.name, 'content': item.content};
            field.payload.push(text);
        });
    };

    hasValidationEndpoint(type) {
        return this.fieldTypesWithValidationEndpoint.includes(type);
    }

    hasMinMaxValues(type) {
        return this.fieldTypesWithMinMaxValues.includes(type);
    }

    hasPayload(type) {
        return this.fieldTypesWithPayload.includes(type);
    }

    genGraph(): void {
        this.graph = {
            links: [],
            nodes: []
        };

        for (const step of this.steps) {
            const index = this.steps.indexOf(step);
            if (index == 0 || index == this.steps.length - 1) {
                step.nodeColor = '#00ff21';
            } else {
                step.nodeColor = '#4249ff';
            }

            if (step == this.step) {
                step.nodeColor = '#ff173c';
            }

            this.graph.nodes.push(step);
        }

        for (const node of this.graph.nodes) {
            const step = node;
            if (step.choices) {
                for (const choice of step.choices) {
                    if (choice.next_step !== null && typeof choice.next_step !== 'undefined') {
                        const target = this.graph.nodes.find((item) => item.stepId == choice.next_step);
                        this.graph.links.push({
                            source: step,
                            target: target
                        });
                    }
                }
            }
        }

    }

    selectStep(value): void {
        if (value !== 'null') {
            this.selected.next_step = this.steps.find((step) => step.stepId == value).stepId;
        } else {
            this.selected.next_step = undefined;
            this.selected.next_step_id = null;
        }
        this.redrawGraph();
    }

    onClickNode($event): void | boolean {
        if (this.step == $event || this.formsInvalid()) {
            return false;
        }

        this.stack = [];

        this.fillStep();

        for (const step of this.steps) {
            if (step == $event) {
                this.step = step;
                break;
            }

            this.stack.push(step);
        }

        this.fillStepFormControls();
        this.restoreFieldForm();

        this.dashboard = this.step.choices;
        this.selected = null;

        this.redrawGraph();
    }

    inputChoiceName(): void {
        this.selected.title = this.choiceForm.controls['title'].value;
    }

    onChoiceTextColorChanged(): void {
        this.selected.is_black = this.choiceForm.controls['is_black'].value;
    }

    onChoiceShowModalChanged(): void {
        this.selected.show_modal = this.choiceForm.controls['show_modal'].value;
        if (this.choiceForm.controls['show_modal'].value === false) {
            this.selected.modal_text = null;
        }
    }

    onChoiceModalTextChanged(): void {
        this.selected.modal_text = this.choiceForm.controls['modal_text'].value;
    }

    onChoiceTypeChanged(): void {
        this.selected.type = this.choiceForm.controls['type'].value;
    }

    onChoiceButtonPositionChanged(): void {
        this.selected.button_position = this.choiceForm.controls['button_position'].value;
    }

    onChoiceBrandIdChanged(): void {
        this.selected.brand_id = this.choiceForm.controls['brand_id'].value;
    }

    onChoiceMechanicIdChanged(): void {
        this.step.mechanic_id = this.stepForm.controls['mechanic_id'].value;
    }

    showMindboxSelect(checked, field): void {
        field.controls['send_to_mindbox_as'].setValue(checked === true ? 'email' : null);
    }

    protected fillStepFormControls(): void {
        this.stepForm.controls['name'].setValue(this.step.name);
        this.stepForm.controls['description'].setValue(this.step.description);
        this.stepForm.controls['big_btn'].setValue(this.step.big_btn);
        this.stepForm.controls['content_position'].setValue(this.step.content_position);
        this.stepForm.controls['mechanic_id'].setValue(this.step.mechanic_id);
        this.stepForm.controls['report_order'].setValue(this.step.report_order);
    }

    protected fillStep(): void {
        this.step.name = this.stepForm.controls['name'].value;
        this.step.description = this.stepForm.controls['description'].value;
        this.step.big_btn = this.stepForm.controls['big_btn'].value;
        this.step.content_position = this.stepForm.controls['content_position'].value;
        this.step.mechanic_id = this.stepForm.controls['mechanic_id'].value;
        this.step.report_order = this.stepForm.controls['report_order'].value;
        this.step.choices = this.dashboard;
        this.saveFields();
    }

    save(): void {
        this.formService.markControlsAsTouched(this.fieldForm);
        if (this.fieldForm.invalid) {
            console.log(this.fieldForm);
            return;
        }

        this.inProgress = true;
        this.fillStep();

        // обновляем структуру контакта
        this.stepService.updateContactStructure(this.project.id, {
            del: {
                steps: this.delSteps.map((step) => step.id),
                choices: this.delChoices.map((choice) => choice.id)
            },
            steps: this.steps
        }).subscribe(() => {
            this.router.navigate(['/']);
        });
    }

    fieldTypeEquals = (field: FormControl, type: string): boolean => {
        return field.get('type').value === type;
    };

    shouldShowBrandsSelect = (field: FormControl): boolean => {
        return this.fieldTypeEquals(field, 'gift-selects')
            || this.fieldTypeEquals(field, 'purchase-selects')
            || this.fieldTypeEquals(field, 'swap-gift-selects')
            || this.fieldTypeEquals(field, 'phone_verify')
    }
}
