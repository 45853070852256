import {Injectable} from '@angular/core';
import {AppService} from '../app.service';
import {APP_CONFIG} from '../../app.config';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {publishLast, refCount} from 'rxjs/operators';

@Injectable()
export class ContactService {
    url: string;

    constructor(private http: HttpClient, private appService: AppService) {
        this.setUrl(this.appService.getCurProject());
        this.appService.getCurProjectObservable().subscribe((project) => {
            this.setUrl(project);
        });
    }

    protected setUrl(project): void {
        if (project) {
            this.url = APP_CONFIG.backendHost + '/api/projects/' + project.id;
        }
    }

    get(params?: { [key: string]: any }): Observable<any> {

        let urlParams = new HttpParams();

        if (params) {
            for (let key in params) {
                urlParams = urlParams.set(key, params[key]);
            }
        }

        let options = {params: urlParams};

        return this.http.get(this.url + '/contacts', options)
            .pipe(
              publishLast(),
              refCount(),
            )
    }

    all(params?: { [key: string]: any }): Observable<any> {

        let urlParams = new HttpParams();

        if (params) {
            for (let key in params) {
                urlParams = urlParams.set(key, params[key]);
            }
        }

        let options = {params: urlParams};

        return this.http.get(this.url + '/all-contacts', options)
            .pipe(
              publishLast(),
              refCount(),
            )
    }

    contactsWithFields(params?: { [key: string]: any }): Observable<any> {

        let urlParams = new HttpParams();

        if (params) {
            for (let key in params) {
                urlParams = urlParams.set(key, params[key]);
            }
        }

        let options = {params: urlParams};

        return this.http.get(this.url + '/contacts-with-fields', options)
            .pipe(
              publishLast(),
              refCount(),
            )
    }

    contactsFromPaper(params?: { [key: string]: any }): Observable<any> {
        let urlParams = new HttpParams();

        if (params) {
            for (let key in params) {
                urlParams = urlParams.set(key, params[key]);
            }
        }

        let options = {params: urlParams};

        return this.http.get(this.url + '/contacts-from-paper', options)
            .pipe(
              publishLast(),
              refCount(),
            )
    }

    contactsFromPaperExport(params: any) {
        let query = [];
        Object.keys(params).forEach((key) => {
            query.push(`${key}=${params[key]}`);
        });
        window.open(`${this.url}/contacts-from-paper-export?${query.join('&')}`);
    }

    createContactsFromPaper(data: any): Observable<any> {
        return this.http.post(this.url + '/contacts-from-paper', data)
            .pipe(
              publishLast(),
              refCount(),
            )
    }

    deleteContactsFromPaper(workScheduleId: number): Observable<any> {
        return this.http.delete(this.url + '/work-schedules/' + workScheduleId + '/contacts-from-paper')
            .pipe(
              publishLast(),
              refCount(),
            )
    }

    statistics(): Observable<any> {
        return this.http.get(this.url + '/contacts-statistics')
            .pipe(
              publishLast(),
              refCount(),
            )
    }

    statisticByCities(page: number = 1): any {
        return this.http.get(this.url + '/contacts-statistics-by-cities?page=' + page);
    }

    allContactsExport(params: any): void {
        window.open(`${this.url}/all-contacts-export?group=${params.group}&from_date=${params.from_date}&to_date=${params.to_date}`, "_blank");
    }

    contactsExport(params: any): void {
        let query = [];
        Object.keys(params).forEach((key) => {
            query.push(`${key}=${params[key]}`);
        });
        window.open(`${this.url}/contacts-export?${query.join('&')}`, '_blank');
    }

    contactsWithFieldsExport(params: any): void {
        window.open(`${this.url}/contacts-with-fields-export?city=${params.city}&outlet=${params.outlet}&promouter=${params.promouter}&from_date=${params.from_date}&to_date=${params.to_date}`, "_blank");
    }
}
