import {NgModule} from '@angular/core';

import {MenuItems} from './menu-items/menu-items';
import {AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective} from './accordion';
import {MgForceDirectedGraphComponent} from './mg-force-directed-graph/mg-force-directed-graph.component';
import {ExceptPipe} from './pipes/except.pipe';
import {TitleByTypePipe} from './pipes/titile-by-type.pipe';
import {ContactComponent} from './contact/contact.component';
import {InitStepsComponent} from './init-steps/init-steps.component';
import {ViolationSettingsComponent} from './violation-settings/violation-settings.component';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CommonModule} from '@angular/common';
import {RolesPipe} from './pipes/roles.pipe';
import {MgScheduleImportComponent} from './mg-schedule-import/mg-schedule-import.component';
import {FileUploadModule} from 'ng2-file-upload';
import {MgInfiniteScrollDirective} from './directives/mg-infinite-scroll.directive';
import {MgCrudModule} from './mg-crud/mg-crud.module';
import {NumberToExcelColumnPipe} from '../components/reports/shared/pipes/number-to-excel-column.pipe';
import {ToLink} from '../components/reports/shared/pipes/to-link';
import {TypeaheadComponent} from './filters/typeahead/typeahead.component';
import {NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {TypeaheadPromouterComponent} from './filters/typeahead-promouter/typeahead-promouter.component';
import {TypeaheadSupervisorComponent} from './filters/typeahead-supervisor/typeahead-supervisor.component';
import {TypeaheadOutletComponent} from './filters/typeahead-outlet/typeahead-outlet.component';
import {TypeaheadConsultantComponent} from './filters/typeahead-consultant/typeahead-consultant.component';
import {TypeaheadCityComponent} from './filters/typeahead-city/typeahead-city.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {SelectComponent} from './select/select.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {GoogleMapComponent} from './google-map/google-map.component';
import {FieldDescriptionComponent} from './fields/parts/description/description.component';
import {BrandsSelectComponent} from './fields/parts/brands-select/brands-select.component';
import {FieldRulesComponent} from './fields/parts/rules/rules.component';
import {FieldMinMaxValuesComponent} from './fields/parts/min-max-values/min-max-values.component';
import {FieldCheckboxComponent} from './fields/parts/checkbox/checkbox.component';
import {FieldMindboxSelectComponent} from './fields/parts/mindbox-select/mindbox-select.component';
import {FieldLocationSelectComponent} from './fields/parts/location-select/location-select.component';
import {FieldCitiesSelectComponent} from './fields/parts/cities-select/cities-select.component';
import {ThemeTemplatePropertiesComponent} from './design/theme-template-properties/theme-template-properties.component';
import {FooterComponent} from './design/theme-template-properties/templates/footer/footer.component';
import {HeaderComponent} from './design/theme-template-properties/templates/header/header.component';
import {PropertyComponent} from './design/theme-template-properties/templates/parts/property/property.component';
import {ColorPickerComponent} from './design/theme-template-properties/templates/parts/color-picker/color-picker.component';
import {InputComponent} from './design/theme-template-properties/templates/input/input.component';
import {ButtonComponent} from './design/theme-template-properties/templates/button/button.component';
import {BackgroundComponent} from './design/theme-template-properties/templates/background/background.component';
import {ImageUploadComponent} from './design/theme-template-properties/templates/parts/image-upload/image-upload.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {MenuComponent} from './design/theme-template-properties/templates/menu/menu.component';
import {TextComponent} from './design/theme-template-properties/templates/text/text.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        FileUploadModule,
        MgCrudModule,
        CKEditorModule,
        NgbTypeaheadModule,
        NgSelectModule,
        ColorPickerModule,
    ],
    declarations: [
        AccordionAnchorDirective,
        AccordionLinkDirective,
        AccordionDirective,
        MgForceDirectedGraphComponent,
        ExceptPipe,
        TitleByTypePipe,
        RolesPipe,
        NumberToExcelColumnPipe,
        ToLink,
        ContactComponent,
        InitStepsComponent,
        ViolationSettingsComponent,
        MgScheduleImportComponent,
        MgInfiniteScrollDirective,
        TypeaheadComponent,
        TypeaheadPromouterComponent,
        TypeaheadSupervisorComponent,
        TypeaheadOutletComponent,
        TypeaheadCityComponent,
        TypeaheadConsultantComponent,
        SelectComponent,
        GoogleMapComponent,
        FieldDescriptionComponent,
        BrandsSelectComponent,
        FieldRulesComponent,
        FieldMinMaxValuesComponent,
        FieldCheckboxComponent,
        FieldMindboxSelectComponent,
        FieldLocationSelectComponent,
        FieldCitiesSelectComponent,
        ThemeTemplatePropertiesComponent,
        FooterComponent,
        HeaderComponent,
        PropertyComponent,
        ColorPickerComponent,
        InputComponent,
        ButtonComponent,
        BackgroundComponent,
        ImageUploadComponent,
        MenuComponent,
        TextComponent
    ],
    exports: [
        AccordionAnchorDirective,
        AccordionLinkDirective,
        AccordionDirective,
        MgForceDirectedGraphComponent,
        ExceptPipe,
        TitleByTypePipe,
        RolesPipe,
        NumberToExcelColumnPipe,
        ToLink,
        InitStepsComponent,
        ViolationSettingsComponent,
        MgScheduleImportComponent,
        MgInfiniteScrollDirective,
        TypeaheadComponent,
        TypeaheadPromouterComponent,
        TypeaheadSupervisorComponent,
        TypeaheadOutletComponent,
        TypeaheadCityComponent,
        TypeaheadConsultantComponent,
        SelectComponent,
        GoogleMapComponent,
        BrandsSelectComponent,
        FieldRulesComponent,
        FieldDescriptionComponent,
        FieldMinMaxValuesComponent,
        FieldCheckboxComponent,
        FieldMindboxSelectComponent,
        FieldLocationSelectComponent,
        FieldCitiesSelectComponent,
        ThemeTemplatePropertiesComponent,
        FooterComponent,
        HeaderComponent,
        PropertyComponent,
        ColorPickerComponent,
        InputComponent,
        ButtonComponent,
        BackgroundComponent,
        ImageUploadComponent
    ],
    providers: [MenuItems]
})
export class SharedModule {
}
