import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../../services/app.service';
import {TeamService} from '../../services/entity-services/team.service';
import {User} from '../../models/user';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DialogService} from '../../shared/dialog/dialog.service';
import {Team} from '../../models/team';
import {City} from '../../models/city';

import {FormService} from '../../services/form.service';

import {
    debounceTime,
    switchMap,
} from 'rxjs/operators';

@Component({
    selector: 'app-teams',
    templateUrl: './teams.component.html',
    styleUrls: ['./teams.component.scss'],
    providers: [
        TeamService,
    ]
})
export class TeamsComponent implements OnInit, OnDestroy {

    @ViewChild('add') addModal: ElementRef;
    @ViewChild('edit') editModal: ElementRef;
    @ViewChild('consultantListModal') consultantListModal: ElementRef;
    @ViewChild('cityListModal') cityListModal: ElementRef;

    teams: Team[] = [];
    consultants: User[] = [];
    teamLeaders: User[] = [];

    selectedTeam: Team;
    selectedConsultants: User[];
    selectedCities: City[];
    consultantList: User[] = [];
    cityList: City[] = [];

    formAdd: FormGroup;
    formEdit: FormGroup;

    params: any = {};
    params$: BehaviorSubject<any>;
    teamsSubscription$: Subscription;
    projectSubscription$: Subscription;
    teamLeadersSubscription$: Subscription;
    cityInputSubscription: Subscription;

    cityLoading = false;
    cityInput$ = new Subject<string>();
    selectedCity = null;
    count = 0;


    leaderLoading = false;
    leaderInput$ = new Subject<string>();
    leaderInputSubscription: Subscription;

    consultantLoading = false;
    consultantInput$ = new Subject<string>();
    selectedConsultant = null;
    consultantInputSubscription: Subscription;

    searchModel: any;

    messages = {
        emptyMessage: 'Нет данных',
        totalMessage: 'всего'
    };

    private modalRef: NgbModalRef;

    constructor(private fb: FormBuilder,
                private appService: AppService,
                private teamService: TeamService,
                private modalService: NgbModal,
                private dialogService: DialogService,
                private formService: FormService) {
        this.consultants = [];
        this.consultantList = [];
        this.cityList = [];
        this.selectedConsultants = [];
    }

    ngOnInit(): void {
        this.initData();
        this.projectSubscription$ = this.appService.getCurProjectObservable().subscribe((project) => {
            if (typeof project !== 'undefined') {
                this.initData();
            }
        });

        this.formAdd = this.fb.group({
            name: [null, Validators.compose([Validators.required])],
            leader_id: [null, Validators.compose([Validators.required])],
        });

        this.formEdit = this.fb.group({
            id: [null],
            name: [null, Validators.compose([Validators.required])],
            leader_id: [null, Validators.compose([Validators.required])],
        });

        this.subscribeToCityOptions();
        this.subscribeToConsultantOptions();
    }

    initData(): void {
        this.getTeam(1, 10);

        this.subscribeToLeaderOptions();

        this.leaderLoading = true;
        this.teamLeadersSubscription$ = this.teamService.getAvailableTeamLeaders().subscribe((leaders: User[]) => {
            this.teamLeaders = leaders;
            this.leaderLoading = false;
        });
    }

    ngOnDestroy(): void {
        const {teamsSubscription$, teamLeadersSubscription$, projectSubscription$} = this;
        if (teamsSubscription$) {
            teamsSubscription$.unsubscribe();
        }
        if (teamLeadersSubscription$) {
            teamLeadersSubscription$.unsubscribe();
        }

        if (projectSubscription$) {
            projectSubscription$.unsubscribe();
        }
    }

    getTeam = (page = 1, limit = 10) => {
        this.params = {
            page,
            limit,
        };

        this.params$ = new BehaviorSubject<any>(this.params);
        this.teamsSubscription$ = this.params$
            .pipe(
                debounceTime(300),
                switchMap(params => this.teamService.get(params)),
            )
            .subscribe((response: any) => {
                this.teams = response.data;
                this.count = response.count;
            });
    };

    private subscribeToLeaderOptions() {
        this.leaderInputSubscription = this.leaderInput$
            .pipe(
                debounceTime(500),
            )
            .subscribe(
                value => {
                    this.leaderLoading = true;
                    this.teamLeaders = [];
                    if (value && value.length > 0) {
                        this.teamService.getAvailableTeamLeaders(value).subscribe((leaders: User[]) => {
                            this.teamLeaders = leaders;
                            this.leaderLoading = false;
                        });
                    } else {
                        this.teamService.getAvailableTeamLeaders().subscribe((leaders: User[]) => {
                            this.teamLeaders = leaders;
                            this.leaderLoading = false;
                        });
                    }
                });
    }

    page($event): void {
        this.params.page = $event.offset + 1;
        this.applyParams();
    }

    openActionModal(id?): void {
        if (id) {
            const team = this.teams.find((teamItem) => teamItem.id === id);
            this.formEdit.controls['id'].setValue(team.id);
            this.formEdit.controls['name'].setValue(team.name);
            this.formEdit.controls['leader_id'].setValue(team.leader.id);
            this.modalRef = this.modalService.open(this.editModal, {backdrop: 'static'});
        } else {
            this.modalRef = this.modalService.open(this.addModal, {backdrop: 'static'});
        }
    }

    openConsultantListModal(id): void {
        this.selectedTeam = this.teams.find(team => team.id === id);
        this.teamService.getAvailableConsultants(this.selectedTeam.id).subscribe((availableConsultants: User[]) => {
            this.teamService.getTeamConsultants(this.selectedTeam.id).subscribe((consultants: User[]) => {
                this.selectedConsultants = consultants;
                this.consultantList = availableConsultants.filter((availableConsultant) => {
                    return !this.selectedConsultants.find((teamConsultant) => availableConsultant.id === teamConsultant.id);
                });
            });
            this.modalRef = this.modalService.open(this.consultantListModal, {backdrop: 'static'});
        });
    }

    openCityListModal(id): void {
        this.selectedTeam = this.teams.find(team => team.id === id);
        this.teamService.getAvailableCities(this.selectedTeam.id).subscribe((availableCities: City[]) => {
            this.teamService.getTeamCities(this.selectedTeam.id).subscribe((cities: City[]) => {
                this.selectedCities = cities;
                this.cityList = availableCities.filter((availableCity) => {
                    return !this.selectedCities.find((teamCity) => availableCity.id === teamCity.id);
                });
            });
            this.modalRef = this.modalService.open(this.cityListModal, {backdrop: 'static'});
        });
    }

    addConsultant(consultantId): void {
        const consultant = this.consultantList.find((consultantItem) => consultantItem.id === consultantId);
        const index = this.consultantList.indexOf(consultant);

        if (index !== -1) {
            this.consultantList.splice(index, 1);
            this.selectedConsultants.push(consultant);
        }
    }

    removeConsultant(consultantId): void {
        this.selectedConsultants = this.selectedConsultants.filter((consultant) => {
            if (consultant.id === consultantId) {
                this.consultantList.push(consultant);
                return false;
            }
            return true;
        });
    }

    addCity(cityId): void {
        const city = this.cityList.find((cityItem) => cityItem.id === cityId);
        const index = this.cityList.indexOf(city);

        if (index !== -1) {
            this.selectedCity = null;
            this.selectedCities.push(city);
            this.cityList = this.cityList.filter((cityItem) => cityItem.id !== cityId);
        }
    }

    removeCity(cityId): void {
        this.selectedCities = this.selectedCities.filter((city) => {
            if (city.id === cityId) {
                this.cityList.push(city);
                return false;
            }
            return true;
        });
    }

    saveTeamConsultants(): void {
        this.teamService
            .addConsultants(this.selectedTeam.id, this.selectedConsultants.map((item) => item.id))
            .subscribe((res) => {
                this.selectedTeam = null;
                this.modalRef.close();
            });
    }

    saveCities(): void {
        this.teamService
            .addCities(this.selectedTeam.id, this.selectedCities.map((item) => item.id))
            .subscribe((res) => {
                this.selectedTeam = null;
                this.modalRef.close();
            });
    }


    onAdd(): void {
        this.formService.markControlsAsTouched(this.formAdd);

        if (this.formAdd.valid) {
            const team = new Team();
            team.name = this.formAdd.value.name;
            team.leader = new User();
            team.leader.id = this.formAdd.value.leader_id;

            this.teamService.create(team).subscribe(data => {
                this.teams.push(data);
                this.formAdd.reset();
                this.modalRef.close();
                this.initData();
            });
        }
    }

    onEdit(): void {
        this.formService.markControlsAsTouched(this.formEdit);

        if (this.formEdit.valid) {
            const team = new Team();
            team.id = this.formEdit.value.id;
            team.name = this.formEdit.value.name;
            team.leader = new User();
            team.leader.id = this.formEdit.value.leader_id;

            this.teamService.update(team).subscribe(data => {
                const updatedTeam = this.teams.find((teamItem) => teamItem.id === team.id);
                updatedTeam.name = data.name;
                updatedTeam.leader = data.leader;
                this.modalRef.close();
            });
        }
    }

    onDelete(id): void {
        this.dialogService.confirm().then((res) => {
            if (res) {
                this.teamService.del(id).subscribe(data => {
                    // this.teams = this.teams.filter((team) => team.id !== id);

                    this.getTeam(this.params.page, 10);
                });
            }
        });
    }

    protected applyParams(): void {
        this.params$.next(this.params);
    }

    protected userIsDeveloper(): boolean {
        return this.appService.getCurrentUser().role === 'developer';
    }

    userIsAdminOrManager(): boolean {
        return this.appService.getCurrentUser().role === 'admin' || this.appService.getCurrentUser().role === 'manager';
    }

    protected userIsAdmin(): boolean {
        return this.appService.getCurrentUser().role === 'admin';
    }

    protected userIsManager(): boolean {
        return this.appService.getCurrentUser().role === 'manager';
    }

    protected userIsTeamLeader(): boolean {
        return this.appService.getCurrentUser().role === 'team_leader';
    }

    private subscribeToCityOptions() {
        this.cityInputSubscription = this.cityInput$
            .pipe(
                debounceTime(500),
            )
            .subscribe(
                value => {
                    // TODO if null then load default cities list
                    if (value && value.length > 1) {
                        this.cityLoading = true;
                        this.cityList = [];
                        this.teamService.getAvailableCities(this.selectedTeam.id, value).subscribe((availableCities: City[]) => {
                            this.cityList = availableCities.filter((availableCity) => {
                                return !this.selectedCities.find((teamCity) => availableCity.id === teamCity.id);
                            });
                            this.cityLoading = false;
                        });
                    }
                });
    }

    private subscribeToConsultantOptions() {
        this.consultantInputSubscription = this.consultantInput$
            .pipe(
                debounceTime(500),
            )
            .subscribe(
                value => {
                    if (value && value.length > 1) {
                        this.consultantLoading = true;
                        this.consultantList = [];
                        this.teamService.getAvailableConsultants(this.selectedTeam.id, value).subscribe((availableConsultants: User[]) => {
                            this.consultantList = availableConsultants.filter((availableConsultant) => {
                                return !this.selectedConsultants.find((teamConsultant) => availableConsultant.id === teamConsultant.id);
                            });
                            this.consultantLoading = false;
                        });
                    }
                });
    }

    search(): void {
        this.params.search = this.searchModel;
        console.log(this.params);
        this.applyParams();
    }
}

