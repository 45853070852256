import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';

@Component({
    selector: 'field-location-select',
    templateUrl: './location-select.component.html',
    styleUrls: ['./location-select.component.scss']
})
export class FieldLocationSelectComponent implements OnInit {

    @Input() field: FormGroup;

    texts: any;

    payloadTexts = [
        'citySelectText',
        'districtSelectText'
    ];

    placeholders = {
        citySelectText: 'Текст селекта с городами',
        districtSelectText: 'Текст селекта с округами'
    };

    constructor(private fb: FormBuilder) {
    }

    ngOnInit() {
        const payload = <FormArray>this.field.get('payload');
        const controls = payload.controls;
        this.texts = controls.filter((item) => {
            const control = <FormGroup>item;
            return this.payloadTexts.includes(control.controls['name'].value);
        });
    }

    buildPlaceholder = (name) => {
        return this.placeholders[name];
    }
}
