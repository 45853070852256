import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './components/layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './components/layouts/auth/auth-layout.component';
import { WorkSchedulesComponent } from './components/work-schedules/work-schedules.component';
import { ViolationsComponent } from './components/violations/violations.component';
import { WorkBreaksComponent } from './components/work-breaks/work-breaks.component';
import { UserComponent } from './components/user/user.component';
import { TeamsComponent } from './components/teams/teams.component';
import { TestDrivesComponent } from './components/test-drives/test-drives.component';

import { RoleGuard } from './guards/role.guard';
import { AuthGuard } from './guards/auth.guard';
import { ProjectGuard } from './guards/project.guard';

export const AppRoutes: Routes = [
    {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        // canActivateChild: [AuthGuard],
        children: [
            {
                path: 'reports',
                canActivate: [ProjectGuard],
                loadChildren: './components/reports/reports.module#ReportsModule',
            },
            {
                path: 'multi-reports',
                canActivate: [ProjectGuard],
                loadChildren: './components/multi-reports/multi-reports.module#MultiReportsModule',
            },
            {
                path: 'violations',
                canActivate: [ProjectGuard],
                component: ViolationsComponent,
                data: {
                    heading: 'Нарушения',
                },
            },
            {
                path: 'work-breaks',
                canActivate: [ProjectGuard],
                component: WorkBreaksComponent,
                data: {
                    heading: 'Перерывы',
                },
            },
            {
                path: 'work-schedules',
                canActivate: [ProjectGuard],
                component: WorkSchedulesComponent,
                data: {
                    heading: 'График работы',
                },
            },
            {
                path: 'handbooks',
                canActivate: [RoleGuard],
                loadChildren: './components/handbooks/handbooks.module#HandbooksModule',
                data: {
                    roles: ['developer', 'admin', 'manager'],
                },
            },
            {
                path: 'master',
                canActivate: [RoleGuard],
                loadChildren: './components/master/master.module#MasterModule',
                data: {
                    roles: ['developer', 'admin'],
                },
            },
            {
                path: '',
                loadChildren: './components/dashboard/dashboard.module#DashboardModule',
            },
            {
                path: 'settings',
                loadChildren: './components/settings/settings.module#SettingsModule',
                data: {
                    roles: ['developer', 'admin'],
                },
            },
            {
                path: 'users',
                canActivate: [RoleGuard],
                component: UserComponent,
                data: {
                    heading: 'Пользователи',
                    roles: ['developer', 'admin', 'manager', 'team_leader'],
                },
            },
            {
                path: 'teams',
                canActivate: [ProjectGuard, RoleGuard],
                component: TeamsComponent,
                data: {
                    heading: 'Команды',
                    roles: ['developer', 'admin', 'manager'],
                },
            },
            {
                path: 'address-programs',
                loadChildren: './components/address-programs/address-programs.module#AddressProgramsModule',
            },
            {
                path: 'stock',
                loadChildren: './components/stock/stock.module#StockModule',
            },
            {
                path: 'sales',
                loadChildren: './components/sales/sales.module#SalesModule',
            },
            {
                path: 'test-drives',
                canActivate: [ProjectGuard, RoleGuard],
                component: TestDrivesComponent,
                data: {
                    heading: 'Аренда',
                    roles: ['developer', 'admin'],
                },
            },
            {
                path: 'monitoring',
                canActivate: [RoleGuard],
                loadChildren: './components/monitoring/monitoring.module#MonitoringModule',
                data: {
                    roles: ['developer', 'admin', 'manager', 'team_leader'],
                },
            },
        ],
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: 'auth',
            loadChildren: './components/authentication/authentication.module#AuthenticationModule',
        }],
    },
    {
        path: '**',
        redirectTo: 'error/404',
    }
];

