import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

export class FilterConfig {
    name: string;
    type: 'input' | 'select' | 'datepicker' | 'toggleButtons';
    options?: {
        key: string | number,
        value: string | number
    }[];
    label?: string = '';
    placeholder?: string = '';
}

@Component({
    selector: 'mg-crud-filter',
    templateUrl: './mg-crud-filter.component.html',
    styleUrls: ['./mg-crud-filter.component.scss']
})
export class MgCrudFilterComponent implements OnInit, OnChanges {

    @Input('filterConfig') filterConfig: FilterConfig = null;

    @Output('init') init: EventEmitter<any> = new EventEmitter<any>();
    @Output('change') change: EventEmitter<any> = new EventEmitter<any>();

    date: NgbDateStruct = null;

    toggleButtonsModel: any = {};

    constructor() {
    }

    ngOnInit(): void {

    }

    ngOnChanges(): void {
        this.init.emit();
    }

    dateChanged(): void {
        let value = null;

        if (this.date && typeof this.date === 'object') {
            value = this.date.year + '-' + this.date.month + '-' + this.date.day;
        }

        this.filterChanged(value);
    }

    toggleButtonsChanged(toggledButtonKey: number | string): void {
        Object.keys(this.toggleButtonsModel).forEach((buttonKey) => {
            if (buttonKey != toggledButtonKey) {
                this.toggleButtonsModel[buttonKey] = false;
            }
        });
        this.filterChanged((this.toggleButtonsModel[toggledButtonKey]) ? toggledButtonKey : null);
    }

    filterChanged(value: any): void {
        this.change.emit({
            filterName: this.filterConfig.name,
            value: value
        });
    }
}
