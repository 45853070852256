import {CrudService} from '../crud.service';
import {Injectable} from '@angular/core';
import {APP_CONFIG} from '../../app.config';
import {User} from '../../models/user';

@Injectable()
export class UserService extends CrudService {
    protected apiUrl = '/api/users/';

    consultantsOfProject(projectId: number) {
        return this.http.get<{ count: number; data: User[] }>
        (APP_CONFIG.backendHost + '/api/projects/' + projectId + '/consultantsOfProject');
    }

    projectConsultantsWithActivity(projectId: number) {
        return this.http.get<{ withContacts: { count: number; data: User[] }; withShifts: { count: number; data: User[] } }>
        (APP_CONFIG.backendHost + '/api/projects/' + projectId + '/consultants-with-activity');
    }

    getCurrentUser() {
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + 'current');
    }

    projects(userId: number) {
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + userId + '/projects');
    }

    availableProjects(userId: number) {
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + userId + '/available-projects');
    }

    addProjects(userId: number, projects: number[]) {
        return this.http.post(APP_CONFIG.backendHost + this.apiUrl + userId + '/projects', {
            'projects': projects
        });
    }

    cities(userId: number) {
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + userId + '/cities');
    }

    addCities(userId: number, cities: number[]) {
        return this.http.post(APP_CONFIG.backendHost + this.apiUrl + userId + '/cities', {
            'cities': cities
        });
    }

    outletAreas() {
        return this.http.get(APP_CONFIG.backendHost + '/api/outlets-areas');
    }

    generatePassword() {
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + 'generate-password');
    }

    getTeamsOfTeamLead(projectId: number, userId: number) {
        return this.http.get(APP_CONFIG.backendHost + '/api/projects/' + projectId + '/users/' + userId + '/teamsOfTeamLeader');
    }

    getConsultantTeams(projectId: number, userId: number) {
        return this.http.get(APP_CONFIG.backendHost + '/api/projects/' + projectId + '/users/' + userId + '/teams');
    }
}
