import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { AuthService } from './services/auth.service';
import { VARS } from '../environments/vars';

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>'
})
export class AppComponent {
    constructor(private router: Router, private auth: AuthService, private titleService: Title) {
        this.setTitle(VARS.title || '1-2-1 DataBase');
    }

    public setTitle(title: string): void {
        this.titleService.setTitle(title);
    }
}
