import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { tap } from 'rxjs/operators';
import { Cookie } from 'ng2-cookies/ng2-cookies';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

    constructor(private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const modifiedReq = req.clone({
            withCredentials: true
        });

        return next.handle(modifiedReq)
            .pipe(
                tap(res => {
                }, (error) => {
                    if (error.status === 401 || !this.isValidToken(error)) {
                        this.router.navigate(['/auth/signin']);
                    }
                }),
            );
    }

    private isValidToken(res): boolean {
        let error = res.error;
        if (error === 'token_not_provided'
            || error === 'token_expired'
            || error === 'token_invalid'
            || error === 'user_not_found') {
            return false;
        }

        return true;
    }
}
