import {VARS} from '../environments/vars';

export const APP_CONFIG = getConfig();

function getConfig() {
    if (VARS.api) {
        return {
            backendHost: VARS.api,
        }
    } else {
        if ((document.location.href.indexOf('localhost') !== -1) || (document.location.href.indexOf('127.0.0.1') !== -1)) {
            return {
                backendHost: 'http://localhost:8200',
            }
        } else {
            return {
                backendHost: 'https://' + document.location.hostname + (document.location.port ? ':' + document.location.port : ''),
            }
        }
    }
}
