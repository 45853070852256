import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AppService} from '../../../services/app.service';
import {User} from '../../../models/user';
import {ConsultantService} from '../../../services/entity-services/consultant.service';

@Component({
  selector: 'app-typeahead-consultant',
  templateUrl: './typeahead-consultant.component.html',
  styleUrls: ['./typeahead-consultant.component.scss'],
  providers: [
    ConsultantService
  ]
})
export class TypeaheadConsultantComponent implements OnInit {

  @Output('selectItem') change: EventEmitter<any> = new EventEmitter<any>();

  consultants: User[] = [];

  constructor(public consultantService: ConsultantService, private appService: AppService) { }

  ngOnInit(): void {
   /* console.log('ngOnInit');
    this.init();*/
  }

  /*protected init(): void {
    this.getData();
  }

  protected getData(): void {
    this.consultantService.consultantsOfProject(this.appService.getCurProject().id).subscribe((data: User[]) => {
      this.consultants = data;
    });
    console.log('this.consultants', this.consultants);
  }*/

}
