import { Injectable } from '@angular/core';
import { APP_CONFIG } from '../app.config';

import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Cookie } from 'ng2-cookies';

import {
    publishLast,
    refCount,
    map,
} from 'rxjs/operators';

@Injectable()
export class AuthService {

    private token: string;
    private secure: boolean;
    public authorized: BehaviorSubject<boolean>;

    constructor(private http: HttpClient) {
        this.secure = !((document.location.href.indexOf('localhost') !== -1) || (document.location.href.indexOf('127.0.0.1') !== -1));
        this.authorized = new BehaviorSubject(false);
    }

    setToken(token: string): void {
        this.token = token;
    }

    getToken(): string | null {
        return Cookie.get('token') || null;
    }

    setUserRole(userRole: string): void {
        Cookie.set('userRole', userRole, 90, null, null, this.secure);
    }

    getUserRole(): string | null {
        return Cookie.get('userRole') || null;
    }

    signin(data): Observable<any> {
        return this.http.post(APP_CONFIG.backendHost + '/api/signin/admin', data)
            .pipe(
                publishLast(),
                refCount()
            );
    }

    signup(data): Observable<any> {
        return this.http.post(APP_CONFIG.backendHost + '/api/signup', data)
            .pipe(
                publishLast(),
                refCount()
            );
    }

    signout(): Observable<any> {
        return this.http.get(APP_CONFIG.backendHost + '/api/signout')
            .pipe(
                map((res) => {
                    return res;
                }),
                publishLast(),
                refCount()
            );
    }

    checkToken(): Observable<any> {
        return this.http.get(APP_CONFIG.backendHost + '/api/check-token')
            .pipe(
                publishLast(),
                refCount()
            );
    }
}
