import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, UrlSerializer} from '@angular/router';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {NgbModule, NgbPaginationModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {SidebarModule} from 'ng-sidebar';
import {NgxMaskModule} from 'ngx-mask';
import {NgSelectModule} from '@ng-select/ng-select';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {FileUploadModule} from 'ng2-file-upload';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';

import {AppRoutes} from './app.routing';
import {AppComponent} from './app.component';

import {AdminLayoutComponent} from './components/layouts/admin/admin-layout.component';
import {AuthLayoutComponent} from './components/layouts/auth/auth-layout.component';
import {WorkSchedulesComponent} from './components/work-schedules/work-schedules.component';
import {ViolationsComponent} from './components/violations/violations.component';
import {WorkBreaksComponent} from './components/work-breaks/work-breaks.component';
import {UserComponent} from './components/user/user.component';
import {TeamsComponent} from './components/teams/teams.component';
import {TestDrivesComponent} from './components/test-drives/test-drives.component';

import {SharedModule} from './shared/shared.module';
import {DialogComponent} from './shared/dialog/dialog.component';
import {DialogService} from './shared/dialog/dialog.service';
import {MgCrudModule} from './shared/mg-crud/mg-crud.module';

import {AuthService} from './services/auth.service';
import {ProjectService} from './services/entity-services/project.service';
import {AppService} from './services/app.service';
import {FormService} from './services/form.service';
import {UserService} from './services/entity-services/user.service';
import {AuthHttpInterceptor} from './services/auth-http-interceptor.service';

import {ProjectGuard} from './guards/project.guard';
import {AuthGuard} from './guards/auth.guard';
import {RoleGuard} from './guards/role.guard';

import {DirectivesModule} from './directives/directives.module';
import {ToastrModule} from 'ngx-toastr';

@NgModule({
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        DialogComponent,
        WorkSchedulesComponent,
        ViolationsComponent,
        WorkBreaksComponent,
        UserComponent,
        TeamsComponent,
        TestDrivesComponent,
    ],
    imports: [
        BrowserModule,
        NgxMaskModule.forRoot(),
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        SharedModule,
        RouterModule.forRoot(AppRoutes),
        NgxDatatableModule,
        NgbPaginationModule,
        NgbTypeaheadModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        FormsModule,
        HttpClientModule,
        NgbModule.forRoot(),
        SidebarModule.forRoot(),
        ReactiveFormsModule,
        FileUploadModule,
        MgCrudModule,
        NgSelectModule,
        ScrollToModule.forRoot(),
        DirectivesModule,
    ],

    providers: [
        DialogService,
        ProjectService,
        UserService,
        AppService,
        ProjectGuard,
        AuthService,
        AuthGuard,
        RoleGuard,
        FormService,
        Title,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true,
        },
        /*{
            provide: UrlSerializer,
            useClass: CustomUrlSerializer
        }*/
    ],

    bootstrap: [AppComponent],
    entryComponents: [DialogComponent]
})
export class AppModule {
}
