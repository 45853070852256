import {AfterViewInit, Directive, ElementRef, Input, OnDestroy} from '@angular/core';
import {fromEvent, Observable} from 'rxjs';

import {
    map,
    filter,
    exhaustMap,
    pairwise,
    startWith,
} from 'rxjs/operators';

interface ScrollPosition {
    sH: number;
    sT: number;
    cH: number;
}

const DEFAULT_SCROLL_POSITION: ScrollPosition = {
    sH: 0,
    sT: 0,
    cH: 0
};

@Directive({
    selector: '[mgInfiniteScroll]',
})
export class MgInfiniteScrollDirective implements AfterViewInit, OnDestroy {

    private scrollEvent$;

    private userScrolledDown$;

    private requestOnScroll$;

    private requestOnScrollSubscription;

    @Input()
    scrollCallback;

    @Input()
    immediateCallback = false;

    @Input()
    scrollPercent = 70;

    @Input()
    scrollElement = null;

    @Input()
    initSubject: Observable<any> = null;

    constructor(private elm: ElementRef) {
    }

    ngAfterViewInit(): void {
        if (this.initSubject) {
            this.initSubject.subscribe(() => {
                this.initialization();
            });
        } else {
            this.initialization();
        }
    }

    ngOnDestroy(): void {
        this.requestOnScrollSubscription && this.requestOnScrollSubscription.unsubscribe();
    }

    private registerScrollEvent(): void {
        this.scrollEvent$ = fromEvent(this.scrollElement || this.elm.nativeElement, 'scroll');
    }

    private streamScrollEvents(): void {
        this.userScrolledDown$ = this.scrollEvent$
            .pipe(
                map((e: any): ScrollPosition => ({
                    sH: e.target.scrollHeight,
                    sT: e.target.scrollTop,
                    cH: e.target.clientHeight
                })),
                pairwise(),
                filter((positions) => this.isUserScrollingDown(positions) && this.isScrollExpectedPercent(positions[1])),
            );
    }

    private requestCallbackOnScroll(): void {
        this.requestOnScroll$ = this.userScrolledDown$;

        if (this.immediateCallback) {
            this.requestOnScroll$ = this.requestOnScroll$
                .pipe(
                    startWith([DEFAULT_SCROLL_POSITION, DEFAULT_SCROLL_POSITION])
                );
        }

        this.requestOnScrollSubscription = this.requestOnScroll$
            .pipe(
                exhaustMap(() => {
                    return this.scrollCallback();
                })
            )
            .subscribe(() => {
            });
    }

    private initialization(): void {
        this.requestOnScrollSubscription && this.requestOnScrollSubscription.unsubscribe();
        this.registerScrollEvent();
        this.streamScrollEvents();
        this.requestCallbackOnScroll();
    }

    private isUserScrollingDown = (positions) => {
        return positions[0].sT < positions[1].sT;
    }

    private isScrollExpectedPercent = (position) => {
        return ((position.sT + position.cH) / position.sH) > (this.scrollPercent / 100);
    }

}
