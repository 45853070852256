import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {WorkBreakService} from '../../services/entity-services/work-break.service';
import {AppService} from '../../services/app.service';
import {Subscription, forkJoin} from 'rxjs';
import {MgCrudComponent} from '../../shared/mg-crud/mg-crud/mg-crud.component';
import {Field} from '../../shared/mg-crud/mg-crud.module';
import {ProjectService} from '../../services/entity-services/project.service';
import {PromouterService} from '../../services/entity-services/promouter.service';

import {map} from 'rxjs/operators';

@Component({
    selector: 'app-work-breaks',
    templateUrl: './work-breaks.component.html',
    styleUrls: ['./work-breaks.component.scss'],
    providers: [
        WorkBreakService,
        ProjectService,
        PromouterService,
    ]
})
export class WorkBreaksComponent implements OnInit, OnDestroy {

    @ViewChild(MgCrudComponent) mgCrudComponent: MgCrudComponent;

    fields: Field[] = [];
    columns: any = [];

    projectSubscription: Subscription;

    promouters: any = [];
    cities: any = [];

    constructor(private appService: AppService,
                public workBreakService: WorkBreakService,
                private projectService: ProjectService,
                private promouterService: PromouterService) {

    }

    ngOnInit(): void {
        this.initData();
        this.projectSubscription = this.appService.getCurProjectObservable().subscribe((project) => {
            if (typeof project !== 'undefined') {
                this.initData();
                this.mgCrudComponent.ngOnInit();
            }
        });
    }

    ngOnDestroy(): void {
        this.projectSubscription.unsubscribe();
    }

    protected initData(): void {
        this.initColumns();

        forkJoin(
            this.promouterService
                .all()
                .pipe(
                    map((promouters) => {
                        let options = [{
                            key: null,
                            value: '-- Все --'
                        }];

                        return options.concat(promouters.map((promouter) => {
                            return {
                                key: promouter.id,
                                value: promouter.fio
                            }
                        }));
                    })
                ),
            this.projectService
                .cities(this.appService.getCurProject().id)
                .pipe(
                    map((cities) => {
                        let options = [{
                            key: null,
                            value: '-- Все --'
                        }];

                        return options.concat(cities.map((city) => {
                            return {
                                key: city.id,
                                value: city.name
                            }
                        }));
                    })
                )
        ).subscribe((data) => {
            [this.promouters, this.cities] = data;
        });
    }

    protected initColumns(): void {
        this.columns = [
            {
                name: 'Дата',
                prop: 'work_schedule.date',
                sortable: false
            },
            {
                name: 'Начало',
                prop: 'begin_at'
            },
            {
                name: 'Конец',
                prop: 'end_at'
            },
            {
                name: 'Продолжительность',
                prop: 'duration',
                sortable: false
            },
            {
                name: 'Промоутер',
                prop: 'work_schedule.promouter.fio',
                sortable: false
            },
            {
                name: 'Город',
                prop: 'work_schedule.outlet.city.name',
                sortable: false
            }
        ];
    }
}
