import {CrudService} from '../crud.service';
import {Injectable} from '@angular/core';
import {AppService} from "../app.service";
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ProjectCityService extends CrudService {

    constructor(http: HttpClient,private appService: AppService) {
        super(http);
        this.apiUrl = '/api/projects/' + this.appService.getCurProject().id + '/citiesOfProject/';
        this.appService.getCurProjectObservable().subscribe((project) => {
            if (typeof project !== 'undefined') {
                this.apiUrl = '/api/projects/' + project.id + '/citiesOfProject/';
            }
        });
    }


}
