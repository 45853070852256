import { Injectable } from '@angular/core';
import { Project } from '../models/project';
import { Observable, Subject, AsyncSubject } from 'rxjs';
import { User } from '../models/user';
import { UserService } from './entity-services/user.service';
import { AuthService } from './auth.service';

@Injectable()
export class AppService {

    protected projects: Project[] = [];
    protected currentProject: Project = null;
    protected currentUser: User = null;

    protected currentProjectSubject: Subject<Project>;
    public projectsSubject: AsyncSubject<Project[]>;

    protected _googleMapsApiIsLoaded;

    get googleMapsApiIsLoaded() {
        return this._googleMapsApiIsLoaded;
    };
    set googleMapsApiIsLoaded(value: boolean) {
        this._googleMapsApiIsLoaded = value;
    };

    constructor(private userService: UserService, private authService: AuthService) {
        this.currentProjectSubject = new Subject();
        this.projectsSubject = new AsyncSubject();

        this.authService.authorized.subscribe(() => {
            this.userService.getCurrentUser().subscribe((user: User) => {
                this.currentUser = user;

                this.userService.projects(user.id).subscribe((projects: Project[]) => {
                    this.projects = projects;

                    this.projectsSubject.next(this.projects);

                    if (this.projects.length) {
                        this.currentProject = this.projects[0];
                        this.currentProjectSubject.next(this.currentProject);
                        this.projectsSubject.complete();
                    }
                });
            });
        });
    }

    isProjectSelected(): boolean {
        return !!this.currentProject;
    }

    getCurrentUser(): User {
        return this.currentUser;
    }

    getProjects(): Project[] {
        return this.projects;
    }

    pushProject(project: Project): void {
        this.projects.push(project);
    }

    getCurProject(): Project {
        return this.currentProject;
    }

    getCurProjectObservable(): Observable<Project> {
        return this.currentProjectSubject;
    }

    getProjectsObservable(): Observable<Project[]> {
        return this.projectsSubject;
    }

    setCurProject(projectId: number): void {
        this.currentProject = this.projects.find((item) => item.id == projectId);
        this.currentProjectSubject.next(this.currentProject);
    }
}
