import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PromouterService} from '../../../services/entity-services/promouter.service';

@Component({
  selector: 'app-typeahead-promouter',
  templateUrl: './typeahead-promouter.component.html',
  styleUrls: ['./typeahead-promouter.component.scss'],
  providers: [
      PromouterService,
  ]
})
export class TypeaheadPromouterComponent implements OnInit {

  @Output('selectItem') change: EventEmitter<any> = new EventEmitter<any>();

  constructor(public promouterService: PromouterService) { }

  ngOnInit(): void {
  }

}
