import {Injectable} from '@angular/core';
import {CanActivate, Router, CanActivateChild} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {Observable, of} from 'rxjs';

import {
    map,
    catchError,
} from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(): Observable<boolean> | boolean {
        return this.authService.checkToken()
            .pipe(
                map(() => {
                    return true;
                }),
                catchError(() => {
                    this.router.navigate(['/auth/signin']);
                    return of(false);
                }),
            );
    }

    canActivateChild(): Observable<boolean> | boolean {
        return this.authService.checkToken()
            .pipe(
                map(() => {
                    return true;
                }),
                catchError(() => {
                    this.router.navigate(['/auth/signin']);
                    return of(false);
                }),
            );
    }
}
