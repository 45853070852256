import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'numberToExcelColumn'
})
export class NumberToExcelColumnPipe implements PipeTransform {

    transform(number: any, args?: any): any {
        let temp, letter = '';

        while (number > 0)
        {
            temp = (number - 1) % 26;
            letter = String.fromCharCode(temp + 65) + letter;
            number = (number - temp - 1) / 26;
        }

        return letter;
    }

}
