import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SupervisorService} from '../../../services/entity-services/supervisor.service';

@Component({
  selector: 'app-typeahead-supervisor',
  templateUrl: './typeahead-supervisor.component.html',
  styleUrls: ['./typeahead-supervisor.component.scss'],
  providers: [
      SupervisorService,
  ]
})
export class TypeaheadSupervisorComponent implements OnInit {

  @Output('selectItem') change: EventEmitter<any> = new EventEmitter<any>();

  constructor(public supervisorService: SupervisorService) { }

  ngOnInit(): void {
  }

}
