import {User} from './user';

export class Team {
    id: number;
    name: string;
    consultants: User[] = [];
    project_id: number;
    leader: User = null;
}

