import {Injectable} from '@angular/core';

export interface Refresh {
    refresh(): void;
}

@Injectable()
export class RefreshService {
    protected timer: any;

    setTimer(component: any, interval: number = 900000): void {
        this.destroyTimer();
        this.timer = setInterval(() => {
            component.refresh();
        }, interval);
    }

    destroyTimer(): void {
        clearTimeout(this.timer);
    }
}