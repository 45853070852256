import { Field } from './init-step';

export class StepChoice {
    id?: number;
    title?: string = '';
    x: number;
    y: number;
    cols: number = 12;
    rows: number = 1;
    is_black: boolean = true;
    color?: string = '#ffffff';
    step_id?: number;
    type: number = 0;
    next_step: number;
    next_step_id?: number;
    button_position: string = 'center';
    brand_id?: number;
    show_modal?: boolean;
    modal_text?: string;

    constructor(params = {
        color: '#ffffff',
        is_black: true,
    }) {
        const { color, is_black } = params;

        this.color = color;
        this.is_black = is_black;
    }
}

export class Step {
    id?: number;
    index?: number;
    stepId: number = 0;
    name?: string;
    description?: string;
    choices: StepChoice[] = [];
    big_btn: boolean;
    fields: Field[];
    attachment?: { path: string, type: string };
    nodeColor?: string = '#4249ff';
    is_first: number = 0;
    rows?: number = 1;
    cols?: number = 12;
    content_position: string = 'center';
    mechanic_id?: number;
    report_order?: number = 0;
}
