import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: '[timezone]'
})

export class TimezoneDirective {

    private check(value: string) {
        return String(value).match(new RegExp(/^[\-+0-9]+$/));
    }

    constructor(private el: ElementRef) {
    }

    @HostListener('keypress', [ '$event' ])
    onKeyPress(event: KeyboardEvent) {
        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(event.key);

        if ( next && !this.check(next) ) {
            event.preventDefault();
        }
    }
}
