import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ProjectCityService} from '../../../services/entity-services/project-city.service';

@Component({
    selector: 'app-typeahead-city',
    templateUrl: './typeahead-city.component.html',
    styleUrls: ['./typeahead-city.component.scss'],
    providers: [
        ProjectCityService,
    ]
})
export class TypeaheadCityComponent implements OnInit {

    @Output('selectItem') change: EventEmitter<any> = new EventEmitter<any>();

    constructor(public projectCityService: ProjectCityService) { }

    ngOnInit(): void {
    }
}
