import {throwError as observableThrowError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {CrudService} from '../crud.service';
import {APP_CONFIG} from '../../app.config';
import {HttpErrorResponse} from '@angular/common/http';
import {publishLast, refCount, catchError} from 'rxjs/operators';

@Injectable()
export class OutletService extends CrudService {
    protected apiUrl = '/api/outlets/';

    importFromFile(file): Observable<any> {
        return this.http.post(APP_CONFIG.backendHost + this.apiUrl + 'import/file', file)
            .pipe(
                publishLast(),
                refCount(),
                catchError((error: HttpErrorResponse) => {
                    return observableThrowError(
                        error.error
                    );
                }),
            );
    }

    importFromFtp(data): Observable<any> {
        return this.http.post(APP_CONFIG.backendHost + this.apiUrl + 'import/ftp', data)
            .pipe(
                publishLast(),
                refCount(),
                catchError((error: HttpErrorResponse) => {
                    return observableThrowError(
                        error.error
                    );
                }),
            );
    }

    search(query: string = null) {
        let queryString = '';
        if (query) {
            queryString = '?query=' + query;
        }
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + '/search' + queryString);
    }
}
