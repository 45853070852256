import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormGroupDirective} from '@angular/forms';
import {ThemeTemplateProperties} from '../../../../../models/design/theme-template-property';

@Component({
    selector: 'theme-template-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit, OnDestroy {

    @Input() propertiesFormGroup: FormGroup;
    @Input() properties: ThemeTemplateProperties;

    constructor(private parentForm: FormGroupDirective, private fb: FormBuilder) {
    }

    ngOnInit(): void {
        if (!this.properties) {
            this.properties = new ThemeTemplateProperties();
        }

        this.propertiesFormGroup = this.fb.group({
            text_color_default: [this.properties.text_color_default ],
            text_color_active: [this.properties.text_color_active],

            background_color_default: [this.properties.background_color_default],
            background_color_active: [this.properties.background_color_active],

            background_gradient_colors_default: [this.properties.background_gradient_colors_default],
            background_gradient_colors_active: [this.properties.background_gradient_colors_active],
            background_gradient_locations_default: [this.properties.background_gradient_locations_default],
            background_gradient_locations_active: [this.properties.background_gradient_locations_active],

            border_color_default: [this.properties.border_color_default],
            border_color_active: [this.properties.border_color_active],

            border_gradient_colors_default: [this.properties.border_gradient_colors_default],
            border_gradient_colors_active: [this.properties.border_gradient_colors_active],
            border_gradient_locations_default: [this.properties.border_gradient_locations_default],
            border_gradient_locations_active: [this.properties.border_gradient_locations_active],

            top_left_border_radius_default: [this.properties.top_left_border_radius_default],
            top_right_border_radius_default: [this.properties.top_right_border_radius_default],
            bottom_left_border_radius_default: [this.properties.bottom_left_border_radius_default],
            bottom_right_border_radius_default: [this.properties.bottom_right_border_radius_default],

            top_left_border_radius_active: [this.properties.top_left_border_radius_active],
            top_right_border_radius_active: [this.properties.top_right_border_radius_active],
            bottom_left_border_radius_active: [this.properties.bottom_left_border_radius_active],
            bottom_right_border_radius_active: [this.properties.bottom_right_border_radius_active],

            font_weight: [this.properties.font_weight],
        })

        this.parentForm.form.addControl('properties', this.propertiesFormGroup);
    }

    ngOnDestroy(): void {
        this.parentForm.form.removeControl('properties');
    }
}
