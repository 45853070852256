import { Injectable } from '@angular/core';
import { CrudService } from '../crud.service';
import {AppService} from "../app.service";
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG} from '../../app.config';

@Injectable()
export class TestDriveService extends CrudService {
    constructor(http: HttpClient, private appService: AppService) {
        super(http);

        this.apiUrl = '/api/projects/' + appService.getCurProject().id + '/test-drives/';

        this.appService.getCurProjectObservable().subscribe((project) => {
            if (typeof project !== 'undefined') {
                this.apiUrl = '/api/projects/' + project.id + '/test-drives/';
            }
        });
    }

    search(query: string = null) {
        let queryString = '';
        if (query) {
            queryString = '?query=' + query;
        }
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + '/search' + queryString);
    }
}


