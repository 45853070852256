import {Component, Input} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SegmentBlockRule} from '../../../../models/segment-block';
import {ContactStructureComponent} from '../../../../components/settings/project-settings/contact-structure/contact-structure.component';
import {Step} from '../../../../models/step';

@Component({
    selector: 'field-rules',
    templateUrl: './rules.component.html',
    styleUrls: ['./rules.component.scss']
})
export class FieldRulesComponent {

    @Input() field: FormGroup;
    @Input() step: Step;
    @Input() steps: Step[];

    @Input() rules: Array<string>;
    @Input() rulesReadable: Object;
    @Input() label: string;
    @Input() nextStepLabel: string;

    constructor(private fb: FormBuilder) {
    }

    pushBlockRule = (rule: SegmentBlockRule, array: FormArray) => {
        array.push(this.fb.group({
            name: [rule.name, Validators.compose([Validators.required])],
            next_step_id: [rule.next_step_id, Validators.compose([Validators.required])],
        }));
    };

    addBlockRule = (): void => {
        this.pushBlockRule(
            new SegmentBlockRule({name: null, next_step_id: null}),
            <FormArray>this.field.get('payload'))
    };

    removeBlockRule = (j): void => {
        const control = <FormArray>this.field.get('payload');
        control.removeAt(j);
    };

    blockRuleUp(j): void {
        if (j) {
            const control = <FormArray>(<FormArray>this.field.get('payload'));
            ContactStructureComponent.arrayControlUp(j, control);
        }
    }

    blockRuleDown(j): void {
        const control = <FormArray>(<FormArray>this.field.get('payload'));
        ContactStructureComponent.arrayControlDown(j, control);
    }
}
