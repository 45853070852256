import {Injectable} from '@angular/core';
import {APP_CONFIG} from '../../app.config';

export interface BadgeItem {
    type: string;
    value: string;
}

export interface ChildrenItems {
    parentState?: string;
    state: string;
    name: string;
    type?: string;
}

export interface Menu {
    parentState?: string;
    state: string;
    name: string;
    type: string;
    color?: string;
    icon: string;
    badge?: BadgeItem[];
    children?: ChildrenItems[];
}

const MENUITEMS = [
    {
        state: '',
        name: 'График контактов',
        type: 'main',
        color: '#eeec2f',
        icon: 'bar-chart',
        roles: ['developer', 'admin', 'manager', 'team_leader'],
    },
    {
        state: 'reports',
        name: 'Отчёты',
        type: 'sub',
        icon: 'bar-chart',
        children: [
            {
                state: 'ankets',
                name: 'Анкеты',
                type: 'link',
                color: '#eeec2f',
                icon: 'list',
                roles: ['developer', 'admin', 'manager', 'team_leader'],
            },
            {
                state: 'address-program',
                name: 'Адресные программы',
                type: 'link',
                color: '#eeec2f',
                icon: 'list',
                roles: ['developer', 'admin', 'manager', 'team_leader'],
            },
            {
                state: 'consultant-shifts',
                name: 'Отчёт по сменам',
                type: 'link',
                color: '#eeec2f',
                icon: 'list',
                roles: ['developer', 'admin', 'manager', 'team_leader'],
            },
            {
                state: 'brands-report',
                name: 'Отчёт по брендам',
                type: 'link',
                color: '#eeec2f',
                icon: 'list',
                roles: ['developer', 'admin', 'manager', 'team_leader'],
            },
            {
                state: 'sales-report',
                name: 'Отчёт по покупкам',
                type: 'link',
                color: '#eeec2f',
                icon: 'list',
                roles: ['developer', 'admin', 'manager', 'team_leader'],
            },
            {
                state: 'test-drive-report',
                name: 'Отчёт по арендам',
                type: 'link',
                color: '#eeec2f',
                icon: 'list',
                roles: ['developer', 'admin', 'manager', 'team_leader'],
            },
            {
                state: 'cities-report',
                name: 'Отчёт по городам',
                type: 'link',
                color: '#eeec2f',
                icon: 'list',
                roles: ['developer', 'admin', 'manager', 'team_leader'],
            },
            {
                state: 'key-indicators',
                name: 'Отчёт по ключевым показателям',
                type: 'link',
                color: '#eeec2f',
                icon: 'list',
                roles: ['developer', 'admin', 'manager', 'team_leader'],
            },
            {
                state: 'geo-report',
                name: 'Отчёт по проверке геолокации',
                type: 'link',
                color: '#eeec2f',
                icon: 'list',
                roles: ['developer', 'admin', 'manager', 'team_leader'],
            },
        ],
    },
    {
        state: 'multi-reports',
        name: 'Мульти-Отчёты',
        type: 'sub',
        icon: 'bar-chart',
        children: [
            {
                state: 'shifts',
                name: 'Смены',
                type: 'link',
                color: '#eeec2f',
                icon: 'list',
                roles: ['developer', 'admin', 'manager', 'team_leader'],
            },
            {
                state: 'test-drives',
                name: 'Аренды',
                type: 'link',
                color: '#eeec2f',
                icon: 'list',
                roles: ['developer', 'admin', 'manager', 'team_leader'],
            },
            {
                state: 'ankets',
                name: 'Анкеты',
                type: 'link',
                color: '#eeec2f',
                icon: 'list',
                roles: ['developer', 'admin', 'manager', 'team_leader'],
            },
            {
                state: 'defective-device-refunds',
                name: 'Обмен неисправных устройств',
                type: 'link',
                color: '#eeec2f',
                icon: 'list',
                roles: ['developer', 'admin', 'manager', 'team_leader'],
            },
        ],
    },
    {
        state: 'users',
        name: 'Пользователи',
        type: 'link',
        icon: 'users',
        roles: ['developer', 'admin', 'manager', 'team_leader'],
    },
    {
        state: 'teams',
        name: 'Команды',
        type: 'link',
        icon: 'users',
        roles: ['developer', 'admin', 'manager'],
    },
    {
        state: 'address-programs',
        name: 'Адресные программы (АП)',
        type: 'link',
        icon: 'calendar',
        roles: ['developer', 'admin', 'manager', 'team_leader'],
    },
    {
        parentState: 'handbooks',
        state: 'common',
        name: 'Справочники',
        type: 'sub',
        icon: 'book',
        roles: ['developer', 'admin', 'manager'],
        children: [
            {
                state: 'clients',
                name: 'Проекты',
                roles: ['developer'],
            },
            {
                state: 'brands',
                name: 'Бренды',
                roles: ['developer'],
            },
            {
                state: 'mechanics',
                name: 'Механики',
                roles: ['developer', 'admin', 'team_leader', 'manager'],
            },
            {
                state: 'areas',
                name: 'Регионы РТТ',
                roles: ['developer'],
            },
            {
                state: 'cities',
                name: 'Города РТТ',
                roles: ['developer'],
            },
            {
                state: 'outlets',
                name: 'Торговые точки (РТТ)',
                roles: ['developer', 'admin', 'team_leader', 'manager'],
            },
            {
                state: 'fias-cities',
                name: 'Города (ФИАС)',
                roles: ['developer'],
            },
            {
                state: 'cigarette-marks',
                name: 'Список сигаретных марок',
                roles: ['developer', 'admin'],
            },
            {
                state: 'cigarette-mark-families',
                name: 'Семейства сигаретных марок',
                roles: ['developer', 'admin'],
            },
        ],
    },
    {
        parentState: 'settings',
        state: 'project',
        name: 'Настройки программы',
        type: 'sub',
        icon: 'cogs',
        children: [
            {
                state: 'project',
                name: 'Программа',
                roles: ['developer', 'admin'],
            },
            {
                state: 'gifts-and-products',
                name: 'Список покупок/подарков',
                roles: ['developer', 'admin'],
            },
            {
                state: 'contact-structure',
                name: 'Структура контакта',
                roles: ['developer', 'admin'],
            },
            {
                state: 'media',
                name: 'Медиафайлы',
                roles: ['developer', 'admin'],
            },
            {
                state: 'news',
                name: 'Новости',
                icon: 'newspaper-o',
                roles: ['developer', 'admin'],
            },
        ],
        roles: ['developer', 'admin'],
    },
    {
        parentState: 'settings',
        state: 'common',
        name: 'Настройки приложения',
        type: 'sub',
        icon: 'cog',
        children: [
            {
                state: 'view',
                name: 'Оформление'
            },
            {
                state: 'variables',
                name: 'Переменные',
                roles: ['developer'],
            },
            {
                state: 'themes',
                name: 'Темы',
                roles: ['developer'],
            },
            {
                state: 'theme-templates',
                name: 'Шаблоны тем',
                roles: ['developer'],
            },
        ],
        roles: ['developer', 'admin'],
    },
    {
        state: 'sales',
        name: 'Покупки (Чеки) в контактах',
        type: 'sub',
        icon: 'list-alt',
        children: [
            {
                state: 'checks',
                name: 'Чеки',
                roles: ['developer', 'admin', 'manager', 'team_leader'],
            },
            {
                state: 'nomenclature',
                name: 'Справочник номенклатур',
                roles: ['developer', 'admin', 'manager', 'team_leader'],
            },
            {
                state: 'nomenclature-categories',
                name: 'Категории номенклатур',
                roles: ['developer', 'admin', 'manager', 'team_leader'],
            },
        ],
    },
    {
        state: 'stock',
        name: 'Склад',
        type: 'sub',
        icon: 'check-square-o',
        children: [
            {
                state: 'products',
                name: 'Справочник материалов',
                roles: ['developer', 'admin'],
            },
            {
                state: 'categories',
                name: 'Категории материалов',
                roles: ['developer', 'admin'],
            },
            {
                state: 'materials',
                name: 'Склад материалов',
                roles: ['developer', 'admin', 'manager', 'team_leader'],
            },
            {
                state: 'transactions',
                name: 'Движения материалов',
                roles: ['developer', 'admin', 'manager', 'team_leader'],
            },
        ],
    },
    {
        state: 'test-drives',
        name: 'Аренды',
        type: 'link',
        icon: 'book',
        roles: ['developer', 'admin'],
    },
    {
        state: `${APP_CONFIG.backendHost}/api/logs`,
        name: 'Логи',
        type: 'externalLink',
        icon: 'book',
        roles: ['developer'],
    },
    {
        state: 'monitoring',
        name: 'Мониторинг',
        type: 'sub',
        icon: 'list-alt',
        roles: ['developer', 'admin', 'manager', 'team_leader'],
        children: [
            {
                state: 'map',
                name: 'Карта',
                roles: ['developer', 'admin', 'manager', 'team_leader'],
            },
        ],
    },
];

@Injectable()
export class MenuItems {
    getAll(): Menu[] {
        return MENUITEMS;
    }
}
