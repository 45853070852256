import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {WorkScheduleService} from '../../services/entity-services/work-schedule.service';
import {FormControl, Validators} from '@angular/forms';
import {CityService} from '../../services/entity-services/city.service';
import {OutletService} from '../../services/entity-services/outlet.service';
import {PromouterService} from '../../services/entity-services/promouter.service';
import {SupervisorService} from '../../services/entity-services/supervisor.service';
import {AppService} from '../../services/app.service';
import {Subscription, forkJoin} from 'rxjs';
import {Field} from '../../shared/mg-crud/mg-crud.module';
import {MgCrudComponent} from '../../shared/mg-crud/mg-crud/mg-crud.component';

import {
    map,
} from 'rxjs/operators';

@Component({
    selector: 'app-work-schedules',
    templateUrl: './work-schedules.component.html',
    styleUrls: ['./work-schedules.component.scss'],
    providers: [
        WorkScheduleService,
        CityService,

        OutletService,
        PromouterService,
        SupervisorService]
})
export class WorkSchedulesComponent implements OnInit, OnDestroy {

    @ViewChild(MgCrudComponent) mgCrudComponent: MgCrudComponent;

    fields: Field[] = [];
    columns: any = [];

    outlets: any = [];
    promouters: any = [];
    supervisors: any = [];

    projectSubscription: Subscription;

    showCreateButton: boolean = false;
    showEditButton: boolean = false;
    showDeleteButton: boolean = false;

    constructor(public workScehduleService: WorkScheduleService,
                private outletService: OutletService,
                private promouterService: PromouterService,
                private supervisorService: SupervisorService,
                private appService: AppService) {
    }

    ngOnInit(): void {
        const userRole = this.appService.getCurrentUser().role;
        this.showCreateButton = this.showEditButton = ['developer', 'admin', 'manager', 'team_leader'].indexOf(userRole) !== -1;
        this.showDeleteButton = ['developer', 'admin', 'manager'].indexOf(userRole) !== -1;

        this.initData();
        this.projectSubscription = this.appService.getCurProjectObservable().subscribe((project) => {
            if (typeof project !== 'undefined') {
                this.initData();
                this.mgCrudComponent.ngOnInit();
            }
        });
    }

    ngOnDestroy(): void {
        this.projectSubscription.unsubscribe();
    }

    protected initData(): void {
        this.initColumns();
        this.initFields();

        forkJoin(
            this.outletService
                .all()
                .pipe(
                    map(outlets => {
                        let options = [{
                            key: null,
                            value: '-- Все --'
                        }];

                        return options.concat(outlets.map((outlet) => {
                            return {
                                key: outlet.id,
                                value: outlet.name
                            }
                        }));
                    }),
                ),
            this.promouterService
                .all()
                .pipe(
                    map(promouters => {
                        let options = [{
                            key: null,
                            value: '-- Все --'
                        }];

                        return options.concat(promouters.map((promouter) => {
                            return {
                                key: promouter.id,
                                value: promouter.fio
                            }
                        }));
                    }),
                ),
            this.supervisorService
                .all()
                .pipe(
                    map(supervisors => {
                        let options = [{
                            key: null,
                            value: '-- Все --'
                        }];

                        return options.concat(supervisors.map((supervisor) => {
                            return {
                                key: supervisor.id,
                                value: supervisor.fio
                            }
                        }));
                    }),
                ),
        ).subscribe((data) => {
            [this.outlets, this.promouters, this.supervisors] = data;
            this.initFields();
        });
    }

    protected initColumns(): void {
        this.columns = [
            {
                name: 'Дата',
                prop: 'date'
            },
            {
                name: 'Время С',
                prop: 'begin_at'
            },
            {
                name: 'Время По',
                prop: 'end_at'
            },
            {
                name: 'Город',
                prop: 'outlet.city.name',
                sortable: false
            },
            {
                name: 'Сеть',
                prop: 'outlet.retail_chain.name',
                sortable: false
            },
            {
                name: 'Адрес',
                prop: 'outlet.address',
                sortable: false
            },
            {
                name: 'Промоутер',
                prop: 'promouter.fio',
                sortable: false
            },
            {
                name: 'Супервайзер',
                prop: 'supervisor.fio',
                sortable: false
            }
        ];
    }

    protected initFields(): void {
        this.fields = [
            {
                name: 'date',
                type: 'date',
                label: 'Дата',
                control: new FormControl('', Validators.required)
            },
            {
                name: 'begin_at',
                type: 'time',
                label: 'Время С',
                control: new FormControl('', Validators.required)
            },
            {
                name: 'end_at',
                type: 'time',
                label: 'Время По',
                control: new FormControl('', Validators.required)
            },
            {
                name: 'outlet_id',
                type: 'select',
                options: this.outlets,
                label: 'Торговая точка',
                control: new FormControl(null, Validators.required)
            },
            {
                name: 'promouter_id',
                type: 'select',
                options: this.promouters,
                label: 'Промоутер',
                control: new FormControl(null, Validators.required)
            },
            {
                name: 'supervisor_id',
                type: 'select',
                options: this.supervisors,
                label: 'Супервайзер',
                control: new FormControl(null, Validators.required)
            },
        ];
    }
}
