import {Injectable} from '@angular/core';
import {CrudService} from '../crud.service';
import {AppService} from '../app.service';
import {HttpClient} from '@angular/common/http';
import {Step} from '../../models/step';
import {Observable} from 'rxjs';
import {APP_CONFIG} from '../../app.config';

@Injectable()
export class StepService extends CrudService {
    constructor(http: HttpClient, private appService: AppService) {
        super(http);

        this.apiUrl = '/api/projects/' + (this.appService.isProjectSelected() && this.appService.getCurProject().id) + '/steps/';

        this.appService.getCurProjectObservable().subscribe((project) => {
            if (typeof project !== 'undefined') {
                this.apiUrl = '/api/projects/' + project.id + '/steps/';
            }
        });
    }

    createContactStructure(projectId: number, steps: Step[]): Observable<any> {
        return this.http.post(`${APP_CONFIG.backendHost}/api/projects/${projectId}/create-contact-structure`, steps);
    }

    uploadImage(image: File): Observable<any> {
      const formData: FormData = new FormData();
      formData.append('file', image, image.name);
      return this.http.post(`${APP_CONFIG.backendHost}/api/projects/${this.appService.getCurProject().id}/upload-image-field`, formData);
    }

    updateContactStructure(projectId: number, data: {
        del: {
            steps: number[],
            choices: number[]
        }
        steps: Step[]
    }): Observable<any> {
        return this.http.post(`${APP_CONFIG.backendHost}/api/projects/${projectId}/update-contact-structure`, data);
    }
}
