import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {FiasCityService} from '../../../../services/entity-services/fias-city.service';
import {FiasCity} from '../../../../models/fias-city';

@Component({
    selector: 'field-cities-select',
    templateUrl: './cities-select.component.html',
    styleUrls: ['./cities-select.component.scss'],
    providers: [FiasCityService]
})
export class FieldCitiesSelectComponent implements OnInit {

    @Input() field: FormGroup;
    citiesControl: FormGroup;

    cityInputSubscription: Subscription;
    cityLoading = false;
    cityInput$ = new Subject<string>();
    availableCities = [];
    selectedCity = null;

    constructor(private fb: FormBuilder, public cityService: FiasCityService) {
    }

    ngOnInit() {
        const payload = <FormArray>this.field.get('payload');
        const controls = payload.controls;

        this.citiesControl = <FormGroup>controls.find((item) => {
            const control = <FormGroup>item;
            return control.controls['name'].value === 'availableCities';
        });
        this.subscribeToCityOptions();
    }

    private subscribeToCityOptions() {
        this.cityInputSubscription = this.cityInput$
            .pipe(
                debounceTime(500),
            )
            .subscribe(
                value => {
                    if (value && value.length > 1) {
                        this.cityLoading = true;
                        this.availableCities = [];
                        this.cityService.search(value).subscribe((availableCities: FiasCity[]) => {
                            this.availableCities = availableCities.filter((item) => {
                                return !this.getContentArray().value.find(existing => existing.id == item.id);
                            });
                            this.cityLoading = false;
                        });
                    }
                });
    }

    removeFromAvailableCities = (itemToRemove) => {
        this.availableCities = this.availableCities.filter((item) => {
            return item.id !== itemToRemove.id;
        });
    };

    addToAvailableCities = (itemToAdd) => {
        this.availableCities.push(itemToAdd);
    };

    getContentArray = (): AbstractControl => {
        return this.citiesControl.get('content');
    };

    getCityNameWithRegion = (city) => {
        const cityName = city ? city.name : ``;
        const regionName = 'region' in city ? ` (${city.region.name} ${city.region.type})` : ``;

        return cityName + regionName;
    };

    addCity = () => {
        if (!this.selectedCity) {
            return;
        }
        this.getContentArray().value.push({
            'id': this.selectedCity.id,
            'name': this.selectedCity.name,
            'region': this.selectedCity.region
        });
        this.removeFromAvailableCities(this.selectedCity);
        this.selectedCity = null;
    };

    removeCity = (city) => {
        this.getContentArray().setValue(this.getContentArray().value.filter(item => item.id !== city.id));
        this.addToAvailableCities(city);
    };
}
