import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {DialogComponent} from './dialog.component';

@Injectable()
export class DialogService {
    constructor(private modalService: NgbModal) {}

    public confirm(title: string = 'Подтвердить действие?', message: string = 'Да/Нет') {
        const modalRef = this.modalService.open(DialogComponent, {backdrop: 'static'});
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.changeRef.markForCheck();
        return modalRef.result;
    }
}
