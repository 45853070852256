export class SegmentBlockRule {
    name: string;
    next_step_id: number;

    constructor(params = {
        name: null,
        next_step_id: null,
    }) {
        const {name, next_step_id} = params;

        this.name = name;
        this.next_step_id = next_step_id;
    }

    static get blocks(): Array<string> {
        return [
            this.DEFAULT,
            this.QUESTIONNAIRE,
            this.QUESTIONNAIRE_WITH_COMPLIMENT,
            this.TEST_DRIVE_S10,
            this.TEST_DRIVE_X,
            this.SWAP,
            this.SWAP_DEVICE_S10,
            this.SWAP_DEVICE_X,
            this.APPLY_FOR_RENT,
            this.DEVICE_S10_WAS_PURCHASED,
            this.DEVICE_X_WAS_PURCHASED,
            this.GIFT_FOR_TODAY,
            this.GAP_STICKS
        ];
    };

    static get blocksReadable(): Object {
        return {
            [this.DEFAULT]: 'По умолчанию',
            [this.QUESTIONNAIRE]: 'Анкета заблокирована',
            [this.QUESTIONNAIRE_WITH_COMPLIMENT]: 'Анкета за комплимент заблокирована',
            [this.TEST_DRIVE_S10]: 'Аренда заблокирована (S10)',
            [this.TEST_DRIVE_X]: 'Аренда заблокирована (X)',
            [this.SWAP]: 'Обмен заблокирован',
            [this.SWAP_DEVICE_S10]: 'Обмен устройств заблокирован (S10)',
            [this.SWAP_DEVICE_X]: 'Обмен устройств заблокирован (X)',
            [this.APPLY_FOR_RENT]: 'Заявка на аренду заблокирована',
            [this.DEVICE_S10_WAS_PURCHASED]: 'Блокировка покупки (S10)',
            [this.DEVICE_X_WAS_PURCHASED]: 'Блокировка покупки (X)',
            [this.GIFT_FOR_TODAY]: 'Анкета за подарок заблокирована (на сегодня)',
            [this.GAP_STICKS]: 'Выдача GapSticks заблокирована',
        };
    };

    static get DEFAULT(): string {
        return 'default';
    };

    static get QUESTIONNAIRE(): string {
        return 'questionnaire';
    };

    static get TEST_DRIVE_S10(): string {
        return 'test-drive';
    };

    static get TEST_DRIVE_X(): string {
        return 'test-drive-x';
    };

    static get SWAP(): string {
        return 'swap';
    };

    static get SWAP_DEVICE_S10(): string {
        return 'swap-device';
    }

    static get SWAP_DEVICE_X(): string {
        return 'swap-device-x';
    }

    static get APPLY_FOR_RENT(): string {
        return 'apply-for-rent';
    };

    static get DEVICE_S10_WAS_PURCHASED(): string {
        return 'ploom-device-was-purchased';
    };

    static get DEVICE_X_WAS_PURCHASED(): string {
        return 'ploom-device-x-was-purchased';
    };

    static get QUESTIONNAIRE_WITH_COMPLIMENT(): string {
        return 'questionnaire-with-compliment';
    };

    static get GIFT_FOR_TODAY(): string {
        return 'gift-for-today';
    };

    static get GAP_STICKS(): string {
        return 'gap-sticks';
    };
}
