import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as d3 from 'd3';
import { select as d3Select, event as d3Event } from 'd3-selection';
import { scaleOrdinal as d3scaleOrdinal } from 'd3-scale';
import { drag as d3drag } from 'd3-drag';

@Component({
    selector: 'mg-force-directed-graph',
    templateUrl: 'mg-force-directed-graph.component.html',
    styleUrls: ['mg-force-directed-graph.component.scss']
})
export class MgForceDirectedGraphComponent implements OnInit {
    @Input() options: any = {};
    @Input() graph: any = {};

    @Output() clickNode: EventEmitter<any> = new EventEmitter();

    width: number;
    height: number;

    private svg;
    private color;
    private simulation;

    ngOnInit() {
        const svg = d3Select('svg.graph');
        this.width = +this.options.width || 960;
        this.height = +this.options.height || 400;

        const color = d3scaleOrdinal(d3.schemeCategory20);

        const simulation = d3.forceSimulation()
            .force('link', d3.forceLink().id((d) => {
                return d.id;
            }))
            .force('charge', d3.forceManyBody())
            .force('center', d3.forceCenter(this.width / 2, this.height / 2));

        let link = svg.append('g')
            .attr('class', 'links')
            .selectAll('line')
            .data(this.graph.links)
            .enter().append('line')
            .attr('style', "stroke: #1098cc")
            .attr('stroke-width', +this.options.strokeWidth || 2);

        let node = svg.append('g')
            .attr('class', 'nodes')
            .selectAll('circle')
            .data(this.graph.nodes)
            .enter().append('circle')
            .attr('r', +this.options.nodeRadius || 5)
            .attr('fill', (d) => {
                return d.nodeColor;
            })
            .call(d3drag()
                .on('start', (d) => {
                    if (!d3Event.active) simulation.alphaTarget(0.3).restart();
                    d.fx = d.x;
                    d.fy = d.y;
                })
                .on('drag', (d) => {
                    d.fx = d3Event.x;
                    d.fy = d3Event.y;
                })
                .on('end', (d) => {
                    if (!d3Event.active) simulation.alphaTarget(0);
                    d.fx = null;
                    d.fy = null;
                })
            );

        node.on('click', (d) => {
            this.clickNode.emit(d);
        });

        node.append('title')
            .text((d) => {
                return d.description/* || d.index*/
            });

        simulation
            .nodes(this.graph.nodes)
            .on("tick", () => {
                link
                    .attr("x1", (d) => {
                        return d.source.x;
                    })
                    .attr("y1", (d) => {
                        return d.source.y;
                    })
                    .attr("x2", (d) => {
                        return d.target.x;
                    })
                    .attr("y2", (d) => {
                        return d.target.y;
                    });

                node
                    .attr("cx", (d) => {
                        return d.x;
                    })
                    .attr("cy", (d) => {
                        return d.y;
                    });
            });

        simulation.force("link")
            .links(this.graph.links);
    }

    reset() {
        d3Select('svg > .links').remove();
        d3Select('svg > .nodes').remove();
    }
}
