import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudService} from '../crud.service';
import {AppService} from '../app.service';

import {APP_CONFIG} from '../../app.config';

@Injectable()
export class TeamService extends CrudService {
  constructor(http: HttpClient, private appService: AppService) {
    super(http);

    this.apiUrl = '/api/projects/' + this.appService.getCurProject().id + '/teams/';

    this.appService.getCurProjectObservable().subscribe((project) => {
      if (typeof project !== 'undefined') {
        this.apiUrl = '/api/projects/' + project.id + '/teams/';
      }
    });
  }

  getTeamConsultants(teamId: number, query: string = null) {
    let queryString = '';

    if (query) {
      queryString = '?query=' + query;
    }

    return this.http.get(APP_CONFIG.backendHost + this.apiUrl + teamId + '/consultants' + queryString);
  }

  getTeamCities(teamId: number) {
    return this.http.get(APP_CONFIG.backendHost + this.apiUrl + teamId + '/cities');
  }

  getAvailableTeamLeaders(query: string = null) {
    let queryString = '';

    if (query) {
      queryString = '?query=' + query;
    }

    return this.http.get(APP_CONFIG.backendHost + '/api/projects/' + this.appService.getCurProject().id + '/team-leaders' + queryString);
  }

  getAvailableConsultants(teamId: number, query: string = null) {
    let queryString = '';

    if (query) {
      queryString = '?query=' + query;
    }

    return this.http.get(APP_CONFIG.backendHost + this.apiUrl + teamId + '/available-consultants' + queryString);
  }

  getAvailableCities(teamId: number, query: string = null) {
    let queryString = '';

    if (query) {
      queryString = '?query=' + query;
    }

    return this.http.get(APP_CONFIG.backendHost + this.apiUrl + teamId + '/available-cities' + queryString);
  }

  addConsultants(teamId: number, consultants: number[]) {
    return this.http.post(APP_CONFIG.backendHost + this.apiUrl + teamId + '/consultants', {
      'consultants': consultants,
    });
  }

  addCities(teamId: number, cities: number[]) {
    return this.http.post(APP_CONFIG.backendHost + this.apiUrl + teamId + '/cities', {
      'cities': cities,
    });
  }
}
