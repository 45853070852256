import {Injectable} from '@angular/core';
import {CrudService} from '../crud.service';
import {AppService} from '../app.service';

import {APP_CONFIG} from '../../app.config';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {publishLast, refCount} from 'rxjs/operators';

@Injectable()
export class WorkScheduleService extends CrudService {
    constructor(http: HttpClient, private appService: AppService) {
        super(http);

        this.apiUrl = '/api/projects/' + this.appService.getCurProject().id + '/work-schedules/';

        this.appService.getCurProjectObservable().subscribe((project) => {
            if (typeof project !== 'undefined') {
                this.apiUrl = '/api/projects/' + project.id + '/work-schedules/';
            }
        });
    }

    violations(scheduleId: number): Observable<any> {
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + scheduleId + '/violations/')
            .pipe(
              publishLast(),
              refCount(),
            )
    }

    findWithHidden(scheduleId: number): Observable<any> {
        return this.http.get(APP_CONFIG.backendHost + this.apiUrl + scheduleId + '/with-hidden/')
            .pipe(
              publishLast(),
              refCount(),
            )
    }

    checkByProjectManager(scheduleId: number, checked: boolean): Observable<any> {
        return this.http.put(
            APP_CONFIG.backendHost + this.apiUrl + scheduleId + '/check-by-project-manager',
            {
                checked
            }
        );
    }

    checkByRegionalManager(scheduleId: number, checked: boolean): Observable<any> {
        return this.http.put(
            APP_CONFIG.backendHost + this.apiUrl + scheduleId + '/check-by-regional-manager',
            {
                checked
            }
        );
    }

}
