import {ComponentRef, Directive, ElementRef, HostListener, Input} from '@angular/core';
import {NgbDatepicker, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';

@Directive({
    selector: '[datepickerToggle]',
})
export class DatepickerToggleDirective {
    // tslint:disable-next-line no-input-rename
    @Input('datepickerToggle') inputDatepicker: NgbInputDatepicker;
    @Input() closeDatepickerOnClick = true;

    constructor(private _elementRef: ElementRef) {
    }

    @HostListener('click')
    public toggle() {
        if (this.inputDatepicker.isOpen() && this.closeDatepickerOnClick) {
            this.inputDatepicker.close();
        }

        this.inputDatepicker.open();
    }

    @HostListener('document:click', ['$event'])
    public closeOnOutsideEvent($event: MouseEvent) {
        if (this.inputDatepicker.isOpen()) {
            if (!this.isTargetingToggleButton($event) && this.shouldCloseOnOutsideEvent($event)) {
                this.inputDatepicker.close();
            }
        }
    }

    private isTargetingToggleButton($event: MouseEvent): boolean {
        return this._elementRef.nativeElement.contains($event.target);
    }

    private shouldCloseOnOutsideEvent($event: MouseEvent): boolean {
        const inputDatepickerRef: ElementRef = (this.inputDatepicker as any)._elRef;
        const datepickerComponentRef: ComponentRef<NgbDatepicker> = (this.inputDatepicker as any)._cRef;

        if (inputDatepickerRef != null && datepickerComponentRef != null) {
            let popupClick = false;
            const inputClick = inputDatepickerRef.nativeElement.contains($event.target);

            if (this.inputDatepicker.isOpen() && datepickerComponentRef.location.nativeElement.contains($event.target)) {
                popupClick = true;
            }
            return !inputClick && !popupClick;
        }

        return false;
    }
}
