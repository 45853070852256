import {Component, OnInit, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import {UserService} from '../../services/entity-services/user.service';
import {User} from '../../models/user';
import {NgbModalRef, NgbModal, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, FormBuilder, Validators, ValidatorFn, ValidationErrors, AbstractControl} from '@angular/forms';
import {DialogService} from '../../shared/dialog/dialog.service';
import {Project} from '../../models/project';
import {AppService} from '../../services/app.service';
import {City} from '../../models/city';
import {CityService} from '../../services/entity-services/city.service';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {ProjectUserService} from '../../services/entity-services/project-user.service';
import {FormService} from '../../services/form.service';
import {Area} from '../../models/area';
import {Team} from '../../models/team';
import 'lodash';
import {ScrollToService, ScrollToConfigOptions} from '@nicky-lenaers/ngx-scroll-to';


declare var _: any;

import {
    debounceTime,
    switchMap,
    distinctUntilChanged,
    merge,
    map,
} from 'rxjs/operators';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    providers: [
        UserService,
        ProjectUserService,
        CityService
    ]
})
export class UserComponent implements OnInit, OnDestroy {
    @ViewChild('add') addModal: ElementRef;
    @ViewChild('edit') editModal: ElementRef;
    @ViewChild('projectListModal') projectListModal: ElementRef;
    @ViewChild('teamListModal') teamListModal: ElementRef;
    @ViewChild('cityListModal') cityListModal: ElementRef;
    @ViewChild('passwordToolTip') passwordToolTip: NgbTooltip;

    users: User[] = [];
    projects: Project[];
    project: Project;
    cities: City[];
    areas: Area[];
    generatedPassword: any = null;
    teams: Team[] = [];

    selectedUser: User;
    selectedUserCities: City[];
    selectedUserTeams: any[];
    selectedUserProjects: Project[];

    teamList: Team[] = [];

    cityList: City[];
    projectList: Project[];

    formAdd: FormGroup;
    formEdit: FormGroup;

    params: any = {};
    params$: BehaviorSubject<any>;
    usersSubscription$: Subscription;
    areaSubscription$: Subscription;
    projectSubscription$: Subscription;
    count = 0;

    teamLoading = false;
    teamInput$ = new Subject<string>();
    selectedTeam = null;
    teamInputSubscription: Subscription;

    messages = {
        emptyMessage: 'Нет данных',
        totalMessage: 'всего'
    };
    errorMessages: string[] = [];

    alphabet: string[] = [];
    phonePattern = {
        '7': {
            pattern: new RegExp('[7]'),
        },
        '0': {
            pattern: new RegExp('\\d'),
        }
    };
    emailRegex: RegExp = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    nameRegex: RegExp = /(^[а-яёА-ЯЁa-zA-Z0-9\s-]+$)/;
    phoneRegex: RegExp = /(7[0-9]{10})$/;

    passwordError: any = {
        status: false,
        error: '',
    };

    teamError: any = {
        status: false,
        error: '',
    };

    searchNameModel: any;
    searchNameFocus$: Subject<any> = new Subject<any>();
    searchLastNameModel: any;
    searchLastNameFocus$: Subject<any> = new Subject<any>();
    searchEmailModel: any;
    searchEmailFocus$: Subject<any> = new Subject<any>();
    searchPhoneModel: any;
    searchPhoneFocus$: Subject<any> = new Subject<any>();

    private modalRef: NgbModalRef;

    constructor(private fb: FormBuilder,
                public appService: AppService,
                public userService: UserService,
                public projectUserService: ProjectUserService,
                private cityService: CityService,
                private modalService: NgbModal,
                private dialogService: DialogService,
                private formService: FormService,
                private scrollToService: ScrollToService) {
        this.cityService.get().subscribe((response) => {
            this.cities = response.data;
        });
        this.projects = this.appService.getProjects();
        this.cityList = [];
        this.projectList = [];
        this.selectedUserCities = [];
        this.selectedUserProjects = [];
        this.teamList = [];
        this.generateAlphabet();
    }

    searchNameFormatter = (result) => result.first_name;
    searchName = (text$: Observable<string>) => {
        return text$
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                merge(this.searchNameFocus$),
                switchMap(term => this.projectUserService.getInProject(this.project.id, {
                    page: 1,
                    limit: 10,
                    'first_name_like': term,
                })),
                map((response) => response.data),
            );
    };

    searchLastNameFormatter = (result) => result.last_name;
    searchLastName = (text$: Observable<string>) => {
        return text$
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                merge(this.searchLastNameFocus$),
                switchMap(term => this.projectUserService.getInProject(this.project.id, {
                    page: 1,
                    limit: 10,
                    'last_name_like': term,
                })),
                map((response) => response.data),
            );
    };

    searchEmailFormatter = (result) => result.email;
    searchEmail = (text$: Observable<string>) => {
        return text$
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                merge(this.searchEmailFocus$),
                switchMap(term => this.projectUserService.getInProject(this.project.id, {
                    page: 1,
                    limit: 10,
                    'email_like': term,
                })),
                map((response) => response.data),
            );
    };

    searchPhoneFormatter = (result) => result.phone;
    searchPhone = (text$: Observable<string>) => {
        return text$
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                merge(this.searchPhoneFocus$),
                switchMap(term => this.projectUserService.getInProject(this.project.id, {
                    page: 1,
                    limit: 10,
                    'phone_like': term,
                })),
                map((response) => response.data),
            );
    };

    ngOnInit(): void {
        this.project = this.appService.getCurProject();
        if (this.project === null) {
            this.projectSubscription$ = this.appService.getCurProjectObservable().subscribe((project) => {
                if (typeof project !== 'undefined') {
                    this.project = project;
                    this.initData();
                }
            });
        } else {
            this.initData();
        }

    };

    initData(): void {
        this.params = {
            page: 1,
            limit: 10
        };
        this.params$ = new BehaviorSubject<any>(this.params);

        this.usersSubscription$ = this.params$
            .pipe(
                debounceTime(300),
                switchMap(params => this.projectUserService.getInProject(this.project.id, params)),
            )
            .subscribe((response) => {
                this.users = response.data;
                this.count = response.count;
            });

        this.areaSubscription$ = this.params$
            .pipe(
                debounceTime(300),
                switchMap(params => this.userService.outletAreas()),
            )
            .subscribe((response: any) => {
                this.areas = response;
            });

        this.selectedTeam = null;
        this.teamLoading = true;
        this.teamInputSubscription = this.userService
            .getTeamsOfTeamLead(this.project.id, this.appService.getCurrentUser().id).subscribe((teams: Team[]) => {
                this.teamList = teams;
                this.teamLoading = false;
            });

        this.formAdd = this.fb.group({
            first_name: [null, Validators.compose([Validators.required, this.customFirstNameValidator])],
            last_name: [null, Validators.compose([Validators.required, this.customLastNameValidator])],
            email: [null, Validators.compose([Validators.required, this.customEmailValidator])],
            phone: [null, Validators.compose([Validators.required, this.customPhoneValidator])],
            role: ['manager', Validators.compose([Validators.required])],
            region_id: [null],
            selected_team: [null],
            password: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
        }, {validator: [this.passwordValidationAddForm, this.customTeamValidator]});

        this.formEdit = this.fb.group({
            id: [null],
            first_name: [null, Validators.compose([Validators.required, this.customFirstNameValidator])],
            last_name: [null, Validators.compose([Validators.required, this.customLastNameValidator])],
            email: [null, Validators.compose([Validators.required, this.customEmailValidator])],
            phone: [null, Validators.compose([Validators.required, this.customPhoneValidator])],
            role: ['manager', Validators.compose([Validators.required])],
            region_id: [null],
            selected_team: [null],
            password: [null, Validators.compose([Validators.minLength(6)])]
        }, {validator: this.passwordValidation});
    };

    ngOnDestroy(): void {
        const {usersSubscription$, projectSubscription$} = this;
        if (usersSubscription$) {
            usersSubscription$.unsubscribe();
        }
        if (projectSubscription$) {
            projectSubscription$.unsubscribe();
        }
    };

    page($event): void {
        this.params.page = $event.offset + 1;
        this.applyParams();
    };

    filterChanged(filterName: string, filterValue): void {
        this.params.page = 1;

        const filterValueLower = filterValue.toLowerCase();

        if (!filterValueLower || filterValueLower === 'null' || filterValueLower === '') {
            delete this.params[filterName];
        } else {
            this.params[filterName] = filterValueLower;
        }

        this.applyParams();
    };

    openActionModal(id?): void {
        this.resetPasswordError();
        this.resetTeamError();
        this.resetGeneratedPassword();
        if (id) {
            const user = this.users.find((userItem) => userItem.id === id);
            this.formEdit.controls['id'].setValue(user.id);
            this.formEdit.controls['first_name'].setValue(user.first_name);
            this.formEdit.controls['last_name'].setValue(user.last_name);
            this.formEdit.controls['email'].setValue(user.email);
            this.formEdit.controls['phone'].setValue(user.phone);
            this.formEdit.controls['role'].setValue(user.role);
            this.formEdit.controls['region_id'].setValue(user.region_id);
            this.modalRef = this.modalService.open(this.editModal, {backdrop: 'static'});
        } else {
            this.modalRef = this.modalService.open(this.addModal, {backdrop: 'static'})
        }
    };

    openProjectListModal(id): void {
        this.selectedUser = this.users.find(user => user.id === id);
        this.userService.availableProjects(this.selectedUser.id).subscribe((availableProjects: Project[]) => {
            this.userService.projects(this.selectedUser.id).subscribe((projects: Project[]) => {
                this.selectedUserProjects = projects;
                this.projectList = availableProjects.filter((availableProject) => {
                    return !this.selectedUserProjects.find((userProject) => availableProject.id === userProject.id);
                });
            });
            this.modalRef = this.modalService.open(this.projectListModal, {backdrop: 'static'});
        });
    };

    openTeamListModal(id): void {
        this.selectedUser = this.users.find(user => user.id === id);
        this.userService.getConsultantTeams(this.project.id, this.selectedUser.id)
            .subscribe((teams: any[]) => {
                this.selectedUserTeams = teams;
                this.modalRef = this.modalService.open(this.teamListModal, {backdrop: 'static'});
            });
    };

    openCityListModal(id): void {
        this.selectedUser = this.users.find(user => user.id === id);
        this.userService.cities(this.selectedUser.id).subscribe((cities: City[]) => {
            this.selectedUserCities = cities;
            this.cityList = this.cities.filter((city) => {
                return !this.selectedUserCities.find((userCity) => city.id === userCity.id);
            });
        });
        this.modalRef = this.modalService.open(this.cityListModal, {backdrop: 'static'});
    };

    addProject(projectId): void {
        const project = this.projectList.find((projectItem) => projectItem.id == projectId);
        const index = this.projectList.indexOf(project);
        if (index !== -1) {
            this.projectList.splice(index, 1);
            this.selectedUserProjects.push(project);
        }
    };

    addCity(cityId): void {
        const city = this.cities.find((cityItem) => cityItem.id === cityId);
        const index = this.cityList.indexOf(city);
        if (index !== -1) {
            this.cityList.splice(index, 1);
            this.selectedUserCities.push(city);
        }
    };

    removeProject(projectId): void {
        this.selectedUserProjects = this.selectedUserProjects.filter((project) => {
            if (project.id === projectId) {
                this.projectList.push(project);
                return false;
            }
            return true;
        });
    };

    saveUserProjects(): void {
        this.userService
            .addProjects(this.selectedUser.id, this.selectedUserProjects.map((item) => item.id))
            .subscribe((res) => {
                this.selectedUser = null;
                this.modalRef.close();
            });
    };

    removeCity(cityId): void {
        this.selectedUserCities = this.selectedUserCities.filter((city) => {
            if (city.id === cityId) {
                this.cityList.push(city);
                return false;
            }
            return true;
        });
    };

    saveUserCities(): void {
        this.userService
            .addCities(this.selectedUser.id, this.selectedUserCities.map((item) => item.id))
            .subscribe((res) => {
                this.selectedUser = null;
                this.modalRef.close();
            });
    };

    triggerScrollTo() {
        const config: ScrollToConfigOptions = {
            target: 'destination'
        };

        this.scrollToService.scrollTo(config);
    }

    getAllSubstrings = (str): any => {
        const result = [];

        if (str) {
            for (let i = 0; i < str.length - 2; i++) {
                result.push(str.slice(i, i + 3));
            }
        }
        return result;
    };

    regexCount = (str, pattern) => {
        return ((str || '').match(pattern) || []).length
    };

    setPasswordError = (message) => {
        this.passwordError = {
            status: true,
            error: message,
        };
    };

    resetPasswordError = () => {
        this.passwordError = {
            status: false,
            error: '',
        };
    };

    setTeamError = (message) => {
        this.teamError = {
            status: true,
            error: message,
        };
    };

    resetTeamError = () => {
        this.teamError = {
            status: false,
            error: '',
        };
    };

    passwordValidationAddForm: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
        this.passwordValidation(control);

        const password = control.get('password').value;
        if (!password) {
            this.setPasswordError('Обязательное поле');
            return {'password': true};
        }
    };

    passwordValidation: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
        const password = control.get('password').value;
        const email = control.get('email').value;
        const phone = control.get('phone').value;

        if (!password) {
            this.resetPasswordError();
            return null;
        }

        if (password.length < 8) {
            this.setPasswordError('Минимальная длина пароля составляет 8 символов');
            return {'password': true};
        }

        const subStrings = this.getAllSubstrings(email).concat(this.getAllSubstrings(phone));
        const countLower = this.regexCount(password, /[a-z]/g);
        const countUpper = this.regexCount(password, /[A-Z]/g);
        const countDigit = this.regexCount(password, /[0-9]/g);
        const countSpecial = this.regexCount(password, /[%*)?@#$~]/g);
        let characterTypesInPassword = 0;

        characterTypesInPassword += countLower ? 1 : 0;
        characterTypesInPassword += countUpper ? 1 : 0;
        characterTypesInPassword += countDigit ? 1 : 0;
        characterTypesInPassword += countSpecial ? 1 : 0;

        if (characterTypesInPassword < 3) {
            this.setPasswordError('Пароль должен быть создан с использованием не менее 3 символов из разных типов.');
            return {'password': true};
        }

        for (let i = 0; i < subStrings.length; i++) {
            if (password.includes(subStrings[i])) {
                this.setPasswordError('Пароль не должен содержать имя учетной записи пользователя или части полного имени пользователя,' +
                    ' которые превышают два последовательных символа.');
                return {'password': true};
            }
        }

        this.resetPasswordError();
        return null;
    };

    customTeamValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
        if (this.teamList.length > 1 && !this.selectedTeam) {
            this.setTeamError('Выберите команду');
            return {'selected_team': true};
        } else {
            this.resetTeamError();
            return null;
        }
    };

    onAdd(): void {
        this.formService.markControlsAsTouched(this.formAdd);

        if (this.formAdd.valid) {
            const user = new User();
            user.first_name = this.formAdd.value.first_name;
            user.last_name = this.formAdd.value.last_name;
            user.email = this.formAdd.value.email;
            user.phone = this.formAdd.value.phone;
            user.role = this.formAdd.value.role;
            user.password = this.formAdd.value.password;
            user.selected_team = this.selectedTeam;
            if (this.formAdd.value.role === 'manager') {
                user.region_id = this.formAdd.value.region_id;
            } else {
                user.region_id = null;
            }

            this.projectUserService.createInProject(this.project.id, user).subscribe(data => {
                this.users.push(data);
                this.formAdd.reset();
                this.modalRef.close();
                this.initData();
            }, error => {
                for (const errorKey in error.errors) {
                    if (this.formAdd.value.hasOwnProperty(errorKey.toString())) {
                        this.errorMessages[errorKey] = error.errors[errorKey][0] || null;
                        this.formAdd.controls[errorKey].setErrors({
                            'backend': true
                        });
                    }
                }
            });
        }
    };

    onEdit(): void {
        this.formService.markControlsAsTouched(this.formEdit);
        if (this.formEdit.valid) {
            const user = new User();
            user.id = this.formEdit.value.id;
            user.first_name = this.formEdit.value.first_name;
            user.last_name = this.formEdit.value.last_name;
            user.email = this.formEdit.value.email;
            /*user.phone = this.formEdit.value.phone;*/
            user.role = this.formEdit.value.role;
            user.password = this.formEdit.value.password;
            if (this.formEdit.value.role === 'manager') {
                user.region_id = this.formEdit.value.region_id;
            } else {
                user.region_id = null;
            }

            this.projectUserService.updateInProject(this.project.id, user).subscribe((data) => {
                const updatedUser = this.users.find((data) => data.id === user.id);
                updatedUser.first_name = data.first_name;
                updatedUser.last_name = data.last_name;
                updatedUser.email = data.email;
                /*updatedUser.phone = data.phone;*/
                updatedUser.role = data.role;
                updatedUser.role_name = data.role_name;
                updatedUser.region_id = data.region_id;
                this.modalRef.close();
            }, error => {
                for (const errorKey in error.errors) {
                    if (this.formEdit.value.hasOwnProperty(errorKey.toString())) {
                        this.errorMessages[errorKey] = error.errors[errorKey][0] || null;
                        this.formEdit.controls[errorKey].setErrors({
                            'backend': true,
                        });
                    }
                }
            });
        }
    };

    onDelete(id): void {
        this.dialogService.confirm().then((res) => {
            if (res) {
                this.projectUserService.delInProject(this.project.id, id).subscribe(data => {
                    this.users = this.users.filter(user => user.id !== id);
                });
            }
        });
    };

    protected applyParams(): void {
        this.params$.next(this.params);
    };

    protected generateAlphabet(): void {
        const lastCharacterCode = 'Я'.charCodeAt(0);

        let currentCharacterCode = 'А'.charCodeAt(0);

        while (currentCharacterCode <= lastCharacterCode) {
            this.alphabet.push(String.fromCharCode(currentCharacterCode));

            ++currentCharacterCode;
        }
    };

    protected userIsDeveloper(): boolean {
        return this.appService.getCurrentUser().role === 'developer';
    };

    protected userIsAdmin(): boolean {
        return this.appService.getCurrentUser().role === 'admin';
    };

    protected userIsManager(): boolean {
        return this.appService.getCurrentUser().role === 'manager';
    };

    protected userIsTeamLeader(): boolean {
        return this.appService.getCurrentUser().role === 'team_leader';
    };

    protected canEditProjects(user): boolean {
        const role = this.appService.getCurrentUser().role;
        switch (role) {
            case 'developer':
                return true;
            case 'admin':
                return user.role === 'team_leader' || user.role === 'consultant'
                    || user.role === 'auditor' || user.role === 'manager';
            case 'manager':
                return user.role === 'team_leader' || user.role === 'consultant' || user.role === 'auditor' || user.role === 'supervisor';
            case 'team_leader':
                return user.role === 'consultant' || user.role === 'auditor';
            default:
                return false;
        }
    };

    protected canEditUser(user): boolean {
        const role = this.appService.getCurrentUser().role;
        switch (role) {
            case 'developer':
                return true;
            case 'admin':
                return user.role === 'team_leader' || user.role === 'consultant'
                    || user.role === 'auditor' || user.role === 'manager';
            case 'manager':
                return user.role === 'team_leader' || user.role === 'consultant' 
                    || user.role === 'supervisor' || user.role === 'auditor';
            case 'team_leader':
                return user.role === 'consultant';
            default:
                return false;
        }
    }

    protected canDeleteUser(user): boolean {
        const role = this.appService.getCurrentUser().role;
        switch (role) {
            case 'admin':
                return true;
            case 'developer':
                return true;
            /*case 'manager':
                return (user.role === 'team_leader' || user.role === 'consultant');
            case 'team_leader':
                return (user.role === 'consultant');*/
            default:
                return false;
        }
    };

    protected generatePassword = () => {
        this.userService.generatePassword().subscribe((password: any) => {
            this.generatedPassword = password;
            this.formAdd.controls['password'].setValue(password);
        });
    };

    protected resetGeneratedPassword = () => {
        this.generatedPassword = null;
        if (this.formEdit) {
            this.formEdit.controls['password'].setValue(null);
        }
    };

    customEmailValidator = (control: AbstractControl): ValidationErrors => {
        if (!control.value) {
            return null;
        }

        return this.emailRegex.test(control.value)
            ? null
            : {'email': true};
    };

    customFirstNameValidator = (control: AbstractControl): ValidationErrors => {
        if (!control.value) {
            return null;
        }

        return this.nameRegex.test(control.value)
            ? null
            : {'first_name': true};
    };

    customLastNameValidator = (control: AbstractControl): ValidationErrors => {
        if (!control.value) {
            return null;
        }

        return this.nameRegex.test(control.value)
            ? null
            : {'last_name': true};
    };

    customPhoneValidator = (control: AbstractControl): ValidationErrors => {
        if (!control.value) {
            return null;
        }

        return this.phoneRegex.test(control.value)
            ? null
            : {'phone': true};
    };

    protected canSeeUserTeams(): boolean {
        if (this.appService.getCurrentUser()) {
            const permissions = this.appService.getCurrentUser().extra_permissions;
            let displayPermission = false;

            if (permissions) {
                displayPermission = permissions.find(permission => permission.name == 'display-user-teams');
            }
            return this.appService.getCurrentUser().role === 'developer' || displayPermission;
        }

        return false;
    }

    public canRemoveUserFromProject(): boolean {
        const permissions = this.appService.getCurrentUser().extra_permissions;
        let displayPermission = false;

        if (permissions) {
            displayPermission = permissions.find(permission => permission.name == 'remove-user-from-project');
        }
        return this.appService.getCurrentUser().role === 'developer' || displayPermission;
    }
}
