import {Component, Injector, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'theme-template-property',
    templateUrl: './property.component.html',
    styleUrls: ['./property.component.scss']
})
export class PropertyComponent {
    @Input() formName: string;
    @Input() control: FormControl;

    @Input() label: string;
    @Input() placeholder: string;
    @Input() name: string;

    constructor(private injector: Injector) {
    }
}
