import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MgCrudComponent} from './mg-crud/mg-crud.component';
import {MgCrudTableComponent} from './mg-crud-table/mg-crud-table.component';
import { MgCrudFilterComponent } from './mg-crud-filter/mg-crud-filter.component';
import {TextMaskModule} from 'angular2-text-mask';

export interface Field {
    name: string;
    type: 'input' | 'textarea' | 'select' | 'password' | 'date' | 'time',
    options?: {
        key: string | number,
        value: string | number
    }[],
    label: string,
    placeholder?: string,
    textMask?: { mask: any, pipe: any }

    control: FormControl
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        NgbModule,
        TextMaskModule,
    ],
    declarations: [
        MgCrudTableComponent,
        MgCrudComponent,
        MgCrudFilterComponent,
    ],
    exports: [
        MgCrudComponent,
        MgCrudFilterComponent,
    ],
    entryComponents: [
        MgCrudFilterComponent,
    ]
})
export class MgCrudModule {
}
