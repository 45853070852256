import {Injectable} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';

@Injectable()
export class FormService {
    markControlsAsTouched(formGroup: FormGroup): void {
        this.map(formGroup, (control) => {
            control.markAsTouched();
        })
    }

    markControlsAsUntouched(formGroup: FormGroup): void {
        this.map(formGroup, (control) => {
            control.markAsUntouched();
        })
    }

    updateControlsValueAndValidity(formGroup: FormGroup): void {
        this.map(formGroup, (control) => {
            control.updateValueAndValidity();
        })
    }

    validateControls(formGroup: FormGroup): void {
        this.map(formGroup, (control) => {
            control.updateValueAndValidity();
            control.markAsTouched();
        })
    }

    validateControlsIfNotEmpty(formGroup: FormGroup): void {
        this.map(formGroup, (control) => {
            if (control.value) {
                control.updateValueAndValidity();
                control.markAsTouched();
            }
        })
    }

    map(formGroup: FormGroup, callback: Function): void {
        (Object as any).values(formGroup.controls).forEach((control) => {
            if (control instanceof FormArray) {
                for (const item of control.controls) {
                    this.map(item as FormGroup, callback);
                }
                return;
            }

            callback(control);
        });
    }

    clearFormArray = (formArray: FormArray) => {
        while (formArray.length !== 0) {
            formArray.removeAt(0)
        }
    };
}
