import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, ValidationErrors} from '@angular/forms';

@Component({
    selector: 'field-min-max-values',
    templateUrl: './min-max-values.component.html',
    styleUrls: ['./min-max-values.component.scss']
})
export class FieldMinMaxValuesComponent {

    @Input() field: FormGroup;
    @Input() validationError: ValidationErrors;

    constructor() {
    }
}
