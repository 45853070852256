import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {Violation} from '../../models/violation';
import {Promouter} from '../../models/promouter';
import {Outlet} from '../../models/outlet';
import {WorkSchedule} from '../../models/work-schedule';
import {AppService} from '../../services/app.service';
import {PromouterService} from '../../services/entity-services/promouter.service';
import {WorkScheduleService} from '../../services/entity-services/work-schedule.service';
import {OutletService} from '../../services/entity-services/outlet.service';
import {ViolationService} from '../../services/entity-services/violation.service';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {NgbModalRef, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DialogService} from '../../shared/dialog/dialog.service';
import {City} from '../../models/city';
import {Project} from '../../models/project';
import {ProjectService} from '../../services/entity-services/project.service';
import {AuthService} from '../../services/auth.service';
import {Subscription, forkJoin} from 'rxjs';
import {Refresh, RefreshService} from '../../services/refresh.service';
import {MgCrudComponent} from '../../shared/mg-crud/mg-crud/mg-crud.component';
import {Field} from '../../shared/mg-crud/mg-crud.module';

import {
    map,
} from 'rxjs/operators';

@Component({
    selector: 'app-violations',
    templateUrl: './violations.component.html',
    styleUrls: ['./violations.component.scss'],
    providers: [
        ViolationService,
        WorkScheduleService,
        PromouterService,
        ProjectService,
        OutletService,
        RefreshService,
    ]
})
export class ViolationsComponent implements OnInit, OnDestroy {

    @ViewChild(MgCrudComponent) mgCrudComponent: MgCrudComponent;

    fields: Field[] = [];
    columns: any = [];

    projectSubscription: Subscription;

    outlets: any = [];
    promouters: any = [];
    cities: any = [];
    statuses: any = [];

    constructor(private appService: AppService,
                public promouterService: PromouterService,
                public projectService: ProjectService,
                public outletService: OutletService,
                public violationService: ViolationService) {
    }

    ngOnInit(): void {
        this.initData();
        this.projectSubscription = this.appService.getCurProjectObservable().subscribe((project) => {
            if (typeof project !== 'undefined') {
                this.initData();
                this.mgCrudComponent.ngOnInit();
            }
        });
    }

    ngOnDestroy(): void {
        this.projectSubscription.unsubscribe();
    }

    protected initData(): void {
        this.initColumns();
        this.initFields();

        forkJoin(
            this.promouterService
                .all()
                .pipe(
                    map((promouters) => {
                        let options = [{
                            key: null,
                            value: '-- Все --'
                        }];

                        return options.concat(promouters.map((promouter) => {
                            return {
                                key: promouter.id,
                                value: promouter.fio
                            }
                        }));
                    }),
                ),
            this.projectService
                .cities(this.appService.getCurProject().id)
                .pipe(
                    map((cities) => {
                        let options = [{
                            key: null,
                            value: '-- Все --'
                        }];

                        return options.concat(cities.map((city) => {
                            return {
                                key: city.id,
                                value: city.name
                            }
                        }));
                    }),
                ),
            this.outletService
                .all()
                .pipe(
                    map((outlets) => {
                        let options = [{
                            key: null,
                            value: '-- Все --'
                        }];

                        return options.concat(outlets.map((outlet) => {
                            return {
                                key: outlet.id,
                                value: outlet.name
                            }
                        }));
                    }),
                )
        ).subscribe((data) => {
            [this.promouters, this.cities, this.outlets] = data;
            this.statuses = [
                {
                    key: null,
                    value: '-- Все --'
                },
                {
                    key: 0,
                    value: 'В ожидании рассмотрения'
                },
                {
                    key: 1,
                    value: 'Просмотрено РП'
                },
                {
                    key: 2,
                    value: 'Рассмотрено'
                }
            ];
        });
    }

    protected initColumns(): void {
        this.columns = [
            {
                name: 'Дата',
                prop: 'work_schedule.date'
            },
            {
                name: 'Время',
                prop: 'time'
            },
            {
                name: 'Промоутер',
                prop: 'work_schedule.promouter.fio'
            },
            {
                name: 'Город',
                prop: 'work_schedule.outlet.city.name'
            },
            {
                name: 'Точка',
                prop: 'work_schedule.outlet.name'
            },
            {
                name: 'Нарущение',
                prop: 'violation'
            },
            {
                name: 'Статус',
                prop: 'status'
            }
        ];
    }

    protected initFields(): void {
        this.fields = [
            {
                name: 'status',
                type: 'select',
                options: [
                    {
                        key: 0,
                        value: 'В ожидании рассмотрения'
                    },
                    {
                        key: 1,
                        value: 'Просмотрено РП'
                    },
                    {
                        key: 2,
                        value: 'Рассмотрено'
                    }
                ],
                label: 'Статус',
                control: new FormControl(0, Validators.required)
            },
            {
                name: 'decision',
                type: 'textarea',
                label: 'Решение',
                control: new FormControl('')
            }
        ];
    }

}
