import {Injectable} from '@angular/core';
import {CrudService} from '../crud.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AppService} from '../app.service';
import {APP_CONFIG} from '../../app.config';
import {Team} from '../../models/team';
import {User} from '../../models/user';
import {City} from '../../models/city';
import {Outlet} from '../../models/outlet';
import {AddressProgramValidationError} from '../../models/address-program-validation-error';

@Injectable()
export class AddressProgramService extends CrudService {

    constructor(http: HttpClient, private appService: AppService) {
        super(http);

        this.apiUrl = '/api/projects/' + this.appService.getCurProject().id + '/address-program/';

        this.appService.getCurProjectObservable().subscribe((project) => {
            if (typeof project !== 'undefined') {
                this.apiUrl = '/api/projects/' + project.id + '/address-program/';
            }
        });
    }

    getCityFilterOptions(teamId: number = null) {
        return this.http.get<City[]>(APP_CONFIG.backendHost + this.apiUrl + 'filters/cities', {
            params: teamId ? new HttpParams().set('teamId', teamId.toString()) : new HttpParams()
        });
    };

    getTeamFilterOptions(teamId: number = null) {
        return this.http.get<Team[]>(APP_CONFIG.backendHost + this.apiUrl + 'filters/teams', {
            params: teamId ? new HttpParams().set('teamId', teamId.toString()) : new HttpParams()
        });
    };

    getConsultantFilterOptions(teamId: number = null) {
        return this.http.get<User[]>(APP_CONFIG.backendHost + this.apiUrl + 'filters/consultants', {
            params: teamId ? new HttpParams().set('teamId', teamId.toString()) : new HttpParams()
        });
    };

    getOutletFilterOptions(teamId: number = null) {
        return this.http.get<Outlet[]>(APP_CONFIG.backendHost + this.apiUrl + 'filters/outlets', {
            params: teamId ? new HttpParams().set('teamId', teamId.toString()) : new HttpParams()
        });
    };

    getAvailableTeams() {
        return this.http.get<Team[]>(APP_CONFIG.backendHost + this.apiUrl + 'available/teams');
    };

    getAvailableConsultants(teamId: number = null) {
        return this.http.get<User[]>(APP_CONFIG.backendHost + this.apiUrl + 'available/consultants', {
            params: teamId ? new HttpParams().set('teamId', teamId.toString()) : new HttpParams()
        });
    };

    getAvailableOutlets(teamId: number = null, search: string = null) {
        let params = new HttpParams();

        if (teamId) {
            params = params.set('teamId', teamId.toString());
        }
        if (search) {
            params = params.set('search', search.toString());
        }

        return this.http.get<Outlet[]>(APP_CONFIG.backendHost + this.apiUrl + 'available/outlets', {
            params: params,
        });
    };

    uploadFile(file: File) {
        const formData: FormData = new FormData();
        formData.append('file', file);

        return this.http.post<AddressProgramValidationError[]>(APP_CONFIG.backendHost + this.apiUrl + 'upload', formData);
    };

    saveFile(file: File) {
        const formData: FormData = new FormData();
        formData.append('file', file);

        return this.http.post<AddressProgramValidationError[]>(APP_CONFIG.backendHost + this.apiUrl + 'save', formData);
    };

    templateUrl() {
        return APP_CONFIG.backendHost + this.apiUrl + 'template';
    }
}
