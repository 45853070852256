import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ThemeTemplateProperties} from '../../../models/design/theme-template-property';

@Component({
    selector: 'theme-template-properties',
    templateUrl: './theme-template-properties.component.html',
    styleUrls: ['./theme-template-properties.component.scss']
})
export class ThemeTemplatePropertiesComponent implements OnInit {

    @Input() type: string;
    @Input() properties: ThemeTemplateProperties;
    @Output() formReady = new EventEmitter<FormGroup>();
    propertiesFormGroup: FormGroup;

    constructor() {
    }

    ngOnInit(): void {
    }
}
