import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {OutletService} from '../../../services/entity-services/outlet.service';

@Component({
  selector: 'app-typeahead-outlet',
  templateUrl: './typeahead-outlet.component.html',
  styleUrls: ['./typeahead-outlet.component.scss'],
  providers: [
      OutletService,
  ]
})
export class TypeaheadOutletComponent implements OnInit {

  @Output('selectItem') change: EventEmitter<any> = new EventEmitter<any>();

  constructor(public outletService: OutletService) { }

  ngOnInit(): void {
  }
}
