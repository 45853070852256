import {Injectable} from '@angular/core';
import {CrudService} from '../crud.service';
import {AppService} from '../app.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class SupervisorService extends CrudService {

    constructor(http: HttpClient, private appService: AppService) {
        super(http);

        this.apiUrl = '/api/projects/' + this.appService.getCurProject().id + '/supervisors/';

        this.appService.getCurProjectObservable().subscribe((project) => {
            if (typeof project !== 'undefined') {
                this.apiUrl = '/api/projects/' + project.id + '/supervisors/';
            }
        });
    }
}