import {Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {VARS} from '../../../environments/vars';
import {Marker} from '../../models/map/marker';
import MVCObject = google.maps.MVCObject;
import MarkerClusterer from '@googlemaps/markerclustererplus';
import {Subject, Subscription} from 'rxjs';
import {AppService} from '../../services/app.service';

@Component({
    selector: 'app-google-map',
    templateUrl: './google-map.component.html',
    styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit, OnDestroy {
    @ViewChild('map') mapElement: any;

    @Input() zoom = 10;
    @Input() maxZoom = 22;
    @Input() maxCenterByMarkersZoom = 17;
    @Input() center: google.maps.LatLngLiteral = {lat: 55.5, lng: 37.5};

    // https://developers.google.com/maps/documentation/javascript/marker-clustering
    @Input() clustering = true;
    @Input() clusteringOptions = {
        ignoreHidden: true,
        gridSize: 50,
        maxZoom: 15,
        imagePath: 'assets/images/map/markers/clusters/m'
    };
    @Input() openMultipleInfoWindows = false;
    @Input() closeInfoWindowsOnMapClick = true;


    @Input()
    get markers(): Marker[] {
        return this._markers;
    }

    set markers(markers: Marker[]) {
        this._markers = markers;
        if (this.map) {
            this.initMarkers();
            this.centerByMarkers();
        }
    };

    private clusterer: MarkerClusterer;
    private _markers: Marker[] = [];
    private _googleMarkers: google.maps.Marker[] = [];
    private openedInfoWindows: google.maps.InfoWindow[] = [];

    map: google.maps.Map;
    mapSubject: Subject<google.maps.Map>;
    mapSubscription: Subscription;

    get googleMarkers(): google.maps.Marker[] {
        return this._googleMarkers;
    };

    set googleMarkers(markers: google.maps.Marker[]) {
        this._googleMarkers = markers;
    };

    constructor(private appService: AppService) {
        this.mapSubject = new Subject();
        this.mapSubscription = this.mapSubject.subscribe(() => {
            if (this.clustering && !this.clusterer) {
                this.clusterer = new MarkerClusterer(this.map, this.googleMarkers, this.clusteringOptions);
                this.registerClustersClickEvent();
            }
        });
    }

    ngOnDestroy(): void {
        this.mapSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.prepareMapAndScript();
    };

    prepareMapAndScript = (): void => {
        if (!this.appService.googleMapsApiIsLoaded) {
            // https://developers.google.com/maps/documentation/javascript/overview#js_api_loader_package
            // @ts-ignore google.maps.plugins
            const loader = new google.maps.plugins.loader.Loader({
                apiKey: VARS.googleMapsApiKey,
            });

            loader.load().then(() => {
                this.appService.googleMapsApiIsLoaded = true;
                this.initialize();
            });
        } else {
            this.initialize();
        }
    };

    initialize = () => {
        this.initMap();
        this.initMarkers();
        this.centerByMarkers();
    };

    initMap = () => {
        const mapProperties = {
            center: this.center,
            zoom: this.zoom,
            maxZoom: this.maxZoom,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
        this.mapSubject.next(this.map);

        this.map.addListener('click', () => {
            if (this.closeInfoWindowsOnMapClick && this.openedInfoWindows.length) {
                this.closeOpenedInfoWindows();
            }
        });
    };

    initMarkers = () => {
        this.clearMarkers();
        const {markers} = this;
        markers.map((marker) => {
            this.googleMarkers.push(this.addMarker(marker));
        });
        if (this.clustering) {
            this.clusterer.clearMarkers();
            this.clusterer.addMarkers(this.googleMarkers);
        }
    };

    clearMarkers = () => {
        const {googleMarkers} = this;
        googleMarkers.map((marker) => {
            marker.setMap(null);
        });
        this.googleMarkers = [];
    };

    addMarker = (marker: Marker): google.maps.Marker => {
        const googleMarker = new google.maps.Marker({
            position: new google.maps.LatLng(marker.lat, marker.lng),
            map: this.map,
            title: marker.title,
            icon: marker.icon,
        });
        googleMarker.set('metadata', marker);

        this.addMarkerInfoWindow(marker, googleMarker);
        return googleMarker;
    };

    addMarkerInfoWindow = (marker: Marker, googleMarker: MVCObject) => {
        const infoWindow = new google.maps.InfoWindow();
        google.maps.event.addListener(googleMarker, 'click', ((googleMarkerContext) => {
            return () => {
                if (!this.openMultipleInfoWindows) {
                    this.closeOpenedInfoWindows();
                }
                infoWindow.setContent(marker.description ? marker.description : marker.title);
                infoWindow.open(this.map, googleMarkerContext);
                this.openedInfoWindows.push(infoWindow);
            }
        })(googleMarker));
    };

    closeOpenedInfoWindows = () => {
        this.openedInfoWindows.map((infoWindow) => {
            infoWindow.close();
        });
        this.openedInfoWindows = [];
    };

    getMarkersBounds = () => {
        const bounds = new google.maps.LatLngBounds(null);
        const {googleMarkers} = this;

        googleMarkers.map((marker) => {
            const position = marker.getPosition();
            if (this.positionIsCorrect(position)) {
                bounds.extend(position);
            }
        });
        return bounds;
    };

    positionIsCorrect = (position: google.maps.LatLng) => {
        return position.lat() < 90 && position.lat() > -90
            && position.lng() < 180 && position.lng() > -180;
    };

    centerByMarkers = () => {
        if (!this.markers.length) {
            return;
        }
        const bounds = this.getMarkersBounds();
        this.map.fitBounds(bounds, 0);
        this.map.panToBounds(bounds, 0);

        if (this.map.getZoom() > this.maxCenterByMarkersZoom) {
            this.map.setZoom(this.maxCenterByMarkersZoom);
        }
    };

    registerClustersClickEvent = () => {
        google.maps.event.addListener(this.clusterer, 'clusterclick', (cluster) => {
                const markers = cluster.getMarkers();
                if (this.map.getZoom() === this.clusteringOptions.maxZoom) {
                    const infoWindow = new google.maps.InfoWindow();
                    infoWindow.setContent(this.clusterTemplate(markers));
                    infoWindow.setPosition(cluster.getCenter());
                    infoWindow.open(this.map);
                    this.closeOpenedInfoWindows();
                    this.openedInfoWindows.push(infoWindow);
                    google.maps.event.addListener(infoWindow, 'domready', () => {
                        this.registerClusterMarkersListEvents(infoWindow);
                    });
                }
            }
        );
    };

    clusterTemplate = (markers: google.maps.Marker[]) => {
        let markersHtml = '';
        markers.map(marker => {
            markersHtml += this.clusterMarkerTemplate(marker)
        });
        return `<div class="cluster-info-window">${markersHtml}</div>`;
    };

    clusterMarkerTemplate = (marker: google.maps.Marker) => {
        return `<div class="row mb-1">
                    <div class="col-md-1 cluster-marker-icon" style="background-image: url(${marker.get('metadata').icon})"></div>
                    <div class="col-md-11">
                        <details>
                            <summary class="spoiler">${marker.getTitle()}</summary>
                            <p>${marker.get('metadata').description}</p>
                        </details>
                    </div>
                </div>`;
    };

    registerClusterMarkersListEvents = (infoWindow: google.maps.InfoWindow) => {
        const spoilers = document.getElementsByClassName('spoiler');
        Array.from(spoilers).forEach((spoiler) => {
            spoiler.addEventListener('click', (event: Event) => {
                this.recenterMapAroundInfoWindow(infoWindow);
            });
        });
    };

    recenterMapAroundInfoWindow = (infoWindow: google.maps.InfoWindow) => {
        infoWindow.open(this.map);
    };
}
