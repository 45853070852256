import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { AppService } from '../services/app.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ProjectGuard implements CanActivate, CanActivateChild {
    constructor(private appService: AppService, private router: Router) {}

    canActivate(): Observable<boolean> | boolean {
        return new Observable<boolean>((observable) => {
            this.appService.projectsSubject.subscribe((project) => {
                observable.next(!!project);
            });
        });

        // if (!this.appService.isProjectSelected()) {
        //     this.router.navigate(['/']);
        //     return false;
        // }
        //
        // return true;
    }

    canActivateChild(): Observable<boolean> | boolean {
        return this.canActivate();
    }
}
