import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppService} from '../../app.service';
import {CrudService} from '../../crud.service';

@Injectable()
export class BrandService extends CrudService {

    constructor(http: HttpClient, private appService: AppService) {
        super(http);

        this.apiUrl = '/api/stock/brands/';
    }

}
