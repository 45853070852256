import {Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges} from '@angular/core';
import {Field, InitStep, SubField} from '../../models/init-step';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormService} from '../../services/form.service';

@Component({
    selector: 'ng-promouter-steps',
    templateUrl: './init-steps.component.html',
    styleUrls: ['./init-steps.component.scss']
})
export class InitStepsComponent implements OnInit, OnChanges {

    stepsValue: InitStep[] = [];

    @Input()
    get steps() {
        return this.stepsValue;
    }

    set steps(value) {
        this.stepsValue = value;
        this.stepsChange.emit(this.stepsValue);
    }

    @Input() form: FormGroup;

    @Output() stepsChange: EventEmitter<InitStep[]> = new EventEmitter<InitStep[]>();
    @Output() delStepEmitter: EventEmitter<number> = new EventEmitter<number>();

    groupFieldTypes: string[];

    constructor(private fb: FormBuilder,
                private formService: FormService) {
        this.groupFieldTypes = ['group-checkbox', 'group-radio', 'select'];
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    createStep(): void {
        this.form = this.fb.group({
            order: [null],
            name: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
            fields: this.fb.array([this.initField()])
        });
    }

    editStep(index): void {
        let step = this.steps[index];
        this.form = this.fb.group({
            order: [step.order],
            name: [step.name, Validators.compose([Validators.required, Validators.maxLength(100)])],
            fields: this.fb.array([])
        });

        const control = <FormArray>this.form.controls['fields'];
        for (let item of step.fields) {
            let subFields = this.fb.array([]);
            if (this.groupFieldTypes.indexOf(item.type) !== -1) {
                for (let sub of item.sub) {
                    subFields.push(this.fb.group({
                        name: [sub.name, Validators.compose([Validators.required, Validators.maxLength(100)])],
                    }));
                }
            }

            control.push(this.fb.group({
                uuid: [item.uuid],
                name: [item.name, Validators.compose([Validators.required, Validators.maxLength(100)])],
                type: [item.type, Validators.compose([Validators.required])],
                required: [item.required],
                subFields: subFields,
                description: [item.description]
            }));
        }
    }

    delStep(index: number): void {
        if (this.form && this.form.controls['order'].value != null) {
            if (this.form.controls['order'].value == index) {
                this.form = null;
            } else if (index < this.form.controls['order'].value) {
                this.form.controls['order'].setValue(this.form.controls['order'].value - 1);
            }
        }

        this.delStepEmitter.emit(index);
    }

    stepUp(index): void {
        if (index) {
            let tmp = new InitStep();
            tmp.name = this.steps[index - 1].name;
            tmp.fields = this.steps[index - 1].fields;

            this.steps[index - 1].name = this.steps[index].name;
            this.steps[index - 1].fields = this.steps[index].fields;

            this.steps[index].name = tmp.name;
            this.steps[index].fields = tmp.fields;

            if (this.form && this.form.controls['order'].value != null) {
                if (this.form.controls['order'].value == index) {
                    this.form.controls['order'].setValue(index - 1);
                } else if (this.form.controls['order'].value == index - 1) {
                    this.form.controls['order'].setValue(index);
                }
            }
        }
    }

    stepDown(index): void {
        if (index < this.steps.length - 1) {
            let tmp = new InitStep();
            tmp.name = this.steps[index + 1].name;
            tmp.fields = this.steps[index + 1].fields;

            this.steps[index + 1].name = this.steps[index].name;
            this.steps[index + 1].fields = this.steps[index].fields;

            this.steps[index].name = tmp.name;
            this.steps[index].fields = tmp.fields;

            if (this.form && this.form.controls['order'].value != null) {
                if (this.form.controls['order'].value == index) {
                    this.form.controls['order'].setValue(index + 1);
                } else if (this.form.controls['order'].value == index + 1) {
                    this.form.controls['order'].setValue(index);
                }
            }
        }
    }

    cancel(): void {
        this.form = null;
    }

    save(index): boolean | void {
        console.log('save step', index);
        if (this.form.invalid) {
            this.formService.markControlsAsTouched(this.form);
            return false;
        }


        let step, fields = this.form.controls['fields'].value;
        if (index != null) {
            step = this.steps[index];
        } else {
            step = new InitStep();
        }

        step.name = this.form.controls['name'].value;
        step.fields = [];

        for (let i = 0, fieldsCount = fields.length; i < fieldsCount; ++i) {
            let field = new Field();
            field.uuid = fields[i].uuid;
            field.name = fields[i].name;
            field.description = fields[i].description;
            field.required = fields[i].required;
            field.type = fields[i].type;
            field.order = i;
            if (this.groupFieldTypes.indexOf(field.type) !== -1) {
                let subFields = fields[i].subFields;
                field.sub = [];

                for (let j = 0, subCount = subFields.length; j < subCount; ++j) {
                    let sub = new SubField();
                    sub.name = subFields[j].name;
                    sub.order = j;
                    field.sub.push(sub);
                }

            }
            step.fields.push(field)
        }

        if (index != null) {
            this.steps[index] = step;
        } else {
            this.steps.push(step);
            step.order = this.steps.indexOf(step);
        }
        this.form = null;

        // не обновлялась таблица ngx-datatable после вставки нового шага
        // возможно это баг в версии 11.3.0
        // а пока обновление запускается так
        this.steps = [...this.steps];
        console.log('steps', this.steps);
    }

    initField(): FormGroup {
        return this.fb.group({
            name: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
            type: ['text', Validators.compose([Validators.required])],
            subFields: this.fb.array([]),
            description: [null],
            required: [true]
        });
    }

    initSubField(): FormGroup {
        return this.fb.group({
            name: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
        });
    }

    fieldUp(i): void {
        if (i) {
            const control = <FormArray>this.form.controls['fields'];
            let buf = control.at(i - 1);
            control.setControl(i - 1, control.at(i));
            control.setControl(i, buf);
        }
    }

    fieldDown(i): void {
        const control = <FormArray>this.form.controls['fields'];
        if (i < control.length - 1) {
            let buf = control.at(i + 1);
            control.setControl(i + 1, control.at(i));
            control.setControl(i, buf);
        }
    }

    addField(): void {
        const control = <FormArray>this.form.controls['fields'];
        control.push(this.initField());
    }

    removeField(i): void {
        const control = <FormArray>this.form.controls['fields'];
        control.removeAt(i);
    }

    selectFieldChanged(i, value): void {
        let controls = <FormArray>this.form.controls['fields'];
        let subFields = <FormArray>controls.at(i).get('subFields');
        for (let j = 0, size = subFields.controls.length; j < size; ++j) {
            subFields.removeAt(j);
        }
        if (this.groupFieldTypes.indexOf(value) !== -1) {
            subFields.push(this.initSubField());
        }
    }

    addSubField(i): void {
        const control = <FormArray>this.form.controls['fields'];
        (<FormArray>control.at(i).get('subFields')).push(this.initSubField());
    }

    removeSubField(i, j): void {
        const control = <FormArray>(<FormArray>this.form.controls['fields']).at(i).get('subFields');
        control.removeAt(j);
    }

    subFieldUp(i, j): void {
        if (j) {
            const control = <FormArray>(<FormArray>this.form.controls['fields']).at(i).get('subFields');
            let buf = control.at(j - 1);
            control.setControl(j - 1, control.at(j));
            control.setControl(j, buf);
        }
    }

    subFieldDown(i, j): void {
        const control = <FormArray>(<FormArray>this.form.controls['fields']).at(i).get('subFields');
        if (j < control.length - 1) {
            let buf = control.at(j + 1);
            control.setControl(j + 1, control.at(j));
            control.setControl(j, buf);
        }
    }

}
