import {Injectable} from '@angular/core';
import {CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';

@Injectable()
export class RoleGuard implements CanActivate, CanActivateChild {
    constructor(private authService: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        let userRole = this.authService.getUserRole();
        if (userRole) {
            let roles = route.data['roles'] as string[];
            if (roles === undefined || roles.indexOf(userRole) !== -1) {
                return true;
            }
        }
        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        let userRole = this.authService.getUserRole();
        if (userRole) {
            let roles = route.data['roles'] as string[];
            if (roles === undefined || roles.indexOf(userRole) !== -1) {
                return true;
            }
        }
        return false;
    }
}