import {
    AfterContentInit,
    Component,
    ComponentFactoryResolver,
    ContentChildren,
    EventEmitter,
    Input, OnDestroy,
    OnInit, Output,
    QueryList,
    ReflectiveInjector,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {TableColumn} from '@swimlane/ngx-datatable';
import {CrudService} from '../../../services/crud.service';
import {NgbDateStruct, NgbModal, NgbModalRef, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {toInteger} from '@ng-bootstrap/ng-bootstrap/util/util';
import {FormService} from '../../../services/form.service';
import {Observable, BehaviorSubject, Subscription} from 'rxjs';
import {MgCrudTableComponent} from '../mg-crud-table/mg-crud-table.component';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {Field} from '../mg-crud.module';
import {MgCrudFilterComponent} from '../mg-crud-filter/mg-crud-filter.component';

import {
    debounceTime,
    switchMap,
} from 'rxjs/operators';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'mg-crud',
    templateUrl: './mg-crud.component.html',
    styleUrls: ['./mg-crud.component.scss']
})
export class MgCrudComponent implements OnInit, AfterContentInit, OnDestroy {

    @ViewChild(MgCrudTableComponent) crudTableComponent: MgCrudTableComponent;

    @ViewChild('filtersContainer', {read: ViewContainerRef}) filtersContainer: ViewContainerRef;
    @ContentChildren(MgCrudFilterComponent) filters: QueryList<MgCrudFilterComponent>;

    @ViewChild('modal') modal: NgbModal;

    @Input() columns: TableColumn[] = [];
    @Input() crudService: CrudService;
    @Input() fields: Field[] = [];

    @Input() createButton: boolean = true;
    @Input() editButton: boolean = true;
    @Input() deleteButton: boolean = true;

    @Input() refreshButton: boolean = false;

    @Input() actionCreateTemplate?: TemplateRef<any>;
    @Input() actionEditTemplate?: TemplateRef<any>;
    @Input() actionDeleteTemplate?: TemplateRef<any>;

    @Output() onFilterChange: EventEmitter<any> = new EventEmitter<any>();

    rows: any[] = [];
    count: number = 0;
    limit: number = 10;

    dateTimeModels: { [key: string]: any } = {};

    form: FormGroup;
    formHeading: string = '';
    formButtonTitle: string = '';
    serverErrorMessages: { [key: string]: string[] } = {};

    paramsObservable: BehaviorSubject<any>;
    dataSubscription: Subscription;

    protected params: any = {};
    protected modalRef: NgbModalRef;

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
                private modalService: NgbModal,
                private formService: FormService) {
    }

    ngOnInit(): void {
        this.params = {
            page: 1,
            limit: 10,
            sort: 'id,desc',
        };
        this.paramsObservable = new BehaviorSubject(this.params);
        this.subscribeData();

        this.fields.filter((field) => ['date', 'time'].indexOf(field.type) !== -1)
            .forEach((field) => this.dateTimeModels[field.name] = null);

        this.form = this.createFormGroup();
    }

    ngAfterContentInit(): void {
        this.loadFilterComponents();
    }

    ngOnDestroy(): void {
        this.dataSubscription.unsubscribe();
    }

    protected loadFilterComponents(): void {
        this.filtersContainer.clear();
        this.filters.forEach((filter: MgCrudFilterComponent, index) => {
            if (filter.filterConfig.type === 'select') {
                filter.init.subscribe(() => {
                    this.loadFilter(filter, index);
                });
            } else {
                this.loadFilter(filter, index);
            }
        });
    }

    protected loadFilter(filter: MgCrudFilterComponent, index: number): void {
        let inputProviders = Object.keys(filter).map(inputName => {
            return {
                provide: inputName,
                useValue: filter[inputName]
            };
        });
        let resolvedProviders = ReflectiveInjector.resolve(inputProviders);
        let injector = ReflectiveInjector.fromResolvedProviders(resolvedProviders);

        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(MgCrudFilterComponent);
        let component = componentFactory.create(injector);
        inputProviders.forEach((item: any) => {
            component.instance[item.provide] = item.useValue;
            if (item.provide === 'change') {
                component.instance[item.provide].subscribe((data) => {
                    const value = data.value;
                    if ((value === 0 || value) && value != 'null') {
                        this.params[data.filterName] = value;
                    } else {
                        delete this.params[data.filterName];
                    }
                    this.onFilterChange.emit(data);

                    this.params.page = 1;
                    this.applyParams();
                });
            }
        });

        if (this.filtersContainer.get(index)) {
            this.filtersContainer.remove(index);
        }
        this.filtersContainer.insert(component.hostView, index);
    }

    add(): void {
        if (this.form.controls['id'].value) {
            this.resetForm();
        }

        this.formHeading = 'Добавление';
        this.formButtonTitle = 'Добавить';
        this.openModal();
    }

    edit(id: number): void {
        let editable = this.rows.find((item) => item.id === id);
        Object.keys(editable).forEach((key) => {
            if (this.form.contains(key.toString())) {
                const value = editable[key];
                const field = this.fields.find(field => field.name === key && ['date', 'time'].indexOf(field.type) !== -1);
                if (field) {
                    if (field.type == 'date') {
                        let [year, month, day] = value.split('-');
                        this.dateTimeModels[key] = {
                            year: toInteger(year),
                            month: toInteger(month),
                            day: toInteger(day)
                        };
                    } else {
                        let [hour, minute] = value.split(':');
                        this.dateTimeModels[key] = {hour: toInteger(hour), minute: toInteger(minute), second: 0};
                    }
                }
                this.form.controls[key].setValue(value);
            }
        });

        this.formHeading = 'Редактирование';
        this.formButtonTitle = 'Сохранить';
        this.openModal();
    }

    del(id: number): void {
        this.crudService.del(id).subscribe(() => {
            this.applyParams();
        });
    }

    save(): void {
        if (this.form.invalid) {
            this.formService.markControlsAsTouched(this.form);
            return;
        }

        const data = this.form.value;
        const resultObservable: Observable<any> = (data['id']) ? this.crudService.update(data) : this.crudService.create(data);
        resultObservable.subscribe(() => {
            this.applyParams();
            this.modalRef.close();
            this.resetForm();
        }, (error) => {
            this.form.setErrors({
                'serverError': true
            });
            this.formService.markControlsAsTouched(this.form);
            this.serverErrorMessages = error.errors;
        });
    }

    sort($event): void {
        this.params.sort = $event.column.prop + ',' + $event.newValue;
        this.applyParams();
    }

    page($event): void {
        this.params.page = $event.offset + 1;
        this.params.limit = $event.limit;
        this.applyParams();
    }

    selectDate(date: NgbDateStruct, controlName: string): void {
        const dateControl: AbstractControl = this.form.controls[controlName];
        let value: string = null;

        if (typeof date === 'object') {
            value = (new Date(date.year, date.month - 1, date.day + 1)).toISOString().split('T')[0];
        }
        dateControl.setValue(value);
    }

    selectTime(time: NgbTimeStruct, controlName: string): void {
        const timeControl: AbstractControl = this.form.controls[controlName];
        let value: string = null;

        if (time) {
            const formattedDate = new Date();
            formattedDate.setHours(time.hour, time.minute);

            let [hours, minutes] = formattedDate.toTimeString().split(' ')[0].split(':');
            value = hours + ':' + minutes;
        }
        timeControl.setValue(value);
    }

    refreshData(): void {
        this.paramsObservable.next(this.params);
    }

    protected subscribeData(): void {
        this.dataSubscription = this.paramsObservable
            .pipe(
                debounceTime(300),
                switchMap(params => this.crudService.get(params)),
            )
            .subscribe((data: any) => {
                this.rows = data.data;
                this.count = data.count;
            });
    }

    protected applyParams(): void {
        // костыль, который чинит сброс пагинатора при сортировке
        this.crudTableComponent.table.bodyComponent.updateOffsetY(this.params.page - 1);
        this.crudTableComponent.table.offset = this.params.page - 1;

        this.paramsObservable.next(this.params);
    }

    protected openModal(): void {
        this.serverErrorMessages = {};
        this.modalRef = this.modalService.open(this.modal, {backdrop: 'static'});
    }

    protected createFormGroup(): FormGroup {
        const group = new FormGroup({});

        group.addControl('id', new FormControl());
        this.fields.forEach(field => group.addControl(field.name, field.control));

        return group;
    }

    protected resetForm(): void {
        this.form.reset();
        this.fields.filter(field => ['date', 'time'].indexOf(field.type) !== -1)
            .forEach(field => this.dateTimeModels[field.name] = null);
    }

}
