import {NgModule} from '@angular/core';
import {DatepickerToggleDirective} from './datepicker-toggle.directive';
import {PasswordDirective} from './password.directive';
import {PositiveNumberDirective} from './positive-number.directive';
import {TimezoneDirective} from './timezone.directive';
import {DisableControlDirective} from './disabled-control.directive';

@NgModule({
    imports: [],
    declarations: [
        DatepickerToggleDirective,
        PasswordDirective,
        PositiveNumberDirective,
        TimezoneDirective,
        DisableControlDirective
    ],
    exports: [
        DatepickerToggleDirective,
        PasswordDirective,
        PositiveNumberDirective,
        TimezoneDirective,
        DisableControlDirective
    ]
})
export class DirectivesModule {
}
